export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  DateTime: number
  JSONObject: any
}

export type GQLAccountTag = {
  __typename?: "AccountTag"
  count: Scalars["Int"]
  name: Scalars["String"]
}

export type GQLAddMakeUpSessionInformationInput = {
  cohortSessionId: Scalars["ID"]
  makeUpCohortId: Scalars["ID"]
  makeUpSessionId: Scalars["ID"]
  studentId: Scalars["ID"]
}

export type GQLAddedPurchaseItem =
  | GQLAddedPurchasePackage
  | GQLAddedPurchaseProduct

export type GQLAddedPurchasePackage = {
  __typename?: "AddedPurchasePackage"
  itemId: Scalars["ID"]
  items?: Maybe<Array<GQLAddedPurchaseItem>>
  name: Scalars["String"]
  priceDollars: Scalars["Float"]
  productId: Scalars["ID"]
  quantity: Scalars["Int"]
  type: GQLProductType
}

export type GQLAddedPurchaseProduct = {
  __typename?: "AddedPurchaseProduct"
  basePriceDollars: Scalars["Float"]
  itemId: Scalars["ID"]
  name: Scalars["String"]
  productId: Scalars["ID"]
  quantity: Scalars["Int"]
  type: GQLProductType
}

export type GQLAddress = {
  __typename?: "Address"
  address?: Maybe<Scalars["String"]>
  city?: Maybe<Scalars["String"]>
  id: Scalars["ID"]
  phone?: Maybe<Scalars["String"]>
  secondaryPhone?: Maybe<Scalars["String"]>
  state?: Maybe<Scalars["String"]>
  zipcode?: Maybe<Scalars["String"]>
}

export type GQLAddressInput = {
  address?: InputMaybe<Scalars["String"]>
  city?: InputMaybe<Scalars["String"]>
  phone?: InputMaybe<Scalars["String"]>
  secondaryPhone?: InputMaybe<Scalars["String"]>
  state?: InputMaybe<Scalars["String"]>
  zipcode?: InputMaybe<Scalars["String"]>
}

export type GQLAnvilPdfIds = {
  __typename?: "AnvilPdfIds"
  admin?: Maybe<Scalars["ID"]>
  insurance?: Maybe<Scalars["ID"]>
  student?: Maybe<Scalars["ID"]>
}

export type GQLApprovedInstructorSchedule = {
  __typename?: "ApprovedInstructorSchedule"
  createdAt: Scalars["DateTime"]
  dayTimesAvailable: Array<GQLApprovedScheduleDayTimes>
  daysPerWeek: Scalars["Int"]
  endDate: Scalars["String"]
  id: Scalars["ID"]
  instructorId: Scalars["ID"]
  instructorName?: Maybe<Scalars["String"]>
  managerName?: Maybe<Scalars["String"]>
  scheduleRequestId: Scalars["ID"]
  startDate: Scalars["String"]
  timesOff?: Maybe<Array<GQLInstructorTimeOff>>
  updatedAt: Scalars["DateTime"]
}

export type GQLApprovedInstructorScheduleDayTimes = {
  dayOfTheWeek: Scalars["String"]
  endTime: Scalars["String"]
  startTime: Scalars["String"]
}

export type GQLApprovedInstructorScheduleInput = {
  dayTimesAvailable: Array<GQLApprovedInstructorScheduleDayTimes>
  daysPerWeek: Scalars["Int"]
  endDate?: InputMaybe<Scalars["String"]>
  instructorId: Scalars["ID"]
  scheduleRequestId: Scalars["ID"]
  startDate: Scalars["String"]
}

export type GQLApprovedInstructorScheduleMutateQuery = {
  instructorId: Scalars["ID"]
  scheduleId: Scalars["ID"]
}

export type GQLApprovedInstructorScheduleQuery = {
  endDate?: InputMaybe<Scalars["String"]>
  instructorId: Scalars["ID"]
  scheduleId?: InputMaybe<Scalars["ID"]>
  startDate?: InputMaybe<Scalars["String"]>
}

export type GQLApprovedInstructorsSchedulesQuery = {
  endDate?: InputMaybe<Scalars["String"]>
  instructorIds?: InputMaybe<Array<Scalars["ID"]>>
  scheduleIds?: InputMaybe<Array<Scalars["ID"]>>
  startDate?: InputMaybe<Scalars["String"]>
}

export type GQLApprovedScheduleDayTimes = {
  __typename?: "ApprovedScheduleDayTimes"
  dayOfTheWeek: Scalars["String"]
  endTime: Scalars["String"]
  startTime: Scalars["String"]
}

export enum GQLAttendance {
  Absent = "Absent",
  Made_Up = "Made_Up",
  Not_Set = "Not_Set",
  Present = "Present",
}

export type GQLAuditLog = {
  __typename?: "AuditLog"
  action: GQLAuditLogActions
  changes: Array<GQLLogChange>
  collection: GQLAuditLogCollection
  id: Scalars["ID"]
  timestamp: Scalars["DateTime"]
  updatedBy: GQLUpdatedBy
}

export enum GQLAuditLogActions {
  Create = "Create",
  Delete = "Delete",
  Unknown = "Unknown",
  Update = "Update",
}

export type GQLAuditLogCollection = {
  __typename?: "AuditLogCollection"
  name: Scalars["String"]
  path: Scalars["String"]
}

export type GQLAuditLogEdge = {
  __typename?: "AuditLogEdge"
  cursor: Scalars["ID"]
  node: GQLAuditLog
}

export enum GQLAuditLogUserRoles {
  Admin = "Admin",
  Instructor = "Instructor",
  System = "System",
  User = "User",
}

export type GQLAuditLogsPaginatedList = {
  __typename?: "AuditLogsPaginatedList"
  edges: Array<GQLAuditLogEdge>
  pageInfo: GQLPageInfo
  totalCount: Scalars["Int"]
}

export type GQLAuditLogsQuery = {
  action?: InputMaybe<GQLAuditLogActions>
  collection?: InputMaybe<GQLCollections>
  endDate?: InputMaybe<Scalars["DateTime"]>
  path?: InputMaybe<Scalars["String"]>
  role?: InputMaybe<GQLAuditLogUserRoles>
  startDate?: InputMaybe<Scalars["DateTime"]>
}

export type GQLAvailabilitiesQuery = {
  endTime: Scalars["DateTime"]
  instructorIds: Array<Scalars["ID"]>
  startTime: Scalars["DateTime"]
}

export type GQLAvailability = {
  __typename?: "Availability"
  center: GQLCenter
  date: Scalars["String"]
  endTime: Scalars["DateTime"]
  id: Scalars["ID"]
  instructor: GQLInstructor
  instructorId: Scalars["ID"]
  isPrivate: Scalars["Boolean"]
  maxLessons: Scalars["Int"]
  minLeadHours: Scalars["Int"]
  note?: Maybe<Scalars["String"]>
  startTime: Scalars["DateTime"]
  vehicle: GQLVehicle
}

export type GQLAvailabilityAddFailure = {
  __typename?: "AvailabilityAddFailure"
  date: Scalars["DateTime"]
  error: Scalars["String"]
  weekDay: Scalars["String"]
}

export type GQLAvailabilityCreateInput = {
  center: GQLCenterInput
  date: Scalars["String"]
  endTime: Scalars["DateTime"]
  instructor: GQLAvailabilityInstructorInput
  isPrivate: Scalars["Boolean"]
  maxLessons: Scalars["Int"]
  minLeadHours: Scalars["Int"]
  note?: InputMaybe<Scalars["String"]>
  startTime: Scalars["DateTime"]
  vehicle: GQLAvailabilityVehicleInput
}

export type GQLAvailabilityInstructorInput = {
  id: Scalars["ID"]
  name: Scalars["String"]
  timeZone: Scalars["String"]
}

export type GQLAvailabilityQuery = {
  id: Scalars["ID"]
  instructorId: Scalars["ID"]
}

export type GQLAvailabilitySlot = {
  __typename?: "AvailabilitySlot"
  endTime: Scalars["DateTime"]
  instructorId: Scalars["ID"]
  instructorName: Scalars["String"]
  instructorTimezone: Scalars["String"]
  isPrivate: Scalars["Boolean"]
  location: GQLLocation
  startTime: Scalars["DateTime"]
}

export type GQLAvailabilitySlotPerDay = {
  __typename?: "AvailabilitySlotPerDay"
  date: Scalars["String"]
  timeSlots: Array<GQLAvailabilitySlot>
}

export type GQLAvailabilityUpdateFailure = {
  __typename?: "AvailabilityUpdateFailure"
  availabilityId: Scalars["ID"]
  error: Scalars["String"]
}

export type GQLAvailabilityUpdateInput = {
  center?: InputMaybe<GQLCenterInput>
  date: Scalars["String"]
  endTime?: InputMaybe<Scalars["DateTime"]>
  isPrivate?: InputMaybe<Scalars["Boolean"]>
  maxLessons?: InputMaybe<Scalars["Int"]>
  minLeadHours?: InputMaybe<Scalars["Int"]>
  note?: InputMaybe<Scalars["String"]>
  startTime?: InputMaybe<Scalars["DateTime"]>
  vehicle?: InputMaybe<GQLAvailabilityVehicleInput>
}

export type GQLAvailabilityVehicleInput = {
  id: Scalars["ID"]
  name: Scalars["String"]
}

export type GQLBalancedQuantity = {
  availableQuantity: Scalars["Int"]
  expiredQuantity: Scalars["Int"]
  purchasedQuantity: Scalars["Int"]
  receivedQuantity: Scalars["Int"]
  refundedQuantity: Scalars["Int"]
  sentQuantity: Scalars["Int"]
  usedQuantity: Scalars["Int"]
  voidedQuantity: Scalars["Int"]
}

export type GQLBaseLedgerLog = {
  createdById: Scalars["ID"]
  id: Scalars["ID"]
  summary: Scalars["String"]
  timestamp: Scalars["DateTime"]
  type: GQLLedgerLogTypes
}

export type GQLBaseLedgerLogSummaryArgs = {
  studentId: Scalars["ID"]
}

export type GQLBatchAddAvailsResult = {
  __typename?: "BatchAddAvailsResult"
  added: Array<GQLAvailability>
  failed: Array<GQLAvailabilityAddFailure>
}

export type GQLBatchDeleteAvailsQuery = {
  availabilityIds: Array<Scalars["ID"]>
  instructorId: Scalars["ID"]
}

export type GQLBatchDeleteAvailsResult = {
  __typename?: "BatchDeleteAvailsResult"
  deleted: Array<Scalars["ID"]>
  failed: Array<GQLAvailabilityUpdateFailure>
}

export type GQLBatchEditAvailsQuery = {
  ids: Array<Scalars["ID"]>
  instructorId: Scalars["ID"]
}

export type GQLBatchEditAvailsResult = {
  __typename?: "BatchEditAvailsResult"
  failed: Array<GQLAvailabilityUpdateFailure>
  updated: Array<GQLAvailability>
}

export type GQLBatchEditAvailsUpdate = {
  endTimeOfDay?: InputMaybe<Scalars["String"]>
  isPrivate?: InputMaybe<Scalars["Boolean"]>
  maxLessons?: InputMaybe<Scalars["Int"]>
  minLeadHours?: InputMaybe<Scalars["Int"]>
  startTimeOfDay?: InputMaybe<Scalars["String"]>
  vehicleId?: InputMaybe<Scalars["ID"]>
  vehicleLocation?: InputMaybe<Scalars["String"]>
}

export type GQLBookingAlert = {
  __typename?: "BookingAlert"
  createdAt?: Maybe<Scalars["DateTime"]>
  createdBy: GQLPerson
  createdById: Scalars["ID"]
  id: Scalars["ID"]
  studentId: Scalars["ID"]
  text?: Maybe<Scalars["String"]>
}

export type GQLBookingAlertEdge = {
  __typename?: "BookingAlertEdge"
  cursor: Scalars["ID"]
  node: GQLBookingAlert
}

export type GQLBookingAlertInput = {
  id?: InputMaybe<Scalars["ID"]>
  studentId: Scalars["ID"]
  text: Scalars["String"]
}

export type GQLBookingAlertsPaginatedList = {
  __typename?: "BookingAlertsPaginatedList"
  edges: Array<GQLBookingAlertEdge>
  pageInfo: GQLPageInfo
  totalCount: Scalars["Int"]
}

export type GQLBookingAlertsQuery = {
  studentId: Scalars["ID"]
}

export type GQLCancelPolicy = {
  changeFee: Scalars["Float"]
  message: Scalars["String"]
}

export type GQLCart = {
  __typename?: "Cart"
  bookedSlotIds?: Maybe<Array<Scalars["ID"]>>
  coupon?: Maybe<GQLCoupon>
  createdAt: Scalars["DateTime"]
  id: Scalars["ID"]
  isFirstPurchase?: Maybe<Scalars["Boolean"]>
  isOrderComplete: Scalars["Boolean"]
  items: Array<GQLProductPackageWithPrice>
  paymentStatus?: Maybe<Scalars["String"]>
  student?: Maybe<GQLStudent>
  studentId?: Maybe<Scalars["ID"]>
  updatedAt: Scalars["DateTime"]
  usState?: Maybe<GQLUsStateId>
}

export type GQLCartCreateInput = {
  item?: InputMaybe<GQLProductPackageWithPriceInput>
}

export type GQLCartEdge = {
  __typename?: "CartEdge"
  cursor: Scalars["ID"]
  node: GQLCart
}

export type GQLCartFilterInput = {
  fromDate: Scalars["DateTime"]
  limit?: InputMaybe<Scalars["Int"]>
  page?: InputMaybe<Scalars["Int"]>
  state?: InputMaybe<Scalars["String"]>
}

export type GQLCartInput = {
  id: Scalars["ID"]
}

export type GQLCartsPaginatedList = {
  __typename?: "CartsPaginatedList"
  edges: Array<GQLCartEdge>
  pageInfo: GQLPageInfo
  totalCount: Scalars["Int"]
}

export type GQLCenter = {
  __typename?: "Center"
  location: Scalars["String"]
}

export type GQLCenterGeocode = {
  __typename?: "CenterGeocode"
  city: Scalars["String"]
  formattedAddress: Scalars["String"]
  latitude: Scalars["Float"]
  longitude: Scalars["Float"]
  state: Scalars["String"]
  zipcode: Scalars["String"]
}

export type GQLCenterInput = {
  location: Scalars["String"]
}

export type GQLCertificate = {
  __typename?: "Certificate"
  certNumber: Scalars["Int"]
  certType: GQLCertificateType
  certTypeId: Scalars["ID"]
  createdAt: Scalars["DateTime"]
  createdBy: GQLPerson
  createdById: Scalars["ID"]
  id: Scalars["ID"]
  issuedAt: Scalars["DateTime"]
  issuedTo?: Maybe<GQLPerson>
  issuedToId?: Maybe<Scalars["ID"]>
  usState?: Maybe<GQLUsState>
  usStateId?: Maybe<Scalars["ID"]>
}

export type GQLCertificateEdge = {
  __typename?: "CertificateEdge"
  cursor: Scalars["ID"]
  node: GQLCertificate
}

export type GQLCertificateInput = {
  certNumber: Scalars["Int"]
  certTypeId: Scalars["ID"]
  id?: InputMaybe<Scalars["ID"]>
  issuedAt: Scalars["DateTime"]
  issuedToId?: InputMaybe<Scalars["ID"]>
  usStateId: Scalars["ID"]
}

export type GQLCertificateType = {
  __typename?: "CertificateType"
  id: Scalars["ID"]
  label: Scalars["String"]
  prefix: Scalars["String"]
  usStateId: Scalars["ID"]
  valMax?: Maybe<Scalars["Int"]>
  valMin?: Maybe<Scalars["Int"]>
}

export type GQLCertificatesPaginatedList = {
  __typename?: "CertificatesPaginatedList"
  edges: Array<GQLCertificateEdge>
  pageInfo: GQLPageInfo
  totalCount: Scalars["Int"]
}

export type GQLCertificatesQuery = {
  studentId?: InputMaybe<Scalars["ID"]>
}

export type GQLChargeLedgerLog = {
  __typename?: "ChargeLedgerLog"
  categoryId: GQLPaymentCategories
  couponCode?: Maybe<Scalars["String"]>
  id: Scalars["ID"]
  items: Array<GQLPurchaseItem>
  purchaseId: Scalars["ID"]
  summary: Scalars["String"]
  voucherCode?: Maybe<Scalars["String"]>
}

export type GQLChargeLedgerLogSummaryArgs = {
  studentId: Scalars["ID"]
}

export type GQLChargeLedgerLogQueryInput = {
  id: Scalars["ID"]
  studentId: Scalars["ID"]
}

export type GQLClaimInput = {
  key: Scalars["String"]
  value: Scalars["Boolean"]
}

export type GQLCohort = {
  __typename?: "Cohort"
  courseId: Scalars["ID"]
  description: Scalars["String"]
  endDate: Scalars["String"]
  enrolledUserIds?: Maybe<Array<Maybe<Scalars["ID"]>>>
  id: Scalars["ID"]
  instructor: Scalars["String"]
  isEnrollmentOpen: Scalars["Boolean"]
  location: Scalars["String"]
  maxEnrollees: Scalars["Int"]
  name: Scalars["String"]
  numEnrolled: Scalars["Int"]
  sessions?: Maybe<Array<GQLCohortSession>>
  startDate: Scalars["String"]
  state: GQLUsStateId
  stateId: Scalars["String"]
  students?: Maybe<Array<GQLStudentObject>>
  timeZone: Scalars["String"]
}

export type GQLCohortInput = {
  courseId: Scalars["ID"]
  description: Scalars["String"]
  endDate: Scalars["String"]
  enrolledUserIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>
  id?: InputMaybe<Scalars["ID"]>
  instructor: Scalars["String"]
  isEnrollmentOpen: Scalars["Boolean"]
  location: Scalars["String"]
  maxEnrollees: Scalars["Int"]
  name: Scalars["String"]
  startDate: Scalars["String"]
  state: GQLUsStateId
  stateId: Scalars["String"]
  timeZone: Scalars["String"]
}

export type GQLCohortSession = {
  __typename?: "CohortSession"
  attendance?: Maybe<Array<GQLStudentSessionAttendance>>
  cohortId: Scalars["ID"]
  endTime: Scalars["DateTime"]
  id: Scalars["ID"]
  name: Scalars["String"]
  startTime: Scalars["DateTime"]
}

export type GQLCohortSessionInput = {
  cohortId: Scalars["ID"]
  endTime: Scalars["DateTime"]
  id?: InputMaybe<Scalars["ID"]>
  name: Scalars["String"]
  startTime: Scalars["DateTime"]
}

export enum GQLCollections {
  InstructorAvailsV2 = "InstructorAvailsV2",
  InstructorReviewsV2 = "InstructorReviewsV2",
  Instructors = "Instructors",
  LessonSlotsV2 = "LessonSlotsV2",
  Users = "Users",
  Vehicles = "Vehicles",
  Vouchers = "Vouchers",
  Waitlist = "Waitlist",
}

export type GQLCompleteOrderInput = {
  bookedSlotIds?: InputMaybe<Array<Scalars["ID"]>>
  id: Scalars["ID"]
}

export type GQLCoupon = GQLCourseCoupon | GQLLessonCoupon | GQLRoadTestCoupon

export type GQLCouponEdge = {
  __typename?: "CouponEdge"
  cursor: Scalars["ID"]
  node: GQLCoupon
}

export enum GQLCouponType {
  Course = "course",
  Lesson = "lesson",
  RoadTest = "roadTest",
}

export type GQLCouponsFilterQuery = {
  type: GQLCouponType
}

export type GQLCouponsPaginatedList = {
  __typename?: "CouponsPaginatedList"
  edges: Array<GQLCouponEdge>
  pageInfo: GQLPageInfo
  totalCount: Scalars["Int"]
}

export type GQLCourseCoupon = {
  __typename?: "CourseCoupon"
  code: Scalars["String"]
  courseId?: Maybe<Scalars["String"]>
  courseSlug?: Maybe<Scalars["String"]>
  discount: Scalars["Float"]
  expiration?: Maybe<Scalars["DateTime"]>
  id: Scalars["ID"]
  isDailyCoupon: Scalars["Boolean"]
  state: GQLUsStateId
  title: Scalars["String"]
  type: GQLCouponType
}

export type GQLCourseCouponInput = {
  code: Scalars["String"]
  courseId: Scalars["String"]
  discount: Scalars["Float"]
  expiration?: InputMaybe<Scalars["DateTime"]>
  isDailyCoupon: Scalars["Boolean"]
  state: GQLUsStateId
  title: Scalars["String"]
}

export type GQLCourseLecture = {
  __typename?: "CourseLecture"
  content: GQLCourseLectureContent
  id: Scalars["ID"]
  includesAQuiz: Scalars["Boolean"]
  slug: Scalars["String"]
  /** Time the student spent on the lecture in milliseconds. */
  timeSpentByStudent: Scalars["Float"]
  title: Scalars["String"]
}

export type GQLCourseLectureContentArgs = {
  courseId: Scalars["ID"]
  seed?: InputMaybe<Scalars["String"]>
}

export type GQLCourseLectureTimeSpentByStudentArgs = {
  courseId: Scalars["ID"]
  studentId: Scalars["ID"]
}

export type GQLCourseLectureContent = {
  __typename?: "CourseLectureContent"
  minimumDuration: Scalars["Int"]
  parts: Array<GQLLecturePart>
}

export type GQLCoursePrepTest = {
  __typename?: "CoursePrepTest"
  chapters: Array<GQLCoursePrepTestChapter>
  title: Scalars["String"]
}

export type GQLCoursePrepTestChapter = {
  __typename?: "CoursePrepTestChapter"
  id: Scalars["ID"]
  questions: Array<GQLLectureQuizQuestion>
  title: Scalars["String"]
}

export type GQLCoursePrepTestChapterScores = {
  __typename?: "CoursePrepTestChapterScores"
  id: Scalars["ID"]
  questionScores: Array<GQLLectureQuizQuestionPrepTestScore>
}

export type GQLCourseTest = {
  __typename?: "CourseTest"
  id: Scalars["ID"]
  name: Scalars["String"]
}

export type GQLCourseTestLecture = {
  __typename?: "CourseTestLecture"
  id: Scalars["ID"]
  title: Scalars["String"]
}

export type GQLCourseTopic = {
  __typename?: "CourseTopic"
  id: Scalars["ID"]
  lectures: Array<GQLCourseLecture>
  slug: Scalars["String"]
  title: Scalars["String"]
}

export type GQLCoursesQuery = {
  isClassroomCourse?: InputMaybe<Scalars["Boolean"]>
  isEnrollmentOpen?: InputMaybe<Scalars["Boolean"]>
}

export type GQLCreateInstructorTimeOffInput = {
  endDate: Scalars["String"]
  instructorId: Scalars["ID"]
  startDate: Scalars["String"]
}

export type GQLCreateProductInput = {
  description?: InputMaybe<Scalars["String"]>
  id: Scalars["ID"]
  name: Scalars["String"]
  products?: InputMaybe<Array<GQLProductLinkInput>>
  type: GQLCreateProductTypeInput
}

export enum GQLCreateProductTypeInput {
  Course = "course",
  Fee = "fee",
  Package = "package",
}

export type GQLCreatePurchaseInput = {
  amountDollars: Scalars["Float"]
  categoryId: GQLPaymentCategories
  contractId?: InputMaybe<Scalars["ID"]>
  couponCode?: InputMaybe<Scalars["String"]>
  description?: InputMaybe<Scalars["String"]>
  items: Array<GQLPurchaseItemInput>
  note?: InputMaybe<Scalars["String"]>
  paymentCaptureDate?: InputMaybe<Scalars["DateTime"]>
  purchaseSessionId?: InputMaybe<Scalars["ID"]>
  stripeChargeId?: InputMaybe<Scalars["String"]>
  stripeCustomerId?: InputMaybe<Scalars["String"]>
  stripePaymentMethodId?: InputMaybe<Scalars["String"]>
  studentAddress?: InputMaybe<Scalars["String"]>
  studentId: Scalars["ID"]
  usStateId: GQLUsStateId
  voucherCode?: InputMaybe<Scalars["String"]>
}

export type GQLCreateSessionCookieInput = {
  idToken: Scalars["String"]
}

export type GQLCreateVoucherInput = {
  amountDollars: Scalars["Float"]
  amountHours: Scalars["Float"]
  name?: InputMaybe<Scalars["String"]>
  source?: InputMaybe<Scalars["String"]>
  zipcode?: InputMaybe<Scalars["String"]>
}

export type GQLDeleteBookingAlertInput = {
  id: Scalars["ID"]
  studentId: Scalars["ID"]
}

export type GQLDrivingSkill = {
  __typename?: "DrivingSkill"
  id: Scalars["ID"]
  label: Scalars["String"]
}

export enum GQLDrivingSkillRating {
  Capable = "Capable",
  Consistent = "Consistent",
  NeedsAdditionalDevelopment = "NeedsAdditionalDevelopment",
  NeedsImprovement = "NeedsImprovement",
  Proficient = "Proficient",
  Todo = "Todo",
}

export type GQLEditPurchaseInput = {
  amountDollars?: InputMaybe<Scalars["Float"]>
  categoryId?: InputMaybe<GQLPaymentCategories>
  description?: InputMaybe<Scalars["String"]>
  items?: InputMaybe<Array<GQLPurchaseItemInput>>
  paymentCaptureDate?: InputMaybe<Scalars["DateTime"]>
  purchaseId: Scalars["ID"]
  stripeChargeId?: InputMaybe<Scalars["String"]>
  stripeCustomerId?: InputMaybe<Scalars["String"]>
  studentAddress?: InputMaybe<Scalars["String"]>
  studentId: Scalars["ID"]
  usStateId?: InputMaybe<GQLUsStateId>
  versionId: Scalars["ID"]
}

export type GQLEligibleStudent = {
  __typename?: "EligibleStudent"
  displayName: Scalars["String"]
  distanceMeters: Scalars["Float"]
  dob: Scalars["Float"]
  drivingMinutesRemaining: Scalars["Int"]
  email: Scalars["String"]
  licenseExpirationDate?: Maybe<Scalars["Float"]>
  licenseIssueDate?: Maybe<Scalars["Float"]>
  licenseNumber?: Maybe<Scalars["String"]>
  observationMinutesRemaining: Scalars["Int"]
  phone?: Maybe<Scalars["String"]>
  tags?: Maybe<Array<Scalars["String"]>>
  userId: Scalars["ID"]
}

export type GQLEmailStudentReportInput = {
  comments?: InputMaybe<Scalars["String"]>
  emails: Array<Scalars["String"]>
  lessonId: Scalars["ID"]
  studentId: Scalars["ID"]
}

export type GQLEnrollStudentToCohortInput = {
  cohortId: Scalars["ID"]
  studentId: Scalars["ID"]
}

export type GQLExistingPurchaseItemUpdate = {
  __typename?: "ExistingPurchaseItemUpdate"
  itemId: Scalars["ID"]
  priceUpdate: Scalars["Float"]
  quantityUpdate: Scalars["Float"]
}

export type GQLExpenditure = {
  __typename?: "Expenditure"
  itemId: Scalars["ID"]
  productId: Scalars["ID"]
  purchaseId: Scalars["ID"]
  quantity: Scalars["Int"]
}

export type GQLExpiration = {
  __typename?: "Expiration"
  createdAt: Scalars["DateTime"]
  createdById: Scalars["ID"]
  description: Scalars["String"]
  expirationType: GQLExpireProductsTypes
  id: Scalars["ID"]
  isVoid: Scalars["Boolean"]
  itemsToExpire: Array<GQLItemToExpire>
  purchaseIds: Array<Scalars["ID"]>
  studentId: Scalars["ID"]
  versionId: Scalars["ID"]
}

export type GQLExpirationQuery = {
  expirationId: Scalars["ID"]
  studentId: Scalars["ID"]
}

export type GQLExpireProductsInput = {
  description: Scalars["String"]
  itemsToExpire: Array<GQLItemToExpireInput>
  purchaseId: Scalars["ID"]
  studentId: Scalars["ID"]
  type: GQLExpireProductsTypes
}

export enum GQLExpireProductsTypes {
  EXPIRED = "EXPIRED",
  NO_SHOW = "NO_SHOW",
  OTHER = "OTHER",
}

export type GQLFirebaseCourseSessionTime = {
  __typename?: "FirebaseCourseSessionTime"
  sessionEnd?: Maybe<Scalars["DateTime"]>
  sessionStart?: Maybe<Scalars["DateTime"]>
}

export type GQLFirebaseLessonReview = {
  __typename?: "FirebaseLessonReview"
  date: Scalars["DateTime"]
  rating: Scalars["Int"]
  source: Scalars["String"]
  studentId: Scalars["ID"]
  studentName: Scalars["String"]
  text: Scalars["String"]
}

export type GQLGeoJsonFeature = {
  __typename?: "GeoJsonFeature"
  geometry: GQLGeoJsonGeometry
  id: Scalars["ID"]
  properties: GQLGeoJsonProperties
  type: Scalars["String"]
}

export type GQLGeoJsonFeatureInput = {
  geometry: GQLGeoJsonGeometryInput
  id: Scalars["ID"]
  properties: GQLGeoJsonPropertiesInput
  type: Scalars["String"]
}

export type GQLGeoJsonGeometry = {
  __typename?: "GeoJsonGeometry"
  coordinates: Array<Array<Array<Scalars["Float"]>>>
  type: Scalars["String"]
}

export type GQLGeoJsonGeometryInput = {
  coordinates: Array<Array<Array<Scalars["Float"]>>>
  type: Scalars["String"]
}

export type GQLGeoJsonProperties = {
  __typename?: "GeoJsonProperties"
  label?: Maybe<Scalars["String"]>
}

export type GQLGeoJsonPropertiesInput = {
  label: Scalars["String"]
}

export type GQLGetLessonReviewInput = {
  instructorId: Scalars["ID"]
  lessonId: Scalars["ID"]
  studentId: Scalars["ID"]
}

export type GQLIdLessonPackageInput = {
  id: Scalars["ID"]
  item: GQLProductPackageWithPriceInput
}

export type GQLIdToken = {
  __typename?: "IdToken"
  expiresIn: Scalars["Int"]
  idToken: Scalars["String"]
  refreshToken: Scalars["String"]
  userId: Scalars["ID"]
}

export type GQLImpersonateUserResponse = {
  __typename?: "ImpersonateUserResponse"
  token: Scalars["String"]
}

export enum GQLInitiatedBy {
  Coastline = "Coastline",
  Immediate_Reschedule = "Immediate_Reschedule",
  Instructor = "Instructor",
  Student = "Student",
}

export type GQLInstructor = {
  __typename?: "Instructor"
  about?: Maybe<Scalars["String"]>
  adminInfo: GQLInstructorAdminInfo
  id: Scalars["ID"]
  languages?: Maybe<Scalars["String"]>
  name: Scalars["String"]
  portraitImage?: Maybe<Scalars["String"]>
  privateInfo: GQLInstructorPrivateInfo
  region?: Maybe<Scalars["String"]>
  signInEmail?: Maybe<Scalars["String"]>
  summary?: Maybe<Scalars["String"]>
  superRegion?: Maybe<Scalars["String"]>
  timeZone: Scalars["String"]
  title?: Maybe<Scalars["String"]>
  usState?: Maybe<GQLUsState>
  usStateId?: Maybe<Scalars["ID"]>
}

export type GQLInstructorAdminInfo = {
  __typename?: "InstructorAdminInfo"
  badges?: Maybe<Array<GQLInstructorBadge>>
  payrollFirstName?: Maybe<Scalars["String"]>
  payrollLastName?: Maybe<Scalars["String"]>
  payrollTitle?: Maybe<Scalars["String"]>
  regionalManager?: Maybe<GQLPerson>
  regionalManagerId?: Maybe<Scalars["ID"]>
  userId: Scalars["ID"]
}

export type GQLInstructorAlert = {
  __typename?: "InstructorAlert"
  id: Scalars["ID"]
  message: Scalars["String"]
  readMoreLink?: Maybe<Scalars["String"]>
  severity: GQLInstructorAlertSeverity
  timestamp: Scalars["DateTime"]
}

export type GQLInstructorAlertCreateInput = {
  message: Scalars["String"]
  readMoreLink?: InputMaybe<Scalars["String"]>
  severity: GQLInstructorAlertSeverity
}

export type GQLInstructorAlertDeleteInput = {
  id: Scalars["ID"]
}

export type GQLInstructorAlertEdge = {
  __typename?: "InstructorAlertEdge"
  cursor: Scalars["ID"]
  node: GQLInstructorAlert
}

export type GQLInstructorAlertPaginatedList = {
  __typename?: "InstructorAlertPaginatedList"
  edges: Array<GQLInstructorAlertEdge>
  pageInfo: GQLPageInfo
  totalCount: Scalars["Int"]
}

export enum GQLInstructorAlertSeverity {
  Error = "error",
  Info = "info",
  Success = "success",
  Warning = "warning",
}

export type GQLInstructorAlertUpdateInput = {
  id: Scalars["ID"]
  message: Scalars["String"]
  readMoreLink?: InputMaybe<Scalars["String"]>
  severity: GQLInstructorAlertSeverity
}

export type GQLInstructorBadge = {
  __typename?: "InstructorBadge"
  icon: GQLInstructorBadgeIcon
  title: Scalars["String"]
}

export enum GQLInstructorBadgeIcon {
  Lessons100 = "lessons100",
  Lessons500 = "lessons500",
  Lessons1000 = "lessons1000",
  Lessons2000 = "lessons2000",
  Manager = "manager",
  Operator = "operator",
  OtherLanguage = "otherLanguage",
  TrainingSpecialist = "trainingSpecialist",
}

export type GQLInstructorBadgeInput = {
  icon: GQLInstructorBadgeIcon
  title: Scalars["String"]
}

export type GQLInstructorBadgeQuery = {
  instructorId: Scalars["ID"]
}

export type GQLInstructorEdge = {
  __typename?: "InstructorEdge"
  cursor: Scalars["ID"]
  node: GQLInstructor
}

export type GQLInstructorLessonUpdateInput = {
  actualDurationMinutes: Scalars["Int"]
  actualDurationNote: Scalars["String"]
}

export type GQLInstructorMutationTimeOffQuery = {
  id: Scalars["ID"]
  instructorId: Scalars["ID"]
}

export type GQLInstructorOnAvail = {
  __typename?: "InstructorOnAvail"
  displayName: Scalars["String"]
  id: Scalars["ID"]
  timeZone: Scalars["String"]
}

export type GQLInstructorPrivateInfo = {
  __typename?: "InstructorPrivateInfo"
  baseAddress?: Maybe<Scalars["String"]>
  breakTimes: Array<Scalars["Int"]>
  email: Scalars["String"]
  firstName: Scalars["String"]
  lastName: Scalars["String"]
  licenseExpirationDate?: Maybe<Scalars["DateTime"]>
  licenseNumber?: Maybe<Scalars["String"]>
  managerName?: Maybe<Scalars["String"]>
  maxBaseTravelMinutes?: Maybe<Scalars["Int"]>
  maxBetweenTravelMinutes?: Maybe<Scalars["Int"]>
  nonTeachingPay?: Maybe<Scalars["Int"]>
  phoneNumber?: Maybe<Scalars["String"]>
  slackUsername?: Maybe<Scalars["String"]>
  status: GQLInstructorStatus
  teachingPay?: Maybe<Scalars["Int"]>
}

export type GQLInstructorSchedule = {
  __typename?: "InstructorSchedule"
  createdAt: Scalars["DateTime"]
  dayTimesAvailable: Array<GQLScheduleDayTimes>
  daysPerWeek: Scalars["Int"]
  endDate: Scalars["String"]
  id: Scalars["ID"]
  instructorId: Scalars["ID"]
  instructorName?: Maybe<Scalars["String"]>
  isApproved: Scalars["Boolean"]
  managerName?: Maybe<Scalars["String"]>
  startDate: Scalars["String"]
  timesOff?: Maybe<Array<GQLInstructorTimeOff>>
  updatedAt: Scalars["DateTime"]
}

export type GQLInstructorScheduleDayTimes = {
  dayOfTheWeek: Scalars["String"]
  endTime: Scalars["String"]
  startTime: Scalars["String"]
}

export type GQLInstructorScheduleInput = {
  dayTimesAvailable: Array<GQLInstructorScheduleDayTimes>
  daysPerWeek: Scalars["Int"]
  endDate: Scalars["String"]
  instructorId: Scalars["ID"]
  startDate: Scalars["String"]
}

export type GQLInstructorScheduleMutateQuery = {
  instructorId: Scalars["ID"]
  scheduleId: Scalars["ID"]
}

export type GQLInstructorScheduleQuery = {
  endDate?: InputMaybe<Scalars["String"]>
  instructorId: Scalars["ID"]
  scheduleId?: InputMaybe<Scalars["ID"]>
  startDate?: InputMaybe<Scalars["String"]>
}

export type GQLInstructorScheduleUpdateInput = {
  dayTimesAvailable?: InputMaybe<Array<GQLInstructorScheduleDayTimes>>
  daysPerWeek?: InputMaybe<Scalars["Int"]>
  endDate?: InputMaybe<Scalars["String"]>
  isApproved?: InputMaybe<Scalars["Boolean"]>
  startDate?: InputMaybe<Scalars["String"]>
}

export type GQLInstructorSchedulesQuery = {
  endDate?: InputMaybe<Scalars["String"]>
  instructorIds?: InputMaybe<Array<Scalars["ID"]>>
  isApproved?: InputMaybe<Scalars["Boolean"]>
  scheduleIds?: InputMaybe<Array<Scalars["ID"]>>
  startDate?: InputMaybe<Scalars["String"]>
}

export enum GQLInstructorStatus {
  Active = "Active",
  Inactive = "Inactive",
  Retired = "Retired",
}

export type GQLInstructorTimeLog = {
  __typename?: "InstructorTimeLog"
  description?: Maybe<Scalars["String"]>
  duration: Scalars["Int"]
  endTime: Scalars["DateTime"]
  id: Scalars["ID"]
  instructor: GQLInstructor
  instructorId: Scalars["ID"]
  logType: GQLInstructorTimeLogType
  rateType: GQLInstructorTimeLogRateType
  startTime: Scalars["DateTime"]
}

export type GQLInstructorTimeLogCreateInput = {
  description?: InputMaybe<Scalars["String"]>
  endTime: Scalars["DateTime"]
  instructorId: Scalars["ID"]
  logType?: InputMaybe<GQLInstructorTimeLogType>
  rateType: GQLInstructorTimeLogRateType
  startTime: Scalars["DateTime"]
}

export type GQLInstructorTimeLogEdge = {
  __typename?: "InstructorTimeLogEdge"
  cursor: Scalars["ID"]
  node: GQLInstructorTimeLog
}

export type GQLInstructorTimeLogListQuery = {
  instructorId: Scalars["ID"]
}

export type GQLInstructorTimeLogPaginatedList = {
  __typename?: "InstructorTimeLogPaginatedList"
  edges: Array<GQLInstructorTimeLogEdge>
  pageInfo: GQLPageInfo
  totalCount: Scalars["Int"]
}

export type GQLInstructorTimeLogQuery = {
  id: Scalars["ID"]
  instructorId: Scalars["ID"]
}

export enum GQLInstructorTimeLogRateType {
  NonTeaching = "NonTeaching",
  Teaching = "Teaching",
}

export enum GQLInstructorTimeLogType {
  Drive = "Drive",
  Other = "Other",
}

export type GQLInstructorTimeLogUpdateInput = {
  description?: InputMaybe<Scalars["String"]>
  endTime?: InputMaybe<Scalars["DateTime"]>
  rateType?: InputMaybe<GQLInstructorTimeLogRateType>
  startTime?: InputMaybe<Scalars["DateTime"]>
}

export type GQLInstructorTimeOff = {
  __typename?: "InstructorTimeOff"
  createdAt: Scalars["DateTime"]
  endDate: Scalars["String"]
  id: Scalars["ID"]
  instructorId: Scalars["ID"]
  startDate: Scalars["String"]
  updatedAt: Scalars["DateTime"]
}

export type GQLInstructorTimeOffInput = {
  endDate: Scalars["String"]
  startDate: Scalars["String"]
}

export type GQLInstructorTimeOffQuery = {
  endDate?: InputMaybe<Scalars["String"]>
  id?: InputMaybe<Scalars["ID"]>
  instructorId: Scalars["ID"]
  startDate?: InputMaybe<Scalars["String"]>
}

export type GQLInstructorWithSuperRegion = {
  __typename?: "InstructorWithSuperRegion"
  about?: Maybe<Scalars["String"]>
  displayName: Scalars["String"]
  id: Scalars["ID"]
  isInactive?: Maybe<Scalars["Boolean"]>
  isRetired?: Maybe<Scalars["Boolean"]>
  languages?: Maybe<Scalars["String"]>
  portraitImage?: Maybe<Scalars["String"]>
  region?: Maybe<Scalars["String"]>
  slackUsername?: Maybe<Scalars["String"]>
  summary?: Maybe<Scalars["String"]>
  superRegion?: Maybe<Scalars["String"]>
  timeZone: Scalars["String"]
  title?: Maybe<Scalars["String"]>
  usStateId?: Maybe<Scalars["ID"]>
  userId: Scalars["String"]
  wallpaperImage?: Maybe<Scalars["String"]>
}

export type GQLInstructorZone = {
  __typename?: "InstructorZone"
  center: GQLLatLng
  features: Array<GQLGeoJsonFeature>
  instructorId: Scalars["ID"]
  instructorName: Scalars["String"]
  isStatic: Scalars["Boolean"]
}

export type GQLInstructorZoneQueryInput = {
  address: Scalars["String"]
  instructorId: Scalars["ID"]
  maxTravelMinutes: Scalars["Int"]
}

export type GQLInstructorsCoverage = {
  __typename?: "InstructorsCoverage"
  center: GQLLatLng
  instructorZones: Array<GQLInstructorZone>
}

export type GQLInstructorsPaginatedList = {
  __typename?: "InstructorsPaginatedList"
  edges: Array<GQLInstructorEdge>
  pageInfo: GQLPageInfo
  totalCount: Scalars["Int"]
}

export type GQLInstructorsQuery = {
  id?: InputMaybe<Scalars["ID"]>
  region?: InputMaybe<Scalars["String"]>
  regionalManagerId?: InputMaybe<Scalars["ID"]>
  state?: InputMaybe<Scalars["ID"]>
  status?: InputMaybe<GQLInstructorStatus>
  term?: InputMaybe<Scalars["String"]>
}

export type GQLInstructorsSchedules = {
  __typename?: "InstructorsSchedules"
  instructorId: Scalars["ID"]
  name?: Maybe<Scalars["String"]>
  schedules?: Maybe<Array<GQLInstructorSchedule>>
}

export type GQLInstructorsTimeOffQuery = {
  endDate?: InputMaybe<Scalars["String"]>
  instructorIds: Array<Scalars["ID"]>
  startDate?: InputMaybe<Scalars["String"]>
}

export type GQLItemInput = {
  customAmount?: InputMaybe<Scalars["Float"]>
  itemId: Scalars["ID"]
  products?: InputMaybe<Array<GQLSubItemInput>>
  quantity: Scalars["Int"]
}

export type GQLItemToExpire = {
  __typename?: "ItemToExpire"
  expiredQuantity: Scalars["Int"]
  itemId: Scalars["ID"]
  productId: Scalars["ID"]
}

export type GQLItemToExpireInput = {
  expiredQuantity: Scalars["Int"]
  itemId: Scalars["ID"]
}

export type GQLItemToRefund = {
  __typename?: "ItemToRefund"
  itemId: Scalars["ID"]
  productId: Scalars["ID"]
  refundedQuantity: Scalars["Int"]
}

export type GQLItemToRefundInput = {
  itemId: Scalars["ID"]
  refundedQuantity: Scalars["Int"]
}

export type GQLItemToTransfer = {
  __typename?: "ItemToTransfer"
  itemId: Scalars["ID"]
  productId: Scalars["ID"]
  transferredQuantity: Scalars["Int"]
}

export type GQLItemToTransferAndExpire = {
  __typename?: "ItemToTransferAndExpire"
  itemId: Scalars["ID"]
  productId: Scalars["ID"]
  purchaseId: Scalars["ID"]
  quantity: Scalars["Int"]
}

export type GQLItemToTransferInput = {
  itemId: Scalars["ID"]
  transferredQuantity: Scalars["Int"]
}

export type GQLLastBookedInstructor = {
  __typename?: "LastBookedInstructor"
  displayName: Scalars["String"]
  id: Scalars["ID"]
}

export type GQLLatLng = {
  __typename?: "LatLng"
  lat: Scalars["Float"]
  lng: Scalars["Float"]
}

export type GQLLectureBlockColumn =
  | GQLLectureSingleImageBlock
  | GQLLectureTitleBlock

export type GQLLectureBlockTextColumn = {
  __typename?: "LectureBlockTextColumn"
  structuredText: Scalars["JSONObject"]
}

export type GQLLectureColumnImage = {
  __typename?: "LectureColumnImage"
  image: GQLLectureContentImage
}

export type GQLLectureContentBlock =
  | GQLLectureSingleImageBlock
  | GQLLectureSingleVideoBlock
  | GQLLectureTitleBlock
  | GQLLectureTwoColumnBlock

export enum GQLLectureContentBlockType {
  SingleImage = "singleImage",
  SingleVideo = "singleVideo",
  Title = "title",
  TwoColumn = "twoColumn",
}

export type GQLLectureContentImage = {
  __typename?: "LectureContentImage"
  alt?: Maybe<Scalars["String"]>
  aspectRatio?: Maybe<Scalars["Float"]>
  base64?: Maybe<Scalars["String"]>
  bgColor?: Maybe<Scalars["String"]>
  height?: Maybe<Scalars["Int"]>
  sizes?: Maybe<Scalars["String"]>
  src: Scalars["String"]
  srcSet?: Maybe<Scalars["String"]>
  title?: Maybe<Scalars["String"]>
  webpSrcSet?: Maybe<Scalars["String"]>
  width: Scalars["Int"]
}

export type GQLLectureContentPart = {
  __typename?: "LectureContentPart"
  blocks: Array<GQLLectureContentBlock>
  id: Scalars["ID"]
  structuredText?: Maybe<Scalars["JSONObject"]>
  type: GQLLecturePartType
  video?: Maybe<GQLLectureContentVideo>
}

export type GQLLectureContentVideo = {
  __typename?: "LectureContentVideo"
  thumbnailUrl: Scalars["String"]
  title: Scalars["String"]
  url: Scalars["String"]
}

export type GQLLecturePart = GQLLectureContentPart | GQLLectureQuizPart

export enum GQLLecturePartType {
  Content = "content",
  Quiz = "quiz",
}

export type GQLLectureQuestionChoice = {
  __typename?: "LectureQuestionChoice"
  id: Scalars["ID"]
  option: Scalars["String"]
}

export type GQLLectureQuizPart = {
  __typename?: "LectureQuizPart"
  id: Scalars["ID"]
  questions: Array<GQLLectureQuizQuestion>
  type: GQLLecturePartType
}

export type GQLLectureQuizQuestion = {
  __typename?: "LectureQuizQuestion"
  answer: Scalars["String"]
  choices?: Maybe<Array<GQLLectureQuestionChoice>>
  doShuffle: Scalars["Boolean"]
  id: Scalars["ID"]
  image?: Maybe<GQLLectureContentImage>
  question: Scalars["String"]
}

export type GQLLectureQuizQuestionPrepTestScore = {
  __typename?: "LectureQuizQuestionPrepTestScore"
  id: Scalars["ID"]
  isCorrect: Scalars["Boolean"]
}

export type GQLLectureQuizQuestionPrepTestScoreInput = {
  id: Scalars["ID"]
  isCorrect: Scalars["Boolean"]
}

export type GQLLectureReport = {
  __typename?: "LectureReport"
  id: Scalars["ID"]
  testScore?: Maybe<Scalars["Float"]>
  totalTimeSeconds?: Maybe<Scalars["Int"]>
}

export type GQLLectureSessionTime = {
  __typename?: "LectureSessionTime"
  lectureId?: Maybe<Scalars["ID"]>
  session?: Maybe<GQLFirebaseCourseSessionTime>
}

export type GQLLectureSingleImageBlock = {
  __typename?: "LectureSingleImageBlock"
  id: Scalars["ID"]
  image: GQLLectureContentImage
  type: GQLLectureContentBlockType
}

export type GQLLectureSingleVideoBlock = {
  __typename?: "LectureSingleVideoBlock"
  id: Scalars["ID"]
  type: GQLLectureContentBlockType
  video?: Maybe<GQLLectureContentVideo>
}

export type GQLLectureTimeDaily = {
  __typename?: "LectureTimeDaily"
  date: Scalars["String"]
  totalTimeSeconds: Scalars["Int"]
}

export type GQLLectureTitleBlock = {
  __typename?: "LectureTitleBlock"
  id: Scalars["ID"]
  title: Scalars["String"]
  type: GQLLectureContentBlockType
}

export type GQLLectureTwoColumnBlock = {
  __typename?: "LectureTwoColumnBlock"
  column1: Array<GQLLectureBlockColumn>
  column2: GQLLectureBlockTextColumn
  id: Scalars["ID"]
  type: GQLLectureContentBlockType
}

export type GQLLedgerLog =
  | GQLLedgerLogCancelLesson
  | GQLLedgerLogCharge
  | GQLLedgerLogEditPurchase
  | GQLLedgerLogExpireProducts
  | GQLLedgerLogRefund
  | GQLLedgerLogRemoveStudentFromLesson
  | GQLLedgerLogScheduleLesson
  | GQLLedgerLogScheduleMultipleLessons
  | GQLLedgerLogTransferAccount
  | GQLLedgerLogTransferExpiration
  | GQLLedgerLogTransferLesson
  | GQLLedgerLogTransferPurchase
  | GQLLedgerLogUpdateLesson
  | GQLLedgerLogVoidExpiration
  | GQLLedgerLogVoidPurchase
  | GQLLedgerLogVoidRefund

export type GQLLedgerLogCancelLesson = GQLBaseLedgerLog & {
  __typename?: "LedgerLogCancelLesson"
  createdById: Scalars["ID"]
  description?: Maybe<Scalars["String"]>
  id: Scalars["ID"]
  lessonId: Scalars["ID"]
  purchaseIds: Array<Scalars["ID"]>
  summary: Scalars["String"]
  timestamp: Scalars["DateTime"]
  type: GQLLedgerLogTypes
}

export type GQLLedgerLogCancelLessonSummaryArgs = {
  studentId: Scalars["ID"]
}

export type GQLLedgerLogCharge = GQLBaseLedgerLog & {
  __typename?: "LedgerLogCharge"
  amountDollars: Scalars["Float"]
  categoryId: GQLPaymentCategories
  couponCode?: Maybe<Scalars["String"]>
  couponDiscount?: Maybe<Scalars["Float"]>
  createdById: Scalars["ID"]
  description: Scalars["String"]
  id: Scalars["ID"]
  items: Array<GQLPurchaseItem>
  paymentCaptureDate?: Maybe<Scalars["DateTime"]>
  purchaseId: Scalars["ID"]
  studentId: Scalars["ID"]
  summary: Scalars["String"]
  timestamp: Scalars["DateTime"]
  type: GQLLedgerLogTypes
  usStateId: GQLUsStateId
  voucherCode?: Maybe<Scalars["String"]>
}

export type GQLLedgerLogChargeSummaryArgs = {
  studentId: Scalars["ID"]
}

export type GQLLedgerLogEditPurchase = GQLBaseLedgerLog & {
  __typename?: "LedgerLogEditPurchase"
  categoryId?: Maybe<GQLPaymentCategories>
  couponCode?: Maybe<Scalars["String"]>
  couponDiscount?: Maybe<Scalars["Float"]>
  createdById: Scalars["ID"]
  description?: Maybe<Scalars["String"]>
  id: Scalars["ID"]
  itemsUpdates?: Maybe<Array<GQLPurchaseItemUpdate>>
  paymentCaptureDate?: Maybe<Scalars["DateTime"]>
  purchaseId: Scalars["ID"]
  stripeChargeId?: Maybe<Scalars["String"]>
  stripeCustomerId?: Maybe<Scalars["String"]>
  studentId: Scalars["ID"]
  summary: Scalars["String"]
  timestamp: Scalars["DateTime"]
  type: GQLLedgerLogTypes
  usStateId?: Maybe<GQLUsStateId>
  voucherCode?: Maybe<Scalars["String"]>
}

export type GQLLedgerLogEditPurchaseSummaryArgs = {
  studentId: Scalars["ID"]
}

export type GQLLedgerLogExpireProducts = GQLBaseLedgerLog & {
  __typename?: "LedgerLogExpireProducts"
  createdById: Scalars["ID"]
  description: Scalars["String"]
  expirationType: GQLExpireProductsTypes
  id: Scalars["ID"]
  isVoid: Scalars["Boolean"]
  itemsToExpire: Array<GQLItemToExpire>
  purchaseId: Scalars["ID"]
  studentId: Scalars["ID"]
  summary: Scalars["String"]
  timestamp: Scalars["DateTime"]
  type: GQLLedgerLogTypes
}

export type GQLLedgerLogExpireProductsSummaryArgs = {
  studentId: Scalars["ID"]
}

export type GQLLedgerLogRefund = GQLBaseLedgerLog & {
  __typename?: "LedgerLogRefund"
  amountToRefund: Scalars["Float"]
  categoryId: GQLPaymentCategories
  createdById: Scalars["ID"]
  description: Scalars["String"]
  id: Scalars["ID"]
  itemsToRefund?: Maybe<Array<GQLItemToRefund>>
  purchaseId: Scalars["ID"]
  studentId: Scalars["ID"]
  summary: Scalars["String"]
  timestamp: Scalars["DateTime"]
  type: GQLLedgerLogTypes
}

export type GQLLedgerLogRefundSummaryArgs = {
  studentId: Scalars["ID"]
}

export type GQLLedgerLogRemoveStudentFromLesson = GQLBaseLedgerLog & {
  __typename?: "LedgerLogRemoveStudentFromLesson"
  createdById: Scalars["ID"]
  expenditures: Array<GQLExpenditure>
  id: Scalars["ID"]
  initiatedBy: Scalars["ID"]
  lessonId: Scalars["ID"]
  reason: Scalars["String"]
  studentId: Scalars["ID"]
  summary: Scalars["String"]
  timestamp: Scalars["DateTime"]
  type: GQLLedgerLogTypes
}

export type GQLLedgerLogRemoveStudentFromLessonSummaryArgs = {
  studentId: Scalars["ID"]
}

export type GQLLedgerLogScheduleLesson = GQLBaseLedgerLog & {
  __typename?: "LedgerLogScheduleLesson"
  createdById: Scalars["ID"]
  expenditures: Array<GQLExpenditure>
  id: Scalars["ID"]
  lessonId: Scalars["ID"]
  studentId: Scalars["ID"]
  summary: Scalars["String"]
  timestamp: Scalars["DateTime"]
  type: GQLLedgerLogTypes
}

export type GQLLedgerLogScheduleLessonSummaryArgs = {
  studentId: Scalars["ID"]
}

export type GQLLedgerLogScheduleMultipleLessons = GQLBaseLedgerLog & {
  __typename?: "LedgerLogScheduleMultipleLessons"
  createdById: Scalars["ID"]
  id: Scalars["ID"]
  studentId: Scalars["ID"]
  summary: Scalars["String"]
  timestamp: Scalars["DateTime"]
  type: GQLLedgerLogTypes
}

export type GQLLedgerLogScheduleMultipleLessonsSummaryArgs = {
  studentId: Scalars["ID"]
}

export type GQLLedgerLogTransferAccount = GQLBaseLedgerLog & {
  __typename?: "LedgerLogTransferAccount"
  createdById: Scalars["ID"]
  description?: Maybe<Scalars["String"]>
  destinationStudentId: Scalars["ID"]
  id: Scalars["ID"]
  purchaseIds: Array<Scalars["ID"]>
  sourceStudentId: Scalars["ID"]
  summary: Scalars["String"]
  timestamp: Scalars["DateTime"]
  type: GQLLedgerLogTypes
}

export type GQLLedgerLogTransferAccountSummaryArgs = {
  studentId: Scalars["ID"]
}

export type GQLLedgerLogTransferExpiration = GQLBaseLedgerLog & {
  __typename?: "LedgerLogTransferExpiration"
  createdById: Scalars["ID"]
  description?: Maybe<Scalars["String"]>
  destinationStudentId: Scalars["ID"]
  expirationId: Scalars["ID"]
  id: Scalars["ID"]
  isVoid: Scalars["Boolean"]
  itemsToTransferAndExpire: Array<GQLItemToTransferAndExpire>
  purchaseIds: Array<Scalars["ID"]>
  sourceStudentId: Scalars["ID"]
  summary: Scalars["String"]
  timestamp: Scalars["DateTime"]
  type: GQLLedgerLogTypes
}

export type GQLLedgerLogTransferExpirationSummaryArgs = {
  studentId: Scalars["ID"]
}

export type GQLLedgerLogTransferLesson = GQLBaseLedgerLog & {
  __typename?: "LedgerLogTransferLesson"
  createdById: Scalars["ID"]
  description?: Maybe<Scalars["String"]>
  destinationStudentId: Scalars["ID"]
  id: Scalars["ID"]
  lessonId: Scalars["ID"]
  purchaseIds: Array<Scalars["ID"]>
  sourceStudentId: Scalars["ID"]
  summary: Scalars["String"]
  timestamp: Scalars["DateTime"]
  type: GQLLedgerLogTypes
}

export type GQLLedgerLogTransferLessonSummaryArgs = {
  studentId: Scalars["ID"]
}

export type GQLLedgerLogTransferPurchase = GQLBaseLedgerLog & {
  __typename?: "LedgerLogTransferPurchase"
  amountToTransfer: Scalars["Float"]
  createdById: Scalars["ID"]
  description?: Maybe<Scalars["String"]>
  destinationStudentId: Scalars["ID"]
  id: Scalars["ID"]
  itemsToTransfer: Array<GQLItemToTransfer>
  purchaseId: Scalars["ID"]
  sourceStudentId: Scalars["ID"]
  summary: Scalars["String"]
  timestamp: Scalars["DateTime"]
  type: GQLLedgerLogTypes
}

export type GQLLedgerLogTransferPurchaseSummaryArgs = {
  studentId: Scalars["ID"]
}

export enum GQLLedgerLogTypes {
  CancelLesson = "cancelLesson",
  Charge = "charge",
  EditPurchase = "editPurchase",
  ExpireProducts = "expireProducts",
  Refund = "refund",
  RemoveStudentFromLesson = "removeStudentFromLesson",
  ScheduleLesson = "scheduleLesson",
  ScheduleMultipleLessons = "scheduleMultipleLessons",
  TransferAccount = "transferAccount",
  TransferExpiration = "transferExpiration",
  TransferLesson = "transferLesson",
  TransferPurchase = "transferPurchase",
  UpdateLesson = "updateLesson",
  VoidExpiration = "voidExpiration",
  VoidPurchase = "voidPurchase",
  VoidRefund = "voidRefund",
}

export type GQLLedgerLogUpdateLesson = GQLBaseLedgerLog & {
  __typename?: "LedgerLogUpdateLesson"
  createdById: Scalars["ID"]
  id: Scalars["ID"]
  lessonId: Scalars["ID"]
  studentIds: Array<Scalars["ID"]>
  summary: Scalars["String"]
  timestamp: Scalars["DateTime"]
  type: GQLLedgerLogTypes
}

export type GQLLedgerLogUpdateLessonSummaryArgs = {
  studentId: Scalars["ID"]
}

export type GQLLedgerLogVoidExpiration = GQLBaseLedgerLog & {
  __typename?: "LedgerLogVoidExpiration"
  createdById: Scalars["ID"]
  expirationId: Scalars["ID"]
  id: Scalars["ID"]
  purchaseId: Scalars["ID"]
  studentId: Scalars["ID"]
  summary: Scalars["String"]
  timestamp: Scalars["DateTime"]
  type: GQLLedgerLogTypes
}

export type GQLLedgerLogVoidExpirationSummaryArgs = {
  studentId: Scalars["ID"]
}

export type GQLLedgerLogVoidPurchase = GQLBaseLedgerLog & {
  __typename?: "LedgerLogVoidPurchase"
  createdById: Scalars["ID"]
  description: Scalars["String"]
  id: Scalars["ID"]
  purchaseId: Scalars["ID"]
  studentId: Scalars["ID"]
  summary: Scalars["String"]
  timestamp: Scalars["DateTime"]
  type: GQLLedgerLogTypes
}

export type GQLLedgerLogVoidPurchaseSummaryArgs = {
  studentId: Scalars["ID"]
}

export type GQLLedgerLogVoidRefund = GQLBaseLedgerLog & {
  __typename?: "LedgerLogVoidRefund"
  createdById: Scalars["ID"]
  description: Scalars["String"]
  id: Scalars["ID"]
  purchaseId: Scalars["ID"]
  refundId: Scalars["ID"]
  studentId: Scalars["ID"]
  summary: Scalars["String"]
  timestamp: Scalars["DateTime"]
  type: GQLLedgerLogTypes
}

export type GQLLedgerLogVoidRefundSummaryArgs = {
  studentId: Scalars["ID"]
}

export type GQLLedgerLogsEdge = {
  __typename?: "LedgerLogsEdge"
  cursor: Scalars["ID"]
  node: GQLLedgerLog
}

export type GQLLedgerLogsPaginatedList = {
  __typename?: "LedgerLogsPaginatedList"
  edges: Array<GQLLedgerLogsEdge>
  pageInfo: GQLPageInfo
  totalCount: Scalars["Int"]
}

export type GQLLedgerLogsQuery = {
  studentId: Scalars["ID"]
  types?: InputMaybe<Array<GQLLedgerLogTypes>>
}

export type GQLLegacyLogSummary = {
  __typename?: "LegacyLogSummary"
  id: Scalars["ID"]
  timestamp: Scalars["DateTime"]
  type: Scalars["String"]
}

export type GQLLesson = {
  __typename?: "Lesson"
  actualDurationMinutes?: Maybe<Scalars["Int"]>
  actualDurationNote?: Maybe<Scalars["String"]>
  bookedAt: Scalars["DateTime"]
  comments?: Maybe<Scalars["String"]>
  durationMinutes: Scalars["Int"]
  endTime: Scalars["DateTime"]
  hasHomePickup: Scalars["Boolean"]
  id: Scalars["ID"]
  instructor: GQLInstructor
  instructorId: Scalars["ID"]
  isCancelled: Scalars["Boolean"]
  isObserverAllowed: Scalars["Boolean"]
  isShareable: Scalars["Boolean"]
  locationId?: Maybe<Scalars["ID"]>
  meetingPointId?: Maybe<Scalars["ID"]>
  pickupLocation: Scalars["String"]
  pickupLocationCoords?: Maybe<GQLLatLng>
  price: Scalars["Float"]
  startTime: Scalars["DateTime"]
  studentDetails: GQLLessonStudentDetails
  studentDetailsList: Array<GQLLessonStudentDetails>
  studentIds: Array<Scalars["ID"]>
  updatedAt: Scalars["DateTime"]
}

export type GQLLessonStudentDetailsArgs = {
  studentId: Scalars["ID"]
}

export type GQLLessonCancelQuery = {
  id: Scalars["ID"]
  instructorId: Scalars["ID"]
}

export type GQLLessonCancellationInfo = {
  cancelPolicy?: InputMaybe<GQLCancelPolicy>
  description: Scalars["String"]
  initiatedBy: GQLInitiatedBy
}

export type GQLLessonCoupon = {
  __typename?: "LessonCoupon"
  code: Scalars["String"]
  discount: Scalars["Float"]
  displayInLandingPages?: Maybe<Scalars["Boolean"]>
  expiration?: Maybe<Scalars["DateTime"]>
  id: Scalars["ID"]
  onlyFirstPurchase: Scalars["Boolean"]
  regionId: Scalars["ID"]
  regions: Array<GQLRegion>
  title: Scalars["String"]
  type: GQLCouponType
}

export type GQLLessonCouponInput = {
  code: Scalars["String"]
  discount: Scalars["Float"]
  displayInLandingPages?: InputMaybe<Scalars["Boolean"]>
  expiration?: InputMaybe<Scalars["DateTime"]>
  onlyFirstPurchase: Scalars["Boolean"]
  regionIds: Array<Scalars["ID"]>
  title: Scalars["String"]
}

export type GQLLessonDrivingRatingChange = {
  drivingSkillId: Scalars["ID"]
  rating: GQLDrivingSkillRating
}

export type GQLLessonDrivingSkillRating = {
  __typename?: "LessonDrivingSkillRating"
  drivingSkill: GQLDrivingSkill
  drivingSkillId: Scalars["ID"]
  id: Scalars["ID"]
  lesson: GQLLesson
  lessonId: Scalars["ID"]
  rating: GQLDrivingSkillRating
  student: GQLStudent
  studentId: Scalars["ID"]
}

export type GQLLessonDrivingSkillRatingInput = {
  lessonDrivingRatingChange: Array<GQLLessonDrivingRatingChange>
  lessonId: Scalars["ID"]
  studentId: Scalars["ID"]
}

export type GQLLessonDrivingSkillsReport = {
  __typename?: "LessonDrivingSkillsReport"
  drivingSkillsRatings: Array<GQLLessonDrivingSkillRating>
  id: Scalars["ID"]
  lastEmailSent?: Maybe<Scalars["DateTime"]>
  lesson: GQLLesson
}

export type GQLLessonDrivingSkillsReportEdge = {
  __typename?: "LessonDrivingSkillsReportEdge"
  cursor: Scalars["ID"]
  node: GQLLessonDrivingSkillsReport
}

export type GQLLessonDrivingSkillsReportPaginatedList = {
  __typename?: "LessonDrivingSkillsReportPaginatedList"
  edges: Array<GQLLessonDrivingSkillsReportEdge>
  pageInfo: GQLPageInfo
  totalCount: Scalars["Int"]
}

export type GQLLessonEdge = {
  __typename?: "LessonEdge"
  cursor: Scalars["ID"]
  node: GQLLesson
}

export type GQLLessonPackage = {
  __typename?: "LessonPackage"
  drivingMinutes: Scalars["Int"]
  id: Scalars["String"]
  isSharedLessons?: Maybe<Scalars["Boolean"]>
  numLessons: Scalars["Int"]
  numRoadTests: Scalars["Int"]
  observationMinutes: Scalars["Int"]
  totalMinutes: Scalars["Int"]
}

export type GQLLessonPackageInput = {
  drivingMinutes: Scalars["Int"]
  id: Scalars["String"]
  isSharedLessons?: InputMaybe<Scalars["Boolean"]>
  numLessons: Scalars["Int"]
  numRoadTests: Scalars["Int"]
  observationMinutes: Scalars["Int"]
  totalMinutes: Scalars["Int"]
}

export type GQLLessonQuery = {
  id: Scalars["ID"]
  studentId: Scalars["ID"]
}

export type GQLLessonRemoveStudentQuery = {
  id: Scalars["ID"]
  instructorId: Scalars["ID"]
  studentId: Scalars["ID"]
}

export type GQLLessonReview = {
  __typename?: "LessonReview"
  date: Scalars["DateTime"]
  id: Scalars["ID"]
  instructor: GQLInstructor
  instructorId: Scalars["ID"]
  rating: Scalars["Int"]
  source?: Maybe<Scalars["String"]>
  student: GQLStudent
  studentId: Scalars["ID"]
  text?: Maybe<Scalars["String"]>
}

export type GQLLessonReviewCreateInput = {
  date: Scalars["DateTime"]
  instructorId: Scalars["ID"]
  lessonId: Scalars["ID"]
  rating: Scalars["Int"]
  source?: InputMaybe<Scalars["String"]>
  studentId: Scalars["ID"]
  studentName?: InputMaybe<Scalars["String"]>
  text?: InputMaybe<Scalars["String"]>
}

export type GQLLessonReviewEdge = {
  __typename?: "LessonReviewEdge"
  cursor: Scalars["ID"]
  node: GQLLessonReview
}

export type GQLLessonStudentDetails = {
  __typename?: "LessonStudentDetails"
  dmvConfirmationCode?: Maybe<Scalars["String"]>
  dmvLocation?: Maybe<Scalars["String"]>
  hasReview: Scalars["Boolean"]
  hasSentReportToStudent: Scalars["Boolean"]
  isObserverAllowed: Scalars["Boolean"]
  lessonId: Scalars["ID"]
  pickupDetails?: Maybe<Scalars["String"]>
  productQuantity: Scalars["Int"]
  productQuantityList: Array<GQLProductQuantity>
  student: GQLStudent
  studentId: Scalars["ID"]
}

export type GQLLessonStudentDetailsProductQuantityArgs = {
  productId: Scalars["ID"]
}

export type GQLLessonStudentDetailsInput = {
  allowObserver?: InputMaybe<Scalars["String"]>
  dmvConfirmationCode?: InputMaybe<Scalars["String"]>
  dmvLocation?: InputMaybe<Scalars["String"]>
  drivingHistory?: InputMaybe<Scalars["String"]>
  pickupDetails?: InputMaybe<Scalars["String"]>
  previousSchoolLessons?: InputMaybe<Scalars["String"]>
}

export type GQLLessonStudentDetailsUpdateInput = {
  dmvLocation?: InputMaybe<Scalars["String"]>
  drivingMinutes: Scalars["Float"]
  id: Scalars["ID"]
  observationMinutes: Scalars["Float"]
  pickupDetails?: InputMaybe<Scalars["String"]>
}

export type GQLLessonType = {
  __typename?: "LessonType"
  description?: Maybe<Scalars["String"]>
  durationMinutes: Scalars["Float"]
  features: Array<GQLLessonTypeFeature>
  id: Scalars["ID"]
  label: Scalars["String"]
  name: Scalars["String"]
}

export type GQLLessonTypeCreateInput = {
  description?: InputMaybe<Scalars["String"]>
  durationMinutes: Scalars["Float"]
  label: Scalars["String"]
  name: Scalars["String"]
}

export type GQLLessonTypeEdge = {
  __typename?: "LessonTypeEdge"
  cursor: Scalars["ID"]
  node: GQLLessonType
}

export type GQLLessonTypeFeature = {
  __typename?: "LessonTypeFeature"
  content: Array<Scalars["String"]>
  state: GQLUsState
}

export type GQLLessonTypeFeaturesInput = {
  content: Array<Scalars["String"]>
  usState: Scalars["ID"]
}

export type GQLLessonTypeForStateConfigInput = {
  lessonTypeFeatures: Array<GQLLessonTypeFeaturesInput>
  lessonTypeId: Scalars["ID"]
}

export type GQLLessonTypeInput = {
  durationMinutes: Scalars["Int"]
  filterLabel?: InputMaybe<Scalars["String"]>
  label: Scalars["String"]
  name: Scalars["String"]
}

export type GQLLessonTypeUpdateInput = {
  description?: InputMaybe<Scalars["String"]>
  durationMinutes: Scalars["Float"]
  id: Scalars["ID"]
  label: Scalars["String"]
  name: Scalars["String"]
}

export type GQLLessonTypesPaginatedList = {
  __typename?: "LessonTypesPaginatedList"
  edges: Array<GQLLessonTypeEdge>
  pageInfo: GQLPageInfo
  totalCount: Scalars["Int"]
}

export type GQLLessonUpdateAddStudent = {
  dmvConfirmationCode?: InputMaybe<Scalars["String"]>
  dmvLocation?: InputMaybe<Scalars["String"]>
  drivingHistory?: InputMaybe<Scalars["String"]>
  isObserverAllowed: Scalars["Boolean"]
  pickupDetails?: InputMaybe<Scalars["String"]>
  previousSchoolLessons?: InputMaybe<Scalars["String"]>
  productQuantityList: Array<GQLProductQuantityInput>
  studentId: Scalars["ID"]
}

export type GQLLessonUpdateChangeLessonDetails = {
  actualDurationMinutes?: InputMaybe<Scalars["Int"]>
  actualDurationNote?: InputMaybe<Scalars["String"]>
  endTime?: InputMaybe<Scalars["DateTime"]>
  isShareable?: InputMaybe<Scalars["Boolean"]>
  lessonType?: InputMaybe<GQLLessonTypeInput>
  pickupLocation?: InputMaybe<Scalars["String"]>
  price?: InputMaybe<Scalars["Float"]>
  startTime?: InputMaybe<Scalars["DateTime"]>
}

export type GQLLessonUpdateChangeStudentDetails = {
  dmvConfirmationCode?: InputMaybe<Scalars["String"]>
  dmvLocation?: InputMaybe<Scalars["String"]>
  drivingHistory?: InputMaybe<Scalars["String"]>
  isObserverAllowed?: InputMaybe<Scalars["Boolean"]>
  pickupDetails?: InputMaybe<Scalars["String"]>
  previousSchoolLessons?: InputMaybe<Scalars["String"]>
  productQuantityList?: InputMaybe<Array<GQLProductQuantityInput>>
  studentId: Scalars["ID"]
}

export type GQLLessonUpdateInput = {
  addStudent?: InputMaybe<Array<GQLLessonUpdateAddStudent>>
  changeLessonDetails?: InputMaybe<GQLLessonUpdateChangeLessonDetails>
  changeStudentDetails?: InputMaybe<Array<GQLLessonUpdateChangeStudentDetails>>
}

export type GQLLessonUpdateQuery = {
  id: Scalars["ID"]
  instructorId: Scalars["ID"]
}

export type GQLLessonsDrivingSkillsReportsQuery = {
  studentId: Scalars["ID"]
}

export type GQLLessonsPaginatedList = {
  __typename?: "LessonsPaginatedList"
  edges: Array<GQLLessonEdge>
  pageInfo: GQLPageInfo
  totalCount: Scalars["Int"]
}

export type GQLLessonsQuery = {
  instructorId?: InputMaybe<Scalars["ID"]>
  studentId: Scalars["ID"]
}

export type GQLLessonsQueryByInstructorIds = {
  endTime: Scalars["DateTime"]
  instructorIds: Array<Scalars["ID"]>
  startTime: Scalars["DateTime"]
}

export type GQLLessonsReviewsByInstructorQuery = {
  instructorId: Scalars["ID"]
}

export type GQLLessonsReviewsPaginatedList = {
  __typename?: "LessonsReviewsPaginatedList"
  edges: Array<GQLLessonReviewEdge>
  pageInfo: GQLPageInfo
  totalCount: Scalars["Int"]
}

export type GQLLocation = {
  __typename?: "Location"
  address: Scalars["String"]
  lat: Scalars["Float"]
  lng: Scalars["Float"]
  neighborhood: Scalars["String"]
  neighborhoodLat: Scalars["Float"]
  neighborhoodLng: Scalars["Float"]
}

export type GQLLocationCenter = {
  __typename?: "LocationCenter"
  address: Scalars["String"]
  geocode?: Maybe<GQLCenterGeocode>
}

export type GQLLocationLandingPage = {
  __typename?: "LocationLandingPage"
  center: GQLLocationCenter
  cmsId?: Maybe<Scalars["String"]>
  googleMyBusinessUrl?: Maybe<Scalars["String"]>
  hubId?: Maybe<Scalars["ID"]>
  id: Scalars["ID"]
  meetingPointIds?: Maybe<Array<Scalars["String"]>>
  metroId?: Maybe<Scalars["ID"]>
  name: Scalars["String"]
  priceSchemeId: Scalars["String"]
  state: GQLUsStateId
}

export type GQLLocationLandingPageEdge = {
  __typename?: "LocationLandingPageEdge"
  cursor: Scalars["ID"]
  node: GQLLocationLandingPage
}

export type GQLLocationLandingPageInput = {
  address: Scalars["String"]
  cmsId?: InputMaybe<Scalars["String"]>
  googleMyBusinessUrl?: InputMaybe<Scalars["String"]>
  hubId?: InputMaybe<Scalars["ID"]>
  meetingPointIds?: InputMaybe<Array<Scalars["String"]>>
  metroId?: InputMaybe<Scalars["ID"]>
  name: Scalars["String"]
  priceSchemeId: Scalars["String"]
  state: Scalars["String"]
}

export type GQLLocationLandingPagePaginatedList = {
  __typename?: "LocationLandingPagePaginatedList"
  edges: Array<GQLLocationLandingPageEdge>
  pageInfo: GQLPageInfo
  totalCount: Scalars["Int"]
}

export type GQLLogChange =
  | GQLLogDateTimeChange
  | GQLLogFloatChange
  | GQLLogIntChange
  | GQLLogStringChange

export type GQLLogChangeBase = {
  path: Scalars["String"]
  valueType: GQLLogChangeValueType
}

export enum GQLLogChangeValueType {
  DateTime = "DateTime",
  Float = "Float",
  Int = "Int",
  String = "String",
}

export type GQLLogDateTimeChange = GQLLogChangeBase & {
  __typename?: "LogDateTimeChange"
  newDateTime?: Maybe<Scalars["DateTime"]>
  oldDateTime?: Maybe<Scalars["DateTime"]>
  path: Scalars["String"]
  valueType: GQLLogChangeValueType
}

export type GQLLogFloatChange = GQLLogChangeBase & {
  __typename?: "LogFloatChange"
  newFloat?: Maybe<Scalars["Float"]>
  oldFloat?: Maybe<Scalars["Float"]>
  path: Scalars["String"]
  valueType: GQLLogChangeValueType
}

export type GQLLogIntChange = GQLLogChangeBase & {
  __typename?: "LogIntChange"
  newInt?: Maybe<Scalars["Int"]>
  oldInt?: Maybe<Scalars["Int"]>
  path: Scalars["String"]
  valueType: GQLLogChangeValueType
}

export type GQLLogStringChange = GQLLogChangeBase & {
  __typename?: "LogStringChange"
  newString?: Maybe<Scalars["String"]>
  oldString?: Maybe<Scalars["String"]>
  path: Scalars["String"]
  valueType: GQLLogChangeValueType
}

export enum GQLLogTypes {
  All = "All",
  Auto = "Auto",
  Cancels = "Cancels",
  Manual = "Manual",
}

export type GQLMigrateOnlineCourseInput = {
  destinationStudentId: Scalars["ID"]
  onlineCourseId: Scalars["ID"]
  reason: Scalars["String"]
  sourceStudentId: Scalars["ID"]
}

export type GQLMigrateStudentAccountInput = {
  destinationStudentId: Scalars["ID"]
  reason: Scalars["String"]
  sourceStudentId: Scalars["ID"]
}

export type GQLMultipleAvailabilityCreateInput = {
  center: GQLCenterInput
  endDate: Scalars["String"]
  endTime: Scalars["String"]
  instructor: GQLAvailabilityInstructorInput
  minLeadHours: Scalars["Int"]
  startDate: Scalars["String"]
  startTime: Scalars["String"]
  vehicle: GQLAvailabilityVehicleInput
  weekDays?: InputMaybe<Array<GQLWeekDays>>
}

export type GQLMutation = {
  __typename?: "Mutation"
  addInstructor: GQLInstructor
  addMakeUpSessionInformation: Scalars["Boolean"]
  addStudent: GQLStudent
  addToCart: GQLCart
  addWaitlistComment: GQLWaitlistComment
  applyForScholarship: GQLScholarshipApplicationResponse
  batchDeleteAvails: GQLBatchDeleteAvailsResult
  batchEditAvails: GQLBatchEditAvailsResult
  cancelLesson: GQLLesson
  cancelSyncCheck?: Maybe<GQLSyncCheck>
  clearCoupon: GQLCart
  clearPackages: GQLCart
  completeOrder: GQLCart
  completeOrderWithSlots: GQLCart
  createApprovedInstructorSchedule: Scalars["ID"]
  createAvailability: GQLAvailability
  createCart: GQLCart
  createCoupon: GQLCoupon
  createInstructorAlert: GQLInstructorAlert
  createInstructorSchedule?: Maybe<Scalars["ID"]>
  createInstructorTimeLog: GQLInstructorTimeLog
  createLessonReview: GQLLessonReview
  createLessonType: GQLLessonType
  createLocationLandingPage: GQLLocationLandingPage
  createMultipleAvailabilities: GQLBatchAddAvailsResult
  createPriceScheme: GQLPriceScheme
  createPriceSchemeFragment: GQLPriceSchemeFragment
  createProduct: GQLProduct
  createPurchase: GQLPurchase
  createSessionCookie: Scalars["String"]
  createStateConfig: GQLStateConfig
  createStateMeetingPoint: GQLStateMeetingPoint
  createSuperRegion: GQLSuperRegion
  createTimeOff?: Maybe<Scalars["ID"]>
  createTransientSlot: GQLTransientSlot
  createVoucher: GQLVoucher
  deleteCoupon: Scalars["ID"]
  deleteInstructorAlert: Scalars["ID"]
  deleteInstructorSchedule?: Maybe<Scalars["ID"]>
  deleteInstructorTimeLog: Scalars["ID"]
  deleteLessonType: Scalars["Boolean"]
  deleteLocationLandingPage: Scalars["ID"]
  deleteNote: Scalars["ID"]
  deletePriceSchemeFragment: Scalars["ID"]
  deleteProduct: Scalars["ID"]
  deleteStateMeetingPoint: Scalars["ID"]
  deleteSuperRegion: Scalars["ID"]
  deleteTestFirebaseUser: Scalars["Boolean"]
  deleteTimeOff?: Maybe<Scalars["ID"]>
  deleteTransientSlot: Scalars["ID"]
  deleteVehicle: Scalars["ID"]
  deleteVoucher: Scalars["ID"]
  disableStudentAccount: Scalars["Boolean"]
  editPurchase: GQLPurchase
  emailStudentReport: Scalars["ID"]
  enrollStudentToCohort: Scalars["Boolean"]
  enrollStudentToOnlineCourse: GQLStudentOnlineCourse
  expireProducts: GQLExpiration
  impersonateUser: GQLImpersonateUserResponse
  instructorUpdateLesson: GQLLesson
  migrateStudentAccount: Scalars["Boolean"]
  migrateStudentOnlineCourse: GQLStudentCourseMigration
  putBookingAlert: GQLBookingAlert
  putCertificate: GQLCertificate
  putNote: GQLNote
  putReview: GQLFirebaseLessonReview
  putTestChargeLedgerLog: GQLLedgerLog
  putTestLesson: GQLLesson
  putTestProduct: GQLProduct
  putTestRegions: Scalars["Boolean"]
  putTestUser: GQLStudent
  putVehicle: GQLVehicle
  putWaitlistStudent: GQLWaitlistStudent
  redeemVoucher: GQLRedeemVoucherResult
  refreshTransientSlot: Scalars["ID"]
  refundPurchase: GQLRefund
  removeBookingAlert: Scalars["ID"]
  removeElevatedAccess: Scalars["Boolean"]
  removeFromCart: GQLCart
  removeStudentFromLesson: GQLLesson
  removeWaitlist: Scalars["Boolean"]
  resetTestDb: Scalars["Boolean"]
  resetTestLedgerLogs: Scalars["Boolean"]
  saveCohort: GQLCohort
  saveCohortSession: GQLCohortSession
  scheduleLesson: GQLLesson
  scheduleMultipleLessons: Array<GQLLesson>
  selfBookSharedLesson: GQLLesson
  setCoupon: GQLCart
  setPaymentStatus: GQLCart
  setStudentId: GQLCart
  signOut: Scalars["Boolean"]
  startSyncCheck?: Maybe<GQLSyncCheck>
  submitCoursePrepTestChapterScores: Scalars["Boolean"]
  transferAccount: Scalars["Boolean"]
  transferExpiration: GQLExpiration
  transferLesson: GQLLesson
  transferPurchase: GQLPurchase
  unenrollStudentFromCohort: Scalars["Boolean"]
  unenrollStudentFromOnlineCourse: Scalars["Boolean"]
  updateAvailability: GQLAvailability
  updateCoupon: GQLCoupon
  updateInstructor: GQLInstructor
  updateInstructorAlert: GQLInstructorAlert
  updateInstructorSchedule?: Maybe<Scalars["ID"]>
  updateInstructorTimeLog: GQLInstructorTimeLog
  updateLesson: GQLLesson
  updateLessonReportForStudent: Scalars["Boolean"]
  updateLessonType: GQLLessonType
  updateLocationLandingPage: GQLLocationLandingPage
  updateParkingHub: GQLParkingHub
  updatePriceScheme: GQLPriceScheme
  updatePriceSchemeFragment: GQLPriceSchemeFragment
  updateProduct: GQLProduct
  updateRegionScheme: GQLRegionScheme
  updateReview: GQLFirebaseLessonReview
  updateStateConfig: GQLStateConfig
  updateStateMeetingPoint: GQLStateMeetingPoint
  updateStudent: GQLStudent
  updateStudentCourseLectureSession: GQLFirebaseCourseSessionTime
  updateStudentCourseMeta: Scalars["Boolean"]
  updateStudentCourseSession: GQLFirebaseCourseSessionTime
  updateStudentSessionAttendance: Scalars["Boolean"]
  updateStudentsSessionAttendance: Scalars["Boolean"]
  updateSuperRegion: GQLSuperRegion
  updateTimeOff?: Maybe<Scalars["ID"]>
  updateUserRoles: Array<GQLUserRole>
  updateVoucher: GQLVoucher
  voidExpiration: GQLExpiration
  voidPurchase: GQLPurchase
  voidRefund: GQLRefund
}

export type GQLMutationAddInstructorArgs = {
  instructor: GQLNewInstructorInput
}

export type GQLMutationAddMakeUpSessionInformationArgs = {
  input: GQLAddMakeUpSessionInformationInput
}

export type GQLMutationAddStudentArgs = {
  student: GQLNewStudentInput
}

export type GQLMutationAddToCartArgs = {
  input: GQLIdLessonPackageInput
}

export type GQLMutationAddWaitlistCommentArgs = {
  comment: GQLWaitlistCommentInput
}

export type GQLMutationApplyForScholarshipArgs = {
  courseId: Scalars["String"]
  dob: Scalars["Float"]
  email: Scalars["String"]
  scholarshipAnswer: Scalars["String"]
  userName: Scalars["String"]
}

export type GQLMutationBatchDeleteAvailsArgs = {
  query: GQLBatchDeleteAvailsQuery
}

export type GQLMutationBatchEditAvailsArgs = {
  query: GQLBatchEditAvailsQuery
  update: GQLBatchEditAvailsUpdate
}

export type GQLMutationCancelLessonArgs = {
  input: GQLLessonCancellationInfo
  query: GQLLessonCancelQuery
}

export type GQLMutationCancelSyncCheckArgs = {
  id: Scalars["ID"]
}

export type GQLMutationClearCouponArgs = {
  input: GQLCartInput
}

export type GQLMutationClearPackagesArgs = {
  input: GQLCartInput
}

export type GQLMutationCompleteOrderArgs = {
  input: GQLCartInput
}

export type GQLMutationCompleteOrderWithSlotsArgs = {
  input: GQLCompleteOrderInput
}

export type GQLMutationCreateApprovedInstructorScheduleArgs = {
  input: GQLApprovedInstructorScheduleInput
}

export type GQLMutationCreateAvailabilityArgs = {
  input: GQLAvailabilityCreateInput
}

export type GQLMutationCreateCartArgs = {
  input: GQLCartCreateInput
}

export type GQLMutationCreateCouponArgs = {
  input: GQLUpsertCouponInput
}

export type GQLMutationCreateInstructorAlertArgs = {
  input: GQLInstructorAlertCreateInput
}

export type GQLMutationCreateInstructorScheduleArgs = {
  input: GQLInstructorScheduleInput
}

export type GQLMutationCreateInstructorTimeLogArgs = {
  input: GQLInstructorTimeLogCreateInput
}

export type GQLMutationCreateLessonReviewArgs = {
  input: GQLLessonReviewCreateInput
}

export type GQLMutationCreateLessonTypeArgs = {
  input: GQLLessonTypeCreateInput
}

export type GQLMutationCreateLocationLandingPageArgs = {
  input: GQLLocationLandingPageInput
}

export type GQLMutationCreateMultipleAvailabilitiesArgs = {
  input: GQLMultipleAvailabilityCreateInput
}

export type GQLMutationCreatePriceSchemeArgs = {
  input: GQLPriceSchemeCreateInput
}

export type GQLMutationCreatePriceSchemeFragmentArgs = {
  input: GQLPriceSchemeFragmentCreateInput
}

export type GQLMutationCreateProductArgs = {
  product: GQLCreateProductInput
}

export type GQLMutationCreatePurchaseArgs = {
  input: GQLCreatePurchaseInput
}

export type GQLMutationCreateSessionCookieArgs = {
  session: GQLCreateSessionCookieInput
}

export type GQLMutationCreateStateConfigArgs = {
  input: GQLStateConfigCreateInput
}

export type GQLMutationCreateStateMeetingPointArgs = {
  input: GQLStateMeetingPointCreateInput
}

export type GQLMutationCreateSuperRegionArgs = {
  input: GQLSuperRegionCreateInput
}

export type GQLMutationCreateTimeOffArgs = {
  input: GQLCreateInstructorTimeOffInput
}

export type GQLMutationCreateTransientSlotArgs = {
  input: GQLTransientSlotInput
}

export type GQLMutationCreateVoucherArgs = {
  voucher: GQLCreateVoucherInput
}

export type GQLMutationDeleteCouponArgs = {
  id: Scalars["ID"]
}

export type GQLMutationDeleteInstructorAlertArgs = {
  input: GQLInstructorAlertDeleteInput
}

export type GQLMutationDeleteInstructorScheduleArgs = {
  query: GQLInstructorScheduleMutateQuery
}

export type GQLMutationDeleteInstructorTimeLogArgs = {
  query: GQLInstructorTimeLogQuery
}

export type GQLMutationDeleteLessonTypeArgs = {
  id: Scalars["ID"]
}

export type GQLMutationDeleteLocationLandingPageArgs = {
  id: Scalars["ID"]
}

export type GQLMutationDeleteNoteArgs = {
  id: Scalars["ID"]
  userId: Scalars["ID"]
}

export type GQLMutationDeletePriceSchemeFragmentArgs = {
  id: Scalars["ID"]
}

export type GQLMutationDeleteProductArgs = {
  id: Scalars["ID"]
}

export type GQLMutationDeleteStateMeetingPointArgs = {
  id: Scalars["ID"]
  state: Scalars["ID"]
}

export type GQLMutationDeleteSuperRegionArgs = {
  id: Scalars["ID"]
}

export type GQLMutationDeleteTestFirebaseUserArgs = {
  id: Scalars["ID"]
}

export type GQLMutationDeleteTimeOffArgs = {
  query: GQLInstructorMutationTimeOffQuery
}

export type GQLMutationDeleteTransientSlotArgs = {
  transientSlotId: Scalars["ID"]
}

export type GQLMutationDeleteVehicleArgs = {
  id: Scalars["ID"]
}

export type GQLMutationDeleteVoucherArgs = {
  id: Scalars["ID"]
}

export type GQLMutationDisableStudentAccountArgs = {
  studentId: Scalars["ID"]
}

export type GQLMutationEditPurchaseArgs = {
  input: GQLEditPurchaseInput
}

export type GQLMutationEmailStudentReportArgs = {
  input: GQLEmailStudentReportInput
}

export type GQLMutationEnrollStudentToCohortArgs = {
  input: GQLEnrollStudentToCohortInput
}

export type GQLMutationEnrollStudentToOnlineCourseArgs = {
  input: GQLStudentOnlineCourseInput
}

export type GQLMutationExpireProductsArgs = {
  input: GQLExpireProductsInput
}

export type GQLMutationImpersonateUserArgs = {
  userId: Scalars["ID"]
}

export type GQLMutationInstructorUpdateLessonArgs = {
  query: GQLLessonUpdateQuery
  update: GQLInstructorLessonUpdateInput
}

export type GQLMutationMigrateStudentAccountArgs = {
  input: GQLMigrateStudentAccountInput
}

export type GQLMutationMigrateStudentOnlineCourseArgs = {
  input: GQLMigrateOnlineCourseInput
}

export type GQLMutationPutBookingAlertArgs = {
  bookingAlert: GQLBookingAlertInput
}

export type GQLMutationPutCertificateArgs = {
  certificate: GQLCertificateInput
}

export type GQLMutationPutNoteArgs = {
  note: GQLNoteInput
}

export type GQLMutationPutReviewArgs = {
  query: GQLPutLessonReviewInput
}

export type GQLMutationPutTestChargeLedgerLogArgs = {
  input: GQLTestChargeLedgerLogInput
}

export type GQLMutationPutTestLessonArgs = {
  input: GQLPutTestLessonInput
}

export type GQLMutationPutTestProductArgs = {
  product: GQLPutTestProductInput
}

export type GQLMutationPutTestRegionsArgs = {
  input: GQLPutTestRegionsInput
}

export type GQLMutationPutTestUserArgs = {
  input: GQLTestUserInput
}

export type GQLMutationPutVehicleArgs = {
  vehicle: GQLVehicleInput
}

export type GQLMutationPutWaitlistStudentArgs = {
  waitlistStudent: GQLWaitlistStudentInput
}

export type GQLMutationRedeemVoucherArgs = {
  voucher: GQLVoucherRedeemInput
}

export type GQLMutationRefreshTransientSlotArgs = {
  transientSlotId: Scalars["ID"]
}

export type GQLMutationRefundPurchaseArgs = {
  input: GQLRefundPurchaseInput
}

export type GQLMutationRemoveBookingAlertArgs = {
  bookingAlert: GQLDeleteBookingAlertInput
}

export type GQLMutationRemoveElevatedAccessArgs = {
  userId: Scalars["ID"]
}

export type GQLMutationRemoveFromCartArgs = {
  input: GQLIdLessonPackageInput
}

export type GQLMutationRemoveStudentFromLessonArgs = {
  input: GQLLessonCancellationInfo
  query: GQLLessonRemoveStudentQuery
}

export type GQLMutationRemoveWaitlistArgs = {
  id: Scalars["ID"]
}

export type GQLMutationSaveCohortArgs = {
  input: GQLCohortInput
}

export type GQLMutationSaveCohortSessionArgs = {
  input: GQLCohortSessionInput
}

export type GQLMutationScheduleLessonArgs = {
  input: GQLScheduleLessonInput
}

export type GQLMutationScheduleMultipleLessonsArgs = {
  input: Array<GQLScheduleLessonInput>
}

export type GQLMutationSelfBookSharedLessonArgs = {
  input: GQLSelfBookSharedLessonInput
}

export type GQLMutationSetCouponArgs = {
  input: GQLSetCouponInput
}

export type GQLMutationSetPaymentStatusArgs = {
  input: GQLPaymentStatusInput
}

export type GQLMutationSetStudentIdArgs = {
  input: GQLSetStudentInput
}

export type GQLMutationSubmitCoursePrepTestChapterScoresArgs = {
  input: GQLSubmitCoursePrepTestChapterScoresInput
}

export type GQLMutationTransferAccountArgs = {
  input: GQLTransferAccountInput
}

export type GQLMutationTransferExpirationArgs = {
  input: GQLTransferExpirationInput
}

export type GQLMutationTransferLessonArgs = {
  input: GQLTransferLessonInput
}

export type GQLMutationTransferPurchaseArgs = {
  input: GQLTransferPurchaseInput
}

export type GQLMutationUnenrollStudentFromCohortArgs = {
  input: GQLUnenrollStudentFromCohortInput
}

export type GQLMutationUnenrollStudentFromOnlineCourseArgs = {
  input: GQLStudentOnlineCourseInput
}

export type GQLMutationUpdateAvailabilityArgs = {
  input: GQLAvailabilityUpdateInput
  query: GQLAvailabilityQuery
}

export type GQLMutationUpdateCouponArgs = {
  id: Scalars["ID"]
  input: GQLUpsertCouponInput
}

export type GQLMutationUpdateInstructorArgs = {
  query: GQLUpdateInstructorQuery
  update: GQLUpdateInstructorInput
}

export type GQLMutationUpdateInstructorAlertArgs = {
  input: GQLInstructorAlertUpdateInput
}

export type GQLMutationUpdateInstructorScheduleArgs = {
  input: GQLInstructorScheduleUpdateInput
  query: GQLInstructorScheduleMutateQuery
}

export type GQLMutationUpdateInstructorTimeLogArgs = {
  input: GQLInstructorTimeLogUpdateInput
  query: GQLInstructorTimeLogQuery
}

export type GQLMutationUpdateLessonArgs = {
  query: GQLLessonUpdateQuery
  update: GQLLessonUpdateInput
}

export type GQLMutationUpdateLessonReportForStudentArgs = {
  input: GQLLessonDrivingSkillRatingInput
}

export type GQLMutationUpdateLessonTypeArgs = {
  input: GQLLessonTypeUpdateInput
}

export type GQLMutationUpdateLocationLandingPageArgs = {
  id: Scalars["ID"]
  input: GQLLocationLandingPageInput
}

export type GQLMutationUpdateParkingHubArgs = {
  id: Scalars["ID"]
  input: GQLUpdateParkingHubInput
}

export type GQLMutationUpdatePriceSchemeArgs = {
  input: GQLPriceSchemeUpdateInput
}

export type GQLMutationUpdatePriceSchemeFragmentArgs = {
  input: GQLPriceSchemeFragmentUpdateInput
}

export type GQLMutationUpdateProductArgs = {
  product: GQLUpdateProductInput
}

export type GQLMutationUpdateRegionSchemeArgs = {
  input: GQLRegionSchemeUpdateInput
}

export type GQLMutationUpdateReviewArgs = {
  query: GQLPutLessonReviewInput
}

export type GQLMutationUpdateStateConfigArgs = {
  input: GQLStateConfigUpdateInput
}

export type GQLMutationUpdateStateMeetingPointArgs = {
  input: GQLStateMeetingPointUpdateInput
}

export type GQLMutationUpdateStudentArgs = {
  student: GQLStudentInput
}

export type GQLMutationUpdateStudentCourseLectureSessionArgs = {
  input: GQLUpdateCourseSessionInput
}

export type GQLMutationUpdateStudentCourseMetaArgs = {
  input: GQLUpdateStudentCourseMetaInput
}

export type GQLMutationUpdateStudentCourseSessionArgs = {
  input: GQLUpdateCourseSessionInput
}

export type GQLMutationUpdateStudentSessionAttendanceArgs = {
  input: GQLUpdateStudentSessionAttendanceInput
}

export type GQLMutationUpdateStudentsSessionAttendanceArgs = {
  input: GQLUpdateStudentsSessionAttendanceInput
}

export type GQLMutationUpdateSuperRegionArgs = {
  input: GQLSuperRegionUpdateInput
}

export type GQLMutationUpdateTimeOffArgs = {
  input: GQLInstructorTimeOffInput
  query: GQLInstructorMutationTimeOffQuery
}

export type GQLMutationUpdateUserRolesArgs = {
  userId: Scalars["ID"]
  userRoles: Array<GQLUserRole>
}

export type GQLMutationUpdateVoucherArgs = {
  voucher: GQLUpdateVoucherInput
}

export type GQLMutationVoidExpirationArgs = {
  input: GQLVoidExpirationInput
}

export type GQLMutationVoidPurchaseArgs = {
  input: GQLVoidPurchaseInput
}

export type GQLMutationVoidRefundArgs = {
  input: GQLVoidRefundInput
}

export type GQLMyself = {
  __typename?: "Myself"
  email?: Maybe<Scalars["String"]>
  id: Scalars["ID"]
  roles: Array<Scalars["String"]>
  signInEmail?: Maybe<Scalars["String"]>
}

export type GQLNewInstructorInput = {
  about?: InputMaybe<Scalars["String"]>
  badges?: InputMaybe<Array<GQLInstructorBadgeInput>>
  baseAddress?: InputMaybe<Scalars["String"]>
  email: Scalars["String"]
  firstName: Scalars["String"]
  isInactive?: InputMaybe<Scalars["Boolean"]>
  languages?: InputMaybe<Scalars["String"]>
  lastName: Scalars["String"]
  licenseExpirationDate?: InputMaybe<Scalars["DateTime"]>
  licenseNumber?: InputMaybe<Scalars["String"]>
  longBreakMinutes?: InputMaybe<Scalars["Int"]>
  maxBaseTravelMinutes?: InputMaybe<Scalars["Int"]>
  maxBetweenTravelMinutes?: InputMaybe<Scalars["Int"]>
  payrollFirstName?: InputMaybe<Scalars["String"]>
  payrollLastName?: InputMaybe<Scalars["String"]>
  payrollTitle?: InputMaybe<Scalars["String"]>
  phoneNumber?: InputMaybe<Scalars["String"]>
  portraitImage?: InputMaybe<Scalars["String"]>
  region?: InputMaybe<Scalars["ID"]>
  regionalManagerId?: InputMaybe<Scalars["ID"]>
  shortBreakMinutes?: InputMaybe<Scalars["Int"]>
  slackUsername?: InputMaybe<Scalars["String"]>
  summary?: InputMaybe<Scalars["String"]>
  timeZone: Scalars["String"]
  title?: InputMaybe<Scalars["String"]>
  usStateId?: InputMaybe<Scalars["ID"]>
  userId: Scalars["ID"]
}

export type GQLNewStudentInput = {
  acceptTOS: Scalars["Boolean"]
  address?: InputMaybe<GQLAddressInput>
  dob?: InputMaybe<Scalars["DateTime"]>
  email: Scalars["String"]
  legalAndPermissions?: InputMaybe<GQLStudentLegalAndPermissionsInput>
  licenseExpirationDate?: InputMaybe<Scalars["DateTime"]>
  licenseIssueDate?: InputMaybe<Scalars["DateTime"]>
  licenseNumber?: InputMaybe<Scalars["String"]>
  name: Scalars["String"]
  parentEmail?: InputMaybe<Scalars["String"]>
  parentName?: InputMaybe<Scalars["String"]>
  preferredPronoun?: InputMaybe<Scalars["String"]>
  smsOptIn?: InputMaybe<Scalars["Boolean"]>
  tags?: InputMaybe<Array<Scalars["String"]>>
}

export type GQLNote = {
  __typename?: "Note"
  createdAt: Scalars["DateTime"]
  createdBy: GQLPerson
  createdById: Scalars["ID"]
  id: Scalars["ID"]
  isHiddenFromInstructorView: Scalars["Boolean"]
  isPinned: Scalars["Boolean"]
  text: Scalars["String"]
  userId: Scalars["ID"]
}

export type GQLNoteEdge = {
  __typename?: "NoteEdge"
  cursor: Scalars["ID"]
  node: GQLNote
}

export type GQLNoteInput = {
  id?: InputMaybe<Scalars["ID"]>
  isHiddenFromInstructorView?: InputMaybe<Scalars["Boolean"]>
  isPinned?: InputMaybe<Scalars["Boolean"]>
  text: Scalars["String"]
  userId: Scalars["ID"]
}

export type GQLNotesPaginatedList = {
  __typename?: "NotesPaginatedList"
  edges: Array<GQLNoteEdge>
  pageInfo: GQLPageInfo
  totalCount: Scalars["Int"]
}

export type GQLNotesQuery = {
  userId: Scalars["ID"]
}

export type GQLOnlineCourseContent = {
  __typename?: "OnlineCourseContent"
  anvilPdfIds: GQLAnvilPdfIds
  anvilPdfTemplateId?: Maybe<Scalars["String"]>
  branchLicenseNumber?: Maybe<Scalars["String"]>
  certificateCourseName?: Maybe<Scalars["String"]>
  certificateIncludeMeetsRequirements?: Maybe<Scalars["Boolean"]>
  certificateInstructor?: Maybe<Scalars["String"]>
  challengeRequired?: Maybe<Scalars["Boolean"]>
  completionFlow: Array<Scalars["String"]>
  courseAgeMinimum?: Maybe<Scalars["Float"]>
  courseTypeId?: Maybe<Scalars["ID"]>
  dailyTimeLimit?: Maybe<Scalars["Int"]>
  datoStateId: Scalars["ID"]
  dmvPreRegistrationLink?: Maybe<Scalars["String"]>
  externalCourseId?: Maybe<Scalars["String"]>
  failTwiceReview: Scalars["Boolean"]
  hasCertificate: Scalars["Boolean"]
  hasControlNumber: Scalars["Boolean"]
  hasDrivingLessons: Scalars["Boolean"]
  hasShipping: Scalars["Boolean"]
  hasTimer: Scalars["Boolean"]
  hasWeakSecurityMeasures: Scalars["Boolean"]
  id: Scalars["ID"]
  inactivityCheck: Scalars["Boolean"]
  instructionsText?: Maybe<Scalars["String"]>
  isClassroomCourse: Scalars["Boolean"]
  isEnrollmentOpen: Scalars["Boolean"]
  isExternalCourse: Scalars["Boolean"]
  isGuideRequired: Scalars["Boolean"]
  lectureIds: Array<Scalars["ID"]>
  licenseNumber?: Maybe<Scalars["String"]>
  link?: Maybe<Scalars["String"]>
  locationId?: Maybe<Scalars["String"]>
  maxEnrollees?: Maybe<Scalars["Int"]>
  minPercentCorrect: Scalars["Int"]
  notarizedFormLink?: Maybe<Scalars["String"]>
  numLectures: Scalars["Int"]
  numTests: Scalars["Int"]
  requireOrder?: Maybe<Scalars["Boolean"]>
  requiredHours: Scalars["Int"]
  requiresDmvPreRegistration?: Maybe<Scalars["Boolean"]>
  requiresNotarizedForm?: Maybe<Scalars["Boolean"]>
  requiresSampleCertificateCheck?: Maybe<Scalars["Boolean"]>
  slug: Scalars["String"]
  stateLongName: Scalars["String"]
  stateShortName: Scalars["String"]
  testLectures: Array<GQLCourseTestLecture>
  timerLockedLectureId?: Maybe<Scalars["String"]>
  title: Scalars["String"]
  topics: Array<GQLCourseTopic>
  verifyAddressText?: Maybe<Scalars["String"]>
}

export type GQLPackagePrice = {
  __typename?: "PackagePrice"
  banner?: Maybe<Scalars["String"]>
  couponDiscountAmount?: Maybe<Scalars["Float"]>
  courseId?: Maybe<Scalars["String"]>
  description: Scalars["String"]
  discountAmount?: Maybe<Scalars["Float"]>
  extras?: Maybe<Scalars["String"]>
  hasCourse: Scalars["Boolean"]
  hasLessons: Scalars["Boolean"]
  id: Scalars["String"]
  lessonPackage?: Maybe<GQLLessonPackage>
  msrp?: Maybe<Scalars["Float"]>
  price?: Maybe<Scalars["Float"]>
  state: Scalars["String"]
  title: Scalars["String"]
}

export type GQLPackagePriceInput = {
  banner?: InputMaybe<Scalars["String"]>
  couponDiscountAmount?: InputMaybe<Scalars["Float"]>
  courseId?: InputMaybe<Scalars["String"]>
  description: Scalars["String"]
  discountAmount?: InputMaybe<Scalars["Float"]>
  extras?: InputMaybe<Scalars["String"]>
  hasCourse: Scalars["Boolean"]
  hasLessons: Scalars["Boolean"]
  id: Scalars["String"]
  lessonPackage?: InputMaybe<GQLLessonPackageInput>
  msrp?: InputMaybe<Scalars["Float"]>
  price?: InputMaybe<Scalars["Float"]>
  title: Scalars["String"]
}

export type GQLPackagesQuery = {
  courseType?: InputMaybe<Scalars["String"]>
  state: Scalars["String"]
}

export type GQLPageInfo = {
  __typename?: "PageInfo"
  endCursor?: Maybe<Scalars["ID"]>
  hasNextPage: Scalars["Boolean"]
}

export type GQLPageQuery = {
  after?: InputMaybe<Scalars["ID"]>
  first: Scalars["Int"]
  skip?: InputMaybe<Scalars["Int"]>
  sortBy?: InputMaybe<Array<GQLSortKeyDirectionPair>>
}

export type GQLPaginatedCartsResponse = {
  __typename?: "PaginatedCartsResponse"
  carts: Array<GQLCart>
  hasMore: Scalars["Boolean"]
  totalCount: Scalars["Int"]
}

export type GQLParkingHub = {
  __typename?: "ParkingHub"
  center?: Maybe<GQLLocationCenter>
  id: Scalars["ID"]
  name: Scalars["String"]
  state?: Maybe<Scalars["String"]>
  timeZone?: Maybe<Scalars["String"]>
}

export enum GQLPaymentCategories {
  Acquisition = "acquisition",
  CashCheck = "cashCheck",
  Charter = "charter",
  Complimentary = "complimentary",
  Corporate = "corporate",
  NotApplicable = "notApplicable",
  Paypal = "paypal",
  Stripe = "stripe",
  Transfer = "transfer",
  Voucher = "voucher",
}

export type GQLPaymentStatusInput = {
  id: Scalars["ID"]
  paymentStatus: Scalars["String"]
}

export type GQLPerson = {
  __typename?: "Person"
  id: Scalars["ID"]
  name: Scalars["String"]
}

export type GQLPersonEdge = {
  __typename?: "PersonEdge"
  cursor: Scalars["ID"]
  node: GQLPerson
}

export type GQLPersonPaginatedList = {
  __typename?: "PersonPaginatedList"
  edges: Array<GQLPersonEdge>
  pageInfo: GQLPageInfo
  totalCount: Scalars["Int"]
}

export type GQLPriceScheme = {
  __typename?: "PriceScheme"
  id: Scalars["ID"]
  name: Scalars["String"]
  priceSchemeFragments: Array<GQLPriceSchemeFragment>
  products: Array<GQLPriceSchemeProductLink>
}

export type GQLPriceSchemeCreateInput = {
  name: Scalars["String"]
  priceSchemeFragmentIds: Array<Scalars["ID"]>
}

export type GQLPriceSchemeEdge = {
  __typename?: "PriceSchemeEdge"
  cursor: Scalars["ID"]
  node: GQLPriceScheme
}

export type GQLPriceSchemeFragment = {
  __typename?: "PriceSchemeFragment"
  id: Scalars["ID"]
  name: Scalars["String"]
  priceSchemeIds: Array<Scalars["ID"]>
  priceSchemes: Array<GQLPriceSchemeInfo>
  productPrices: Array<GQLProductPrice>
}

export type GQLPriceSchemeFragmentCreateInput = {
  name: Scalars["String"]
  productPrices: Array<GQLProductPriceInput>
}

export type GQLPriceSchemeFragmentEdge = {
  __typename?: "PriceSchemeFragmentEdge"
  cursor: Scalars["ID"]
  node: GQLPriceSchemeFragment
}

export type GQLPriceSchemeFragmentPaginatedList = {
  __typename?: "PriceSchemeFragmentPaginatedList"
  edges: Array<GQLPriceSchemeFragmentEdge>
  pageInfo: GQLPageInfo
  totalCount: Scalars["Int"]
}

export type GQLPriceSchemeFragmentUpdateInput = {
  id: Scalars["ID"]
  name: Scalars["String"]
  productPrices: Array<GQLProductPriceInput>
}

export type GQLPriceSchemeFragmentsQuery = {
  name?: InputMaybe<Scalars["String"]>
  priceSchemeId?: InputMaybe<Scalars["ID"]>
  productId?: InputMaybe<Scalars["ID"]>
}

export type GQLPriceSchemeInfo = {
  __typename?: "PriceSchemeInfo"
  id: Scalars["ID"]
  name: Scalars["String"]
}

export type GQLPriceSchemePaginatedList = {
  __typename?: "PriceSchemePaginatedList"
  edges: Array<GQLPriceSchemeEdge>
  pageInfo: GQLPageInfo
  totalCount: Scalars["Int"]
}

export type GQLPriceSchemeProductLink = {
  __typename?: "PriceSchemeProductLink"
  id: Scalars["ID"]
  name: Scalars["String"]
  price: Scalars["Float"]
  productLinks: Array<GQLProductLink>
  type: GQLProductType
}

export type GQLPriceSchemeUpdateInput = {
  id: Scalars["ID"]
  name?: InputMaybe<Scalars["String"]>
  priceSchemeFragmentIds: Array<Scalars["ID"]>
}

export type GQLPriceSchemesQuery = {
  term?: InputMaybe<Scalars["String"]>
}

export type GQLProduct = {
  __typename?: "Product"
  description?: Maybe<Scalars["String"]>
  id: Scalars["ID"]
  name: Scalars["String"]
  price?: Maybe<Scalars["Float"]>
  products?: Maybe<Array<GQLProductLink>>
  type: GQLProductType
}

export type GQLProductPriceArgs = {
  address: Scalars["String"]
}

export type GQLProductBalance = GQLBalancedQuantity & {
  __typename?: "ProductBalance"
  availableQuantity: Scalars["Int"]
  expiredQuantity: Scalars["Int"]
  productId: Scalars["ID"]
  purchasedQuantity: Scalars["Int"]
  receivedQuantity: Scalars["Int"]
  refundedQuantity: Scalars["Int"]
  sentQuantity: Scalars["Int"]
  usedQuantity: Scalars["Int"]
  voidedQuantity: Scalars["Int"]
}

export type GQLProductEdge = {
  __typename?: "ProductEdge"
  cursor: Scalars["ID"]
  node: GQLProduct
}

export type GQLProductLink = {
  __typename?: "ProductLink"
  product: GQLProduct
  productId: Scalars["ID"]
  productName: Scalars["String"]
  quantity: Scalars["Int"]
}

export type GQLProductLinkInput = {
  productId: Scalars["ID"]
  quantity: Scalars["Int"]
}

export type GQLProductPackage = {
  __typename?: "ProductPackage"
  banner?: Maybe<Scalars["String"]>
  courseAgeMinimum?: Maybe<Scalars["Int"]>
  courseId?: Maybe<Scalars["String"]>
  courseType?: Maybe<Scalars["String"]>
  description: Scalars["String"]
  doHighlight?: Maybe<Scalars["Boolean"]>
  hasCertificateShipping?: Maybe<Scalars["Boolean"]>
  hasCourse: Scalars["Boolean"]
  hasLessons: Scalars["Boolean"]
  id: Scalars["String"]
  isHomePickup?: Maybe<Scalars["Boolean"]>
  lessonPackage?: Maybe<GQLLessonPackage>
  state: Scalars["String"]
  tags?: Maybe<Array<Maybe<Scalars["String"]>>>
  title: Scalars["String"]
}

export type GQLProductPackageWithPrice = {
  __typename?: "ProductPackageWithPrice"
  banner?: Maybe<Scalars["String"]>
  bookUrl?: Maybe<Scalars["String"]>
  couponDiscountAmount?: Maybe<Scalars["Float"]>
  courseAgeMinimum?: Maybe<Scalars["Int"]>
  courseId?: Maybe<Scalars["String"]>
  courseType?: Maybe<Scalars["String"]>
  description: Scalars["String"]
  didApplyCoupon?: Maybe<Scalars["Boolean"]>
  discountAmount: Scalars["Float"]
  doHighlight?: Maybe<Scalars["Boolean"]>
  extras?: Maybe<Scalars["String"]>
  hasCertificateShipping?: Maybe<Scalars["Boolean"]>
  hasCourse: Scalars["Boolean"]
  hasLessons: Scalars["Boolean"]
  id: Scalars["String"]
  isCheckoutFlowPackage?: Maybe<Scalars["Boolean"]>
  isHomePickup?: Maybe<Scalars["Boolean"]>
  lessonPackage?: Maybe<GQLLessonPackage>
  msrp: Scalars["Float"]
  price: Scalars["Float"]
  state: Scalars["String"]
  tags?: Maybe<Array<Maybe<Scalars["String"]>>>
  title: Scalars["String"]
}

export type GQLProductPackageWithPriceInput = {
  banner?: InputMaybe<Scalars["String"]>
  bookUrl?: InputMaybe<Scalars["String"]>
  couponDiscountAmount?: InputMaybe<Scalars["Float"]>
  courseAgeMinimum?: InputMaybe<Scalars["Int"]>
  courseId?: InputMaybe<Scalars["String"]>
  courseType?: InputMaybe<Scalars["String"]>
  description: Scalars["String"]
  didApplyCoupon?: InputMaybe<Scalars["Boolean"]>
  discountAmount: Scalars["Float"]
  doHighlight?: InputMaybe<Scalars["Boolean"]>
  extras?: InputMaybe<Scalars["String"]>
  hasCertificateShipping?: InputMaybe<Scalars["Boolean"]>
  hasCourse: Scalars["Boolean"]
  hasLessons: Scalars["Boolean"]
  id: Scalars["String"]
  isCheckoutFlowPackage?: InputMaybe<Scalars["Boolean"]>
  isHomePickup?: InputMaybe<Scalars["Boolean"]>
  lessonPackage?: InputMaybe<GQLLessonPackageInput>
  msrp: Scalars["Float"]
  price: Scalars["Float"]
  state: Scalars["String"]
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>
  title: Scalars["String"]
}

export type GQLProductPrice = {
  __typename?: "ProductPrice"
  id: Scalars["ID"]
  priceAlgo: GQLProductPriceAlgo
  priceSchemeFragmentId: Scalars["ID"]
  product: GQLProduct
  productId: Scalars["ID"]
}

export type GQLProductPriceAlgo =
  | GQLProductPriceAlgoDollarDiscount
  | GQLProductPriceAlgoDollarThenPercentDiscount
  | GQLProductPriceAlgoFixed
  | GQLProductPriceAlgoPercentDiscount
  | GQLProductPriceAlgoPercentThenDollarDiscount

export type GQLProductPriceAlgoBase = {
  type: GQLProductPriceAlgoType
}

export type GQLProductPriceAlgoDollarDiscount = GQLProductPriceAlgoBase & {
  __typename?: "ProductPriceAlgoDollarDiscount"
  dollarDiscount: Scalars["Float"]
  rounding: GQLProductPriceRounding
  type: GQLProductPriceAlgoType
}

export type GQLProductPriceAlgoDollarDiscountInput = {
  dollarDiscount: Scalars["Float"]
  rounding: GQLProductPriceRoundingInput
  type: GQLProductPriceAlgoType
}

export type GQLProductPriceAlgoDollarThenPercentDiscount =
  GQLProductPriceAlgoBase & {
    __typename?: "ProductPriceAlgoDollarThenPercentDiscount"
    dollarDiscount: Scalars["Float"]
    percentDiscount: Scalars["Float"]
    rounding: GQLProductPriceRounding
    type: GQLProductPriceAlgoType
  }

export type GQLProductPriceAlgoDollarThenPercentDiscountInput = {
  dollarDiscount: Scalars["Float"]
  percentDiscount: Scalars["Float"]
  rounding: GQLProductPriceRoundingInput
  type: GQLProductPriceAlgoType
}

export type GQLProductPriceAlgoFixed = GQLProductPriceAlgoBase & {
  __typename?: "ProductPriceAlgoFixed"
  fixedPrice: Scalars["Float"]
  type: GQLProductPriceAlgoType
}

export type GQLProductPriceAlgoFixedPriceInput = {
  fixedPrice: Scalars["Float"]
  type: GQLProductPriceAlgoType
}

/** Only one of the fields should be passed */
export type GQLProductPriceAlgoInput = {
  dollarDiscount?: InputMaybe<GQLProductPriceAlgoDollarDiscountInput>
  dollarThenPercentDiscount?: InputMaybe<GQLProductPriceAlgoDollarThenPercentDiscountInput>
  fixed?: InputMaybe<GQLProductPriceAlgoFixedPriceInput>
  percentDiscount?: InputMaybe<GQLProductPriceAlgoPercentDiscountInput>
  percentThenDollarDiscount?: InputMaybe<GQLProductPriceAlgoPercentThenDollarDiscountInput>
}

export type GQLProductPriceAlgoPercentDiscount = GQLProductPriceAlgoBase & {
  __typename?: "ProductPriceAlgoPercentDiscount"
  percentDiscount: Scalars["Float"]
  rounding: GQLProductPriceRounding
  type: GQLProductPriceAlgoType
}

export type GQLProductPriceAlgoPercentDiscountInput = {
  percentDiscount: Scalars["Float"]
  rounding: GQLProductPriceRoundingInput
  type: GQLProductPriceAlgoType
}

export type GQLProductPriceAlgoPercentThenDollarDiscount =
  GQLProductPriceAlgoBase & {
    __typename?: "ProductPriceAlgoPercentThenDollarDiscount"
    dollarDiscount: Scalars["Float"]
    percentDiscount: Scalars["Float"]
    rounding: GQLProductPriceRounding
    type: GQLProductPriceAlgoType
  }

export type GQLProductPriceAlgoPercentThenDollarDiscountInput = {
  dollarDiscount: Scalars["Float"]
  percentDiscount: Scalars["Float"]
  rounding: GQLProductPriceRoundingInput
  type: GQLProductPriceAlgoType
}

export enum GQLProductPriceAlgoType {
  DollarDiscount = "dollarDiscount",
  DollarThenPercentDiscount = "dollarThenPercentDiscount",
  Fixed = "fixed",
  PercentDiscount = "percentDiscount",
  PercentThenDollarDiscount = "percentThenDollarDiscount",
}

export type GQLProductPriceInput = {
  priceAlgo: GQLProductPriceAlgoInput
  productId: Scalars["ID"]
}

export type GQLProductPriceRounding = {
  __typename?: "ProductPriceRounding"
  type: GQLProductPriceRoundingType
  value: Scalars["Float"]
}

export type GQLProductPriceRoundingInput = {
  type: GQLProductPriceRoundingType
  value: Scalars["Float"]
}

export enum GQLProductPriceRoundingType {
  Digit = "digit",
  Down = "down",
  Nearest = "nearest",
  Up = "up",
}

export type GQLProductQuantity = {
  __typename?: "ProductQuantity"
  productId: Scalars["ID"]
  quantity: Scalars["Int"]
}

export type GQLProductQuantityInput = {
  productId: Scalars["ID"]
  quantity: Scalars["Int"]
}

export enum GQLProductType {
  Course = "course",
  DrivingMinute = "drivingMinute",
  Fee = "fee",
  HomePickup = "homePickup",
  ObservationMinute = "observationMinute",
  Package = "package",
}

export type GQLProductsPaginatedList = {
  __typename?: "ProductsPaginatedList"
  edges: Array<GQLProductEdge>
  pageInfo: GQLPageInfo
  totalCount: Scalars["Int"]
}

export type GQLProductsQuery = {
  omitPackages?: InputMaybe<Scalars["Boolean"]>
  onlyPackages?: InputMaybe<Scalars["Boolean"]>
  term?: InputMaybe<Scalars["String"]>
}

export type GQLPurchase = {
  __typename?: "Purchase"
  categoryId: GQLPaymentCategories
  couponCode?: Maybe<Scalars["String"]>
  couponDiscount?: Maybe<Scalars["Float"]>
  createdById: Scalars["ID"]
  description: Scalars["String"]
  id: Scalars["ID"]
  items: Array<GQLAddedPurchaseItem>
  paymentCaptureDate?: Maybe<Scalars["DateTime"]>
  stripeChargeId?: Maybe<Scalars["String"]>
  stripeCustomerId?: Maybe<Scalars["String"]>
  stripePaymentIntentId?: Maybe<Scalars["String"]>
  usStateId: GQLUsStateId
  versionId: Scalars["ID"]
  voucherCode?: Maybe<Scalars["String"]>
}

export type GQLPurchaseItem = GQLPurchasePackageItem | GQLPurchaseProductItem

export type GQLPurchaseItemInput = {
  customPrice?: InputMaybe<Scalars["Float"]>
  itemId: Scalars["ID"]
  productId: Scalars["ID"]
  quantity: Scalars["Int"]
}

export type GQLPurchaseItemUpdate =
  | GQLAddedPurchasePackage
  | GQLAddedPurchaseProduct
  | GQLExistingPurchaseItemUpdate

export type GQLPurchasePackageItem = {
  __typename?: "PurchasePackageItem"
  itemId: Scalars["ID"]
  items?: Maybe<Array<GQLPurchaseProductItem>>
  name: Scalars["String"]
  priceDollars: Scalars["Float"]
  productId: Scalars["ID"]
  quantity: Scalars["Int"]
  type: GQLProductType
}

export type GQLPurchaseProductItem = {
  __typename?: "PurchaseProductItem"
  basePriceDollars: Scalars["Float"]
  itemId: Scalars["ID"]
  name: Scalars["String"]
  productId: Scalars["ID"]
  quantity: Scalars["Int"]
  type: GQLProductType
}

export type GQLPurchaseQuery = {
  purchaseId: Scalars["ID"]
  studentId: Scalars["ID"]
}

export type GQLPutLessonReviewInput = {
  date: Scalars["DateTime"]
  instructorId: Scalars["ID"]
  lessonId: Scalars["ID"]
  rating: Scalars["Int"]
  source: Scalars["String"]
  studentId: Scalars["ID"]
  studentName: Scalars["String"]
  text: Scalars["String"]
}

export type GQLPutTestLessonInput = {
  bookedAt: Scalars["DateTime"]
  endTime: Scalars["DateTime"]
  instructorId: Scalars["ID"]
  isObserverAllowed: Scalars["Boolean"]
  isShareable: Scalars["Boolean"]
  lessonId?: InputMaybe<Scalars["ID"]>
  pickupLocation: Scalars["String"]
  price: Scalars["Float"]
  startTime: Scalars["DateTime"]
  students: Array<GQLLessonStudentDetailsUpdateInput>
}

export type GQLPutTestProductInput = {
  description?: InputMaybe<Scalars["String"]>
  id?: InputMaybe<Scalars["ID"]>
  name: Scalars["String"]
  products?: InputMaybe<Array<GQLProductLinkInput>>
  type: GQLProductType
}

export type GQLPutTestRegionsInput = {
  features: Array<GQLGeoJsonFeatureInput>
  type: Scalars["String"]
}

export type GQLQuantityByIndicator = {
  __typename?: "QuantityByIndicator"
  quantity: Scalars["Int"]
  syncIndicator: GQLSyncIndicator
}

export type GQLQuery = {
  __typename?: "Query"
  activeInstructors: Array<GQLInstructor>
  approvedInstructorSchedule: GQLApprovedInstructorSchedule
  approvedInstructorSchedules?: Maybe<Array<GQLApprovedInstructorSchedule>>
  approvedInstructorsSchedules?: Maybe<Array<GQLApprovedInstructorSchedule>>
  auditLogs?: Maybe<GQLAuditLogsPaginatedList>
  availabilities: Array<GQLAvailability>
  availableLessonSlots: Array<GQLAvailabilitySlotPerDay>
  bookingAlerts: GQLBookingAlertsPaginatedList
  cart: GQLCart
  certificateTypes: Array<GQLCertificateType>
  certificates: GQLCertificatesPaginatedList
  cohort: GQLCohort
  cohorts: Array<GQLCohort>
  cohortsForStudent: Array<GQLCohort>
  completedCarts: GQLCartsPaginatedList
  coupons: GQLCouponsPaginatedList
  courseById: GQLOnlineCourseContent
  courseBySlug: GQLOnlineCourseContent
  courseCoupons: Array<Maybe<GQLCourseCoupon>>
  coursePrepTest: GQLCoursePrepTest
  coursePrepTestScores: Array<GQLCoursePrepTestChapterScores>
  courses: Array<GQLOnlineCourseContent>
  coursesWithIds: Array<GQLOnlineCourseContent>
  drivingSkills: Array<GQLDrivingSkill>
  expiration: GQLExpiration
  getReview: GQLFirebaseLessonReview
  getStudentBalance: GQLStudentBalance
  getTestStudent?: Maybe<GQLStudent>
  instructor: GQLInstructor
  instructorAlerts: GQLInstructorAlertPaginatedList
  instructorBadges: Array<GQLInstructorBadge>
  instructorSchedule: GQLInstructorSchedule
  instructorSchedules?: Maybe<Array<GQLInstructorSchedule>>
  instructorTimeLogs: GQLInstructorTimeLogPaginatedList
  instructorTimeOff?: Maybe<Array<GQLInstructorTimeOff>>
  instructorZoneGeoJson: GQLInstructorZone
  instructors: GQLInstructorsPaginatedList
  instructorsCoverageGeoJson: GQLInstructorsCoverage
  instructorsInSuperRegionForUser: Array<GQLInstructorWithSuperRegion>
  instructorsSchedules?: Maybe<Array<GQLInstructorSchedule>>
  instructorsTimeOff?: Maybe<Array<GQLInstructorTimeOff>>
  lastBookedInstructor?: Maybe<GQLLastBookedInstructor>
  lastCompletedSyncCheck?: Maybe<GQLSyncCheck>
  lectureById: GQLCourseLecture
  ledgerLog: GQLLedgerLog
  ledgerLogs: GQLLedgerLogsPaginatedList
  lesson: GQLLesson
  lessonDrivingSkillsReportByStudentAndLesson: GQLLessonDrivingSkillsReport
  lessonTypeById: GQLLessonType
  lessonTypes: GQLLessonTypesPaginatedList
  lessonTypesForAddress: Array<GQLLessonType>
  lessons: GQLLessonsPaginatedList
  lessonsByInstructorIds: Array<GQLLesson>
  lessonsDrivingSkillsReportsByStudent: GQLLessonDrivingSkillsReportPaginatedList
  lessonsReviewsByInstructor: GQLLessonsReviewsPaginatedList
  locationLandingPage?: Maybe<GQLLocationLandingPage>
  locationLandingPages: GQLLocationLandingPagePaginatedList
  me: GQLMyself
  notes: GQLNotesPaginatedList
  packages: Array<Maybe<GQLProductPackage>>
  parkingHub?: Maybe<GQLParkingHub>
  parkingHubs: Array<GQLParkingHub>
  priceScheme: GQLPriceScheme
  priceSchemeForAddress?: Maybe<GQLPriceScheme>
  priceSchemeFragment: GQLPriceSchemeFragment
  priceSchemeFragments: GQLPriceSchemeFragmentPaginatedList
  priceSchemes: GQLPriceSchemePaginatedList
  product: GQLProduct
  products: GQLProductsPaginatedList
  purchase: GQLPurchase
  reference: Array<GQLReferenceItem>
  refund: GQLRefund
  regionSchemes: GQLRegionSchemePaginatedList
  regionalManagers: GQLPersonPaginatedList
  regions: GQLRegionPaginatedList
  regionsGeoJson: GQLRegionZones
  searchAccountTags: Array<GQLAccountTag>
  sharedLessons: Array<GQLSharedLesson>
  stateConfigs: GQLStateConfigPaginatedList
  stateMeetingPoints: GQLStateMeetingPointPaginatedList
  student?: Maybe<GQLStudent>
  studentOnlineCourse: GQLStudentOnlineCoursesPaginatedList
  studentOnlineCourseReport: GQLStudentOnlineCourseReport
  studentOnlineCourseTimeProgress: GQLStudentOnlineCourseTimeProgress
  students: GQLStudentsPaginatedList
  superRegions: GQLSuperRegionPaginatedList
  syncCheck: GQLSyncCheck
  syncChecks: GQLSyncChecksPaginatedList
  syncConflict: GQLSyncConflict
  syncConflicts: GQLSyncConflictsPaginatedList
  transferAccountCheck: GQLTransferAccountCheckResult
  transientSlots: Array<GQLTransientSlot>
  usStates: Array<GQLUsState>
  userLogs?: Maybe<GQLUserLogsPaginatedList>
  userRoles: Array<GQLUserRole>
  vehicleAvailabilities: Array<GQLVehicleAvailability>
  vehicleAvails: Array<GQLVehicleAvail>
  vehicleMapAvailabilities: Array<GQLVehicleMapAvailability>
  vehicles?: Maybe<GQLVehiclesPaginatedList>
  voucherSources: Array<GQLVoucherSource>
  vouchers?: Maybe<GQLVouchersPaginatedList>
  vouchersRedeemedByStudent?: Maybe<GQLVouchersPaginatedList>
  waitlist: GQLWaitlistPaginatedList
  waitlistComments: Array<GQLWaitlistComment>
}

export type GQLQueryApprovedInstructorScheduleArgs = {
  query: GQLApprovedInstructorScheduleQuery
}

export type GQLQueryApprovedInstructorSchedulesArgs = {
  query: GQLApprovedInstructorScheduleQuery
}

export type GQLQueryApprovedInstructorsSchedulesArgs = {
  query: GQLApprovedInstructorsSchedulesQuery
}

export type GQLQueryAuditLogsArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>
  query: GQLAuditLogsQuery
}

export type GQLQueryAvailabilitiesArgs = {
  query: GQLAvailabilitiesQuery
}

export type GQLQueryAvailableLessonSlotsArgs = {
  query: GQLSchedulerQueryInput
}

export type GQLQueryBookingAlertsArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>
  query: GQLBookingAlertsQuery
}

export type GQLQueryCartArgs = {
  id: Scalars["ID"]
}

export type GQLQueryCertificatesArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>
  query?: InputMaybe<GQLCertificatesQuery>
}

export type GQLQueryCohortArgs = {
  id: Scalars["ID"]
}

export type GQLQueryCohortsArgs = {
  courseId: Scalars["ID"]
  doShowClosed?: InputMaybe<Scalars["Boolean"]>
}

export type GQLQueryCohortsForStudentArgs = {
  courseId: Scalars["ID"]
  studentId: Scalars["ID"]
}

export type GQLQueryCompletedCartsArgs = {
  filter: GQLCartFilterInput
  pageQuery?: InputMaybe<GQLPageQuery>
}

export type GQLQueryCouponsArgs = {
  filterQuery?: InputMaybe<GQLCouponsFilterQuery>
  pageQuery?: InputMaybe<GQLPageQuery>
}

export type GQLQueryCourseByIdArgs = {
  id: Scalars["ID"]
}

export type GQLQueryCourseBySlugArgs = {
  slug: Scalars["String"]
}

export type GQLQueryCourseCouponsArgs = {
  courseId: Scalars["String"]
}

export type GQLQueryCoursePrepTestArgs = {
  courseId: Scalars["ID"]
}

export type GQLQueryCoursePrepTestScoresArgs = {
  courseId: Scalars["ID"]
}

export type GQLQueryCoursesArgs = {
  query?: InputMaybe<GQLCoursesQuery>
}

export type GQLQueryCoursesWithIdsArgs = {
  ids: Array<Scalars["ID"]>
}

export type GQLQueryExpirationArgs = {
  query: GQLExpirationQuery
}

export type GQLQueryGetReviewArgs = {
  input: GQLGetLessonReviewInput
}

export type GQLQueryGetStudentBalanceArgs = {
  studentId: Scalars["ID"]
}

export type GQLQueryGetTestStudentArgs = {
  studentId: Scalars["ID"]
}

export type GQLQueryInstructorArgs = {
  instructorId: Scalars["ID"]
}

export type GQLQueryInstructorAlertsArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>
}

export type GQLQueryInstructorBadgesArgs = {
  query: GQLInstructorBadgeQuery
}

export type GQLQueryInstructorScheduleArgs = {
  query: GQLInstructorScheduleQuery
}

export type GQLQueryInstructorSchedulesArgs = {
  query: GQLInstructorScheduleQuery
}

export type GQLQueryInstructorTimeLogsArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>
  query: GQLInstructorTimeLogListQuery
}

export type GQLQueryInstructorTimeOffArgs = {
  query: GQLInstructorTimeOffQuery
}

export type GQLQueryInstructorZoneGeoJsonArgs = {
  query: GQLInstructorZoneQueryInput
}

export type GQLQueryInstructorsArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>
  query?: InputMaybe<GQLInstructorsQuery>
}

export type GQLQueryInstructorsSchedulesArgs = {
  query: GQLInstructorSchedulesQuery
}

export type GQLQueryInstructorsTimeOffArgs = {
  query: GQLInstructorsTimeOffQuery
}

export type GQLQueryLastBookedInstructorArgs = {
  studentId: Scalars["ID"]
}

export type GQLQueryLectureByIdArgs = {
  courseId: Scalars["ID"]
  lectureId: Scalars["ID"]
}

export type GQLQueryLedgerLogArgs = {
  id: Scalars["ID"]
}

export type GQLQueryLedgerLogsArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>
  query: GQLLedgerLogsQuery
}

export type GQLQueryLessonArgs = {
  query: GQLLessonQuery
}

export type GQLQueryLessonDrivingSkillsReportByStudentAndLessonArgs = {
  query: GQLLessonQuery
}

export type GQLQueryLessonTypeByIdArgs = {
  lessonTypeId: Scalars["ID"]
}

export type GQLQueryLessonTypesArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>
}

export type GQLQueryLessonTypesForAddressArgs = {
  address: Scalars["String"]
}

export type GQLQueryLessonsArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>
  query: GQLLessonsQuery
}

export type GQLQueryLessonsByInstructorIdsArgs = {
  query: GQLLessonsQueryByInstructorIds
}

export type GQLQueryLessonsDrivingSkillsReportsByStudentArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>
  query: GQLLessonsDrivingSkillsReportsQuery
}

export type GQLQueryLessonsReviewsByInstructorArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>
  query: GQLLessonsReviewsByInstructorQuery
}

export type GQLQueryLocationLandingPageArgs = {
  id: Scalars["ID"]
}

export type GQLQueryLocationLandingPagesArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>
}

export type GQLQueryNotesArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>
  query: GQLNotesQuery
}

export type GQLQueryPackagesArgs = {
  query: GQLPackagesQuery
}

export type GQLQueryParkingHubArgs = {
  id: Scalars["ID"]
}

export type GQLQueryPriceSchemeArgs = {
  id: Scalars["ID"]
}

export type GQLQueryPriceSchemeForAddressArgs = {
  address: Scalars["String"]
}

export type GQLQueryPriceSchemeFragmentArgs = {
  id: Scalars["ID"]
}

export type GQLQueryPriceSchemeFragmentsArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>
  query?: InputMaybe<GQLPriceSchemeFragmentsQuery>
}

export type GQLQueryPriceSchemesArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>
  query?: InputMaybe<GQLPriceSchemesQuery>
}

export type GQLQueryProductArgs = {
  productId: Scalars["ID"]
}

export type GQLQueryProductsArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>
  query?: InputMaybe<GQLProductsQuery>
}

export type GQLQueryPurchaseArgs = {
  query: GQLPurchaseQuery
}

export type GQLQueryRefundArgs = {
  query: GQLRefundQuery
}

export type GQLQueryRegionSchemesArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>
  query?: InputMaybe<GQLRegionSchemeQuery>
}

export type GQLQueryRegionalManagersArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>
  query?: InputMaybe<GQLRegionalManagersQuery>
}

export type GQLQueryRegionsArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>
}

export type GQLQuerySearchAccountTagsArgs = {
  query: Scalars["String"]
}

export type GQLQuerySharedLessonsArgs = {
  query: GQLSharedLessonsQuery
}

export type GQLQueryStateConfigsArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>
}

export type GQLQueryStateMeetingPointsArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>
  query: GQLStateMeetingPointsQuery
}

export type GQLQueryStudentArgs = {
  studentId: Scalars["ID"]
}

export type GQLQueryStudentOnlineCourseArgs = {
  pageQuery: GQLPageQuery
  studentId: Scalars["ID"]
}

export type GQLQueryStudentOnlineCourseReportArgs = {
  courseId: Scalars["ID"]
  studentId: Scalars["ID"]
}

export type GQLQueryStudentOnlineCourseTimeProgressArgs = {
  onlineCourseId: Scalars["ID"]
  studentId: Scalars["ID"]
}

export type GQLQueryStudentsArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>
  query?: InputMaybe<GQLStudentsQuery>
}

export type GQLQuerySuperRegionsArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>
  query?: InputMaybe<GQLSuperRegionQuery>
}

export type GQLQuerySyncCheckArgs = {
  id: Scalars["ID"]
}

export type GQLQuerySyncChecksArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>
}

export type GQLQuerySyncConflictArgs = {
  id: Scalars["ID"]
}

export type GQLQuerySyncConflictsArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>
  query?: InputMaybe<GQLSyncConflictsQuery>
}

export type GQLQueryTransferAccountCheckArgs = {
  query: GQLTransferAccountInput
}

export type GQLQueryTransientSlotsArgs = {
  query: GQLTransientSlotsQuery
}

export type GQLQueryUserLogsArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>
  query: GQLUserLogsQuery
}

export type GQLQueryUserRolesArgs = {
  userId: Scalars["ID"]
}

export type GQLQueryVehicleAvailabilitiesArgs = {
  query: GQLVehicleAvailabilityQuery
}

export type GQLQueryVehicleAvailsArgs = {
  startTime: Scalars["DateTime"]
  vehicleId: Scalars["ID"]
}

export type GQLQueryVehicleMapAvailabilitiesArgs = {
  query: GQLVehicleMapAvailabilityQuery
}

export type GQLQueryVehiclesArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>
}

export type GQLQueryVouchersArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>
}

export type GQLQueryVouchersRedeemedByStudentArgs = {
  query: GQLVouchersRedeemedByStudentQuery
}

export type GQLQueryWaitlistArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>
}

export type GQLQueryWaitlistCommentsArgs = {
  query: GQLWaitlistCommentsQuery
}

export type GQLRedeemVoucherResult = {
  __typename?: "RedeemVoucherResult"
  purchase: GQLPurchase
  voucher: GQLVoucher
}

export type GQLReferenceItem = {
  __typename?: "ReferenceItem"
  category: Scalars["String"]
  label: Scalars["String"]
  subLabel?: Maybe<Scalars["String"]>
  url?: Maybe<Scalars["String"]>
}

export type GQLRefund = {
  __typename?: "Refund"
  amountToRefund: Scalars["Float"]
  categoryId: GQLPaymentCategories
  createdById: Scalars["ID"]
  description: Scalars["String"]
  id: Scalars["ID"]
  isVoid: Scalars["Boolean"]
  itemsToRefund?: Maybe<Array<GQLItemToRefund>>
  purchaseId: Scalars["ID"]
  stripeChargeId?: Maybe<Scalars["String"]>
  stripeCustomerId?: Maybe<Scalars["String"]>
  versionId: Scalars["ID"]
}

/**
 * Refund requests have to have at least one of
 * (amountToRefund, itemsToRefund) fields set.
 *
 * Here are all the valid options:
 *
 *   1. only amountToRefund field is set
 *   2. both amountToRefund & itemsToRefund are set
 *   3. only itemsToRefund field is set
 *
 * Having both amountToRefund & itemsToRefund not set is invalid.
 *
 * Case #1: amountToRefund was refunded, no items invalidated
 * Case #2: amountToRefund was refunded, itemsToRefund can't be used anymore
 * Case #3: amountToRefund is calculated from itemsToRefund,
 *         itemsToRefund can't be used anymore
 */
export type GQLRefundPurchaseInput = {
  amountToRefund?: InputMaybe<Scalars["Float"]>
  categoryId: GQLPaymentCategories
  description: Scalars["String"]
  itemsToRefund?: InputMaybe<Array<GQLItemToRefundInput>>
  purchaseId: Scalars["ID"]
  stripeChargeId?: InputMaybe<Scalars["String"]>
  stripeCustomerId?: InputMaybe<Scalars["String"]>
  studentId: Scalars["ID"]
  versionId: Scalars["ID"]
}

export type GQLRefundQuery = {
  refundId: Scalars["ID"]
  studentId: Scalars["ID"]
}

export type GQLRegion = {
  __typename?: "Region"
  id: Scalars["ID"]
  name: Scalars["String"]
  state?: Maybe<GQLUsStateId>
  superRegion?: Maybe<Scalars["String"]>
}

export type GQLRegionEdge = {
  __typename?: "RegionEdge"
  cursor: Scalars["ID"]
  node: GQLRegion
}

export type GQLRegionPaginatedList = {
  __typename?: "RegionPaginatedList"
  edges: Array<GQLRegionEdge>
  pageInfo: GQLPageInfo
  totalCount: Scalars["Int"]
}

export type GQLRegionScheme = {
  __typename?: "RegionScheme"
  id: Scalars["ID"]
  priceScheme?: Maybe<GQLPriceScheme>
  priceSchemeId?: Maybe<Scalars["ID"]>
  superRegion?: Maybe<GQLSuperRegion>
  superRegionId?: Maybe<Scalars["ID"]>
}

export type GQLRegionSchemeEdge = {
  __typename?: "RegionSchemeEdge"
  cursor: Scalars["ID"]
  node: GQLRegionScheme
}

export type GQLRegionSchemePaginatedList = {
  __typename?: "RegionSchemePaginatedList"
  edges: Array<GQLRegionSchemeEdge>
  pageInfo: GQLPageInfo
  totalCount: Scalars["Int"]
}

export type GQLRegionSchemeQuery = {
  /** The value 'unset' will target region schemes without a price scheme assigned */
  priceSchemeId?: InputMaybe<Scalars["ID"]>
  state?: InputMaybe<GQLUsStateId>
  /** The value 'unset' will target region schemes without a super region assigned */
  superRegionId?: InputMaybe<Scalars["ID"]>
}

export type GQLRegionSchemeUpdateInput = {
  id: Scalars["ID"]
  priceSchemeId?: InputMaybe<Scalars["ID"]>
  superRegionId?: InputMaybe<Scalars["ID"]>
}

export type GQLRegionZones = {
  __typename?: "RegionZones"
  center: GQLLatLng
  features: Array<GQLGeoJsonFeature>
}

export type GQLRegionalManagersQuery = {
  id?: InputMaybe<Scalars["ID"]>
  term?: InputMaybe<Scalars["String"]>
}

export type GQLRoadTestCoupon = {
  __typename?: "RoadTestCoupon"
  code: Scalars["String"]
  discount: Scalars["Float"]
  displayInLandingPages?: Maybe<Scalars["Boolean"]>
  expiration?: Maybe<Scalars["DateTime"]>
  id: Scalars["ID"]
  onlyFirstPurchase: Scalars["Boolean"]
  regionId: Scalars["ID"]
  regions: Array<GQLRegion>
  title: Scalars["String"]
  type: GQLCouponType
}

export type GQLRoadTestCouponInput = {
  code: Scalars["String"]
  discount: Scalars["Float"]
  displayInLandingPages?: InputMaybe<Scalars["Boolean"]>
  expiration?: InputMaybe<Scalars["DateTime"]>
  onlyFirstPurchase: Scalars["Boolean"]
  regionIds: Array<Scalars["ID"]>
  title: Scalars["String"]
}

export type GQLScheduleDayTimes = {
  __typename?: "ScheduleDayTimes"
  dayOfTheWeek: Scalars["String"]
  endTime: Scalars["String"]
  startTime: Scalars["String"]
}

export type GQLScheduleLessonInput = {
  studentDetails?: InputMaybe<GQLLessonStudentDetailsInput>
  studentId: Scalars["ID"]
  transientSlotId: Scalars["ID"]
}

export type GQLSchedulerQueryInput = {
  address: Scalars["String"]
  appointmentValue: Scalars["Int"]
  doPrioritization?: InputMaybe<Scalars["Boolean"]>
  durationMinutes: Scalars["Int"]
  endDate: Scalars["String"]
  forceFresh?: InputMaybe<Scalars["Boolean"]>
  includePrivate?: InputMaybe<Scalars["Boolean"]>
  instructorId?: InputMaybe<Scalars["ID"]>
  maximizeSlotsInAvail?: InputMaybe<Scalars["Boolean"]>
  preferAfternoons?: InputMaybe<Scalars["Boolean"]>
  startDate: Scalars["String"]
}

export type GQLScholarshipApplicationResponse = {
  __typename?: "ScholarshipApplicationResponse"
  error?: Maybe<Scalars["String"]>
  id?: Maybe<Scalars["String"]>
}

export type GQLSelfBookSharedLessonInput = {
  addStudent: GQLLessonUpdateAddStudent
  transientSlotId: Scalars["ID"]
}

export type GQLSetCouponInput = {
  cartId: Scalars["ID"]
  couponCode: Scalars["String"]
}

export type GQLSetStudentInput = {
  id: Scalars["ID"]
  studentId: Scalars["ID"]
}

export type GQLSharedLesson = {
  __typename?: "SharedLesson"
  eligibleStudents: Array<GQLEligibleStudent>
  lesson: GQLLesson
}

export type GQLSharedLessonsQuery = {
  excludeTags?: InputMaybe<Array<Scalars["String"]>>
  includeTags?: InputMaybe<Array<Scalars["String"]>>
  maxMilesFromLesson: Scalars["Int"]
  meetingPointId?: InputMaybe<Scalars["ID"]>
  minDrivingMinutes: Scalars["Int"]
  minObservationMinutes: Scalars["Int"]
  superRegionId?: InputMaybe<Scalars["ID"]>
  withLicense?: InputMaybe<Scalars["Boolean"]>
}

export type GQLSignInWithEmailInput = {
  email: Scalars["String"]
  password: Scalars["String"]
}

export enum GQLSortDirection {
  Asc = "asc",
  Desc = "desc",
}

export type GQLSortKeyDirectionPair = {
  key: Scalars["String"]
  sortDirection: GQLSortDirection
}

export type GQLStateConfig = {
  __typename?: "StateConfig"
  hasHomePickup: Scalars["Boolean"]
  hasMeetingPoints: Scalars["Boolean"]
  id: Scalars["ID"]
  isShareableByDefault: Scalars["Boolean"]
  lessonTypeIds?: Maybe<Array<Scalars["ID"]>>
  lessonTypes?: Maybe<Array<GQLLessonType>>
  longName: Scalars["String"]
  state: Scalars["ID"]
}

export type GQLStateConfigCreateInput = {
  hasHomePickup: Scalars["Boolean"]
  hasMeetingPoints: Scalars["Boolean"]
  isShareableByDefault: Scalars["Boolean"]
  lessonTypes?: InputMaybe<Array<GQLLessonTypeForStateConfigInput>>
  longName: Scalars["String"]
  state: Scalars["String"]
}

export type GQLStateConfigEdge = {
  __typename?: "StateConfigEdge"
  cursor: Scalars["ID"]
  node: GQLStateConfig
}

export type GQLStateConfigPaginatedList = {
  __typename?: "StateConfigPaginatedList"
  edges: Array<GQLStateConfigEdge>
  pageInfo: GQLPageInfo
  totalCount: Scalars["Int"]
}

export type GQLStateConfigUpdateInput = {
  hasHomePickup?: InputMaybe<Scalars["Boolean"]>
  hasMeetingPoints?: InputMaybe<Scalars["Boolean"]>
  id: Scalars["ID"]
  isShareableByDefault?: InputMaybe<Scalars["Boolean"]>
  lessonTypes?: InputMaybe<Array<GQLLessonTypeForStateConfigInput>>
  longName?: InputMaybe<Scalars["String"]>
  state?: InputMaybe<Scalars["String"]>
}

export type GQLStateMeetingPoint = {
  __typename?: "StateMeetingPoint"
  address: Scalars["String"]
  coords?: Maybe<GQLLatLng>
  description?: Maybe<Scalars["String"]>
  id: Scalars["ID"]
  label: Scalars["String"]
  state: Scalars["ID"]
}

export type GQLStateMeetingPointCreateInput = {
  address: Scalars["String"]
  description?: InputMaybe<Scalars["String"]>
  label: Scalars["String"]
  state: Scalars["ID"]
}

export type GQLStateMeetingPointEdge = {
  __typename?: "StateMeetingPointEdge"
  cursor: Scalars["ID"]
  node: GQLStateMeetingPoint
}

export type GQLStateMeetingPointPaginatedList = {
  __typename?: "StateMeetingPointPaginatedList"
  edges: Array<GQLStateMeetingPointEdge>
  pageInfo: GQLPageInfo
  totalCount: Scalars["Int"]
}

export type GQLStateMeetingPointUpdateInput = {
  address?: InputMaybe<Scalars["String"]>
  description?: InputMaybe<Scalars["String"]>
  id: Scalars["ID"]
  label?: InputMaybe<Scalars["String"]>
  state: Scalars["ID"]
}

export type GQLStateMeetingPointsQuery = {
  state: Scalars["ID"]
}

export type GQLStudent = {
  __typename?: "Student"
  acceptTOS?: Maybe<Scalars["Boolean"]>
  address: GQLAddress
  age?: Maybe<Scalars["Int"]>
  dmvPreRegNumber?: Maybe<Scalars["String"]>
  dob?: Maybe<Scalars["DateTime"]>
  drivingHistory?: Maybe<Scalars["String"]>
  email: Scalars["String"]
  id: Scalars["ID"]
  isDeleted?: Maybe<Scalars["Boolean"]>
  isFirstLessonPurchase?: Maybe<Scalars["Boolean"]>
  legalAndPermissions?: Maybe<GQLStudentLegalAndPermissions>
  licenseExpirationDate?: Maybe<Scalars["DateTime"]>
  licenseIssueDate?: Maybe<Scalars["DateTime"]>
  licenseNumber?: Maybe<Scalars["String"]>
  name: Scalars["String"]
  parentEmail?: Maybe<Scalars["String"]>
  parentName?: Maybe<Scalars["String"]>
  preferredPronoun?: Maybe<Scalars["String"]>
  previousHours?: Maybe<Scalars["Float"]>
  previousSchoolLessons?: Maybe<Scalars["String"]>
  productBalance: GQLProductBalance
  productBalanceList: Array<GQLProductBalance>
  referral?: Maybe<Scalars["String"]>
  signInEmail?: Maybe<Scalars["String"]>
  signupTimestamp?: Maybe<Scalars["DateTime"]>
  smsOptIn?: Maybe<Scalars["Boolean"]>
  stripeCustomerId?: Maybe<Scalars["ID"]>
  tags?: Maybe<Array<Scalars["String"]>>
  timeZone?: Maybe<Scalars["String"]>
  usStateId?: Maybe<Scalars["ID"]>
}

export type GQLStudentProductBalanceArgs = {
  productId: Scalars["ID"]
}

export type GQLStudentBalance = {
  __typename?: "StudentBalance"
  drivingMinutesPurchased: Scalars["Float"]
  drivingMinutesRemaining: Scalars["Float"]
  drivingMinutesUsed: Scalars["Float"]
  expirationDrivingMinutesUsed: Scalars["Float"]
  homePickupsExpired: Scalars["Float"]
  homePickupsPurchased: Scalars["Float"]
  homePickupsRemaining: Scalars["Float"]
  homePickupsUsed: Scalars["Float"]
  observationMinutesPurchased: Scalars["Float"]
  observationMinutesRemaining: Scalars["Float"]
  observationMinutesUsed: Scalars["Float"]
  userId: Scalars["ID"]
}

export enum GQLStudentContractStatus {
  Issue = "Issue",
  NotSent = "NotSent",
  Sent = "Sent",
  Signed = "Signed",
}

export type GQLStudentCourseMigration = {
  __typename?: "StudentCourseMigration"
  destinationStudentId: Scalars["ID"]
  onlineCourseId: Scalars["ID"]
  sourceStudentId: Scalars["ID"]
}

export type GQLStudentEdge = {
  __typename?: "StudentEdge"
  cursor: Scalars["ID"]
  node: GQLStudent
}

export type GQLStudentId = {
  __typename?: "StudentId"
  studentId: Scalars["ID"]
}

export type GQLStudentIdProductId = {
  __typename?: "StudentIdProductId"
  productId: Scalars["ID"]
  studentId: Scalars["ID"]
}

export type GQLStudentInput = {
  acceptTOS?: InputMaybe<Scalars["Boolean"]>
  address?: InputMaybe<GQLAddressInput>
  dob?: InputMaybe<Scalars["DateTime"]>
  email?: InputMaybe<Scalars["String"]>
  id: Scalars["ID"]
  legalAndPermissions?: InputMaybe<GQLStudentLegalAndPermissionsInput>
  licenseExpirationDate?: InputMaybe<Scalars["DateTime"]>
  licenseIssueDate?: InputMaybe<Scalars["DateTime"]>
  licenseNumber?: InputMaybe<Scalars["String"]>
  name?: InputMaybe<Scalars["String"]>
  parentEmail?: InputMaybe<Scalars["String"]>
  parentName?: InputMaybe<Scalars["String"]>
  preferredPronoun?: InputMaybe<Scalars["String"]>
  previousHours?: InputMaybe<Scalars["Float"]>
  signupTimestamp?: InputMaybe<Scalars["DateTime"]>
  smsOptIn?: InputMaybe<Scalars["Boolean"]>
  stripeCustomerId?: InputMaybe<Scalars["ID"]>
  tags?: InputMaybe<Array<Scalars["String"]>>
  usStateId?: InputMaybe<Scalars["ID"]>
}

export type GQLStudentLegalAndPermissions = {
  __typename?: "StudentLegalAndPermissions"
  contractStatus: GQLStudentContractStatus
  observerApproved: Scalars["Boolean"]
  privateApproved: Scalars["Boolean"]
  transferStatus: GQLStudentTransferStatus
  transferType?: Maybe<GQLStudentTransferType>
}

export type GQLStudentLegalAndPermissionsInput = {
  contractStatus: GQLStudentContractStatus
  observerApproved: Scalars["Boolean"]
  privateApproved: Scalars["Boolean"]
  transferStatus: GQLStudentTransferStatus
  transferType?: InputMaybe<GQLStudentTransferType>
}

export type GQLStudentObject = {
  __typename?: "StudentObject"
  id: Scalars["ID"]
  name: Scalars["String"]
}

export type GQLStudentOnlineCourse = {
  __typename?: "StudentOnlineCourse"
  courseId: Scalars["ID"]
  currentLecture?: Maybe<Scalars["String"]>
  enrolled: Scalars["Boolean"]
  id: Scalars["ID"]
  lectures?: Maybe<Array<GQLStudentOnlineCourseLecture>>
  name: Scalars["String"]
  percentComplete?: Maybe<Scalars["Float"]>
  percentTestsPassed?: Maybe<Scalars["Float"]>
  studentId: Scalars["ID"]
  tests?: Maybe<Array<GQLStudentOnlineCourseTest>>
}

export type GQLStudentOnlineCourseEdge = {
  __typename?: "StudentOnlineCourseEdge"
  cursor: Scalars["ID"]
  node: GQLStudentOnlineCourse
}

export type GQLStudentOnlineCourseInput = {
  onlineCourseId: Scalars["ID"]
  studentId: Scalars["ID"]
}

export type GQLStudentOnlineCourseLecture = {
  __typename?: "StudentOnlineCourseLecture"
  id: Scalars["ID"]
}

export type GQLStudentOnlineCourseReport = {
  __typename?: "StudentOnlineCourseReport"
  address: Scalars["String"]
  completionDate?: Maybe<Scalars["DateTime"]>
  courseTotalTimeSeconds?: Maybe<Scalars["Int"]>
  dmvPreRegNumber?: Maybe<Scalars["String"]>
  dob: Scalars["String"]
  lectureReports?: Maybe<Array<GQLLectureReport>>
  name: Scalars["String"]
  startDate?: Maybe<Scalars["DateTime"]>
}

export type GQLStudentOnlineCourseTest = {
  __typename?: "StudentOnlineCourseTest"
  id: Scalars["ID"]
  numCorrect: Scalars["Int"]
  totalQuestions: Scalars["Int"]
}

export type GQLStudentOnlineCourseTimeProgress = {
  __typename?: "StudentOnlineCourseTimeProgress"
  courseTotalTime: Scalars["Int"]
  totalTimePerDay?: Maybe<Array<GQLLectureTimeDaily>>
}

export type GQLStudentOnlineCoursesPaginatedList = {
  __typename?: "StudentOnlineCoursesPaginatedList"
  edges: Array<GQLStudentOnlineCourseEdge>
  pageInfo: GQLPageInfo
  totalCount: Scalars["Int"]
}

export type GQLStudentSessionAttendance = {
  __typename?: "StudentSessionAttendance"
  attendance: GQLAttendance
  cohortSessionId: Scalars["ID"]
  id: Scalars["ID"]
  makeUpCohortId?: Maybe<Scalars["ID"]>
  makeUpSessionId?: Maybe<Scalars["ID"]>
  studentId: Scalars["ID"]
}

export enum GQLStudentTransferStatus {
  No = "No",
  YesMissingPaperwork = "YesMissingPaperwork",
  YesPaperWorkComplete = "YesPaperWorkComplete",
}

export enum GQLStudentTransferType {
  Both = "Both",
  CarOnly = "CarOnly",
  CourseOnly = "CourseOnly",
}

export type GQLStudentsPaginatedList = {
  __typename?: "StudentsPaginatedList"
  edges: Array<GQLStudentEdge>
  pageInfo: GQLPageInfo
  totalCount: Scalars["Int"]
}

export type GQLStudentsQuery = {
  id?: InputMaybe<Scalars["ID"]>
  term?: InputMaybe<Scalars["String"]>
}

export type GQLSubItemInput = {
  itemId: Scalars["ID"]
  quantity: Scalars["Int"]
}

export type GQLSubmitCoursePrepTestChapterScoresInput = {
  chapterId: Scalars["ID"]
  courseId: Scalars["ID"]
  questionScores: Array<GQLLectureQuizQuestionPrepTestScoreInput>
}

export type GQLSuperRegion = {
  __typename?: "SuperRegion"
  googleBusinessPageUrl?: Maybe<Scalars["String"]>
  id: Scalars["ID"]
  name: Scalars["String"]
  state?: Maybe<GQLUsStateId>
}

export type GQLSuperRegionCreateInput = {
  googleBusinessPageUrl?: InputMaybe<Scalars["String"]>
  name: Scalars["String"]
  state: GQLUsStateId
}

export type GQLSuperRegionEdge = {
  __typename?: "SuperRegionEdge"
  cursor: Scalars["ID"]
  node: GQLSuperRegion
}

export type GQLSuperRegionPaginatedList = {
  __typename?: "SuperRegionPaginatedList"
  edges: Array<GQLSuperRegionEdge>
  pageInfo: GQLPageInfo
  totalCount: Scalars["Int"]
}

export type GQLSuperRegionQuery = {
  term?: InputMaybe<Scalars["String"]>
}

export type GQLSuperRegionUpdateInput = {
  googleBusinessPageUrl?: InputMaybe<Scalars["String"]>
  id: Scalars["ID"]
  name?: InputMaybe<Scalars["String"]>
  state?: InputMaybe<GQLUsStateId>
}

export type GQLSyncCheck = {
  __typename?: "SyncCheck"
  checkedStudentsCount: Scalars["Int"]
  conflicts: GQLSyncConflictsPaginatedList
  conflictsByIndicator: Array<GQLQuantityByIndicator>
  conflictsCount: Scalars["Int"]
  endTime?: Maybe<Scalars["DateTime"]>
  errors: Array<GQLSyncCheckError>
  errorsCount: Scalars["Int"]
  id: Scalars["ID"]
  ignoredStudentsCount: Scalars["Int"]
  isCancelled: Scalars["Boolean"]
  ledgerStudentsCount: Scalars["Int"]
  outOfSyncStudentsCount: Scalars["Int"]
  startTime: Scalars["DateTime"]
  versionId: Scalars["ID"]
}

export type GQLSyncCheckConflictsArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>
}

export type GQLSyncCheckEdge = {
  __typename?: "SyncCheckEdge"
  cursor: Scalars["ID"]
  node: GQLSyncCheck
}

export type GQLSyncCheckError = {
  __typename?: "SyncCheckError"
  message: Scalars["String"]
  stack: Scalars["String"]
}

export enum GQLSyncCheckLogTypes {
  Cancel = "cancel",
  Conflict = "conflict",
  End = "end",
  Progress = "progress",
  Start = "start",
}

export type GQLSyncChecksPaginatedList = {
  __typename?: "SyncChecksPaginatedList"
  edges: Array<GQLSyncCheckEdge>
  pageInfo: GQLPageInfo
  totalCount: Scalars["Int"]
}

export type GQLSyncConflict = {
  details: GQLSyncConflictDetails
  id: Scalars["ID"]
  lastSyncCheckId: Scalars["ID"]
  syncIndicator: GQLSyncIndicator
}

export type GQLSyncConflictDayBreakdown = {
  __typename?: "SyncConflictDayBreakdown"
  date: Scalars["DateTime"]
  hasConflict: Scalars["Boolean"]
  legacyLogs: Array<GQLLegacyLogSummary>
  nextLogs: Array<GQLLedgerLog>
}

export type GQLSyncConflictDetails = {
  __typename?: "SyncConflictDetails"
  dayByDayBreakdown: Array<GQLSyncConflictDayBreakdown>
  timeZone: Scalars["String"]
}

export type GQLSyncConflictEdge = {
  __typename?: "SyncConflictEdge"
  cursor: Scalars["ID"]
  node: GQLSyncConflict
}

export type GQLSyncConflictsPaginatedList = {
  __typename?: "SyncConflictsPaginatedList"
  edges: Array<GQLSyncConflictEdge>
  pageInfo: GQLPageInfo
  totalCount: Scalars["Int"]
}

export type GQLSyncConflictsQuery = {
  syncCheckId?: InputMaybe<Scalars["ID"]>
}

export enum GQLSyncIndicator {
  StudentExpiredMinutes = "studentExpiredMinutes",
  StudentFundsBalance = "studentFundsBalance",
  StudentProductBalance = "studentProductBalance",
}

export type GQLSyncStudentExpiredMinutesConflict = GQLSyncConflict & {
  __typename?: "SyncStudentExpiredMinutesConflict"
  details: GQLSyncConflictDetails
  id: Scalars["ID"]
  lastSyncCheckId: Scalars["ID"]
  legacyId: GQLStudentId
  legacyValue: GQLSyncStudentExpiredMinutesEntityValue
  nextId: GQLStudentId
  nextValue: GQLSyncStudentExpiredMinutesEntityValue
  syncIndicator: GQLSyncIndicator
}

export type GQLSyncStudentExpiredMinutesEntityValue = {
  __typename?: "SyncStudentExpiredMinutesEntityValue"
  expiredMinutesTotal: Scalars["Int"]
}

export type GQLSyncStudentFundsBalanceConflict = GQLSyncConflict & {
  __typename?: "SyncStudentFundsBalanceConflict"
  details: GQLSyncConflictDetails
  id: Scalars["ID"]
  lastSyncCheckId: Scalars["ID"]
  legacyId: GQLStudentId
  legacyValue: GQLSyncStudentFundsBalanceConflictEntityValue
  nextId: GQLStudentId
  nextValue: GQLSyncStudentFundsBalanceConflictEntityValue
  syncIndicator: GQLSyncIndicator
}

export type GQLSyncStudentFundsBalanceConflictEntityValue = {
  __typename?: "SyncStudentFundsBalanceConflictEntityValue"
  paidCents: Scalars["Int"]
  refundedCents: Scalars["Int"]
  sentCents: Scalars["Int"]
}

export type GQLSyncStudentProductBalanceConflict = GQLSyncConflict & {
  __typename?: "SyncStudentProductBalanceConflict"
  details: GQLSyncConflictDetails
  id: Scalars["ID"]
  lastSyncCheckId: Scalars["ID"]
  legacyId: GQLStudentIdProductId
  legacyValue: GQLSyncStudentProductBalanceConflictEntityValue
  nextId: GQLStudentIdProductId
  nextValue: GQLSyncStudentProductBalanceConflictEntityValue
  syncIndicator: GQLSyncIndicator
}

export type GQLSyncStudentProductBalanceConflictEntityValue = {
  __typename?: "SyncStudentProductBalanceConflictEntityValue"
  purchasedQuantity: Scalars["Int"]
  refundedQuantity: Scalars["Int"]
  sentQuantity: Scalars["Int"]
  usedQuantity: Scalars["Int"]
}

export type GQLTestChargeLedgerLogInput = {
  drivingMinutes: Scalars["Int"]
  observationMinutes: Scalars["Int"]
  studentId: Scalars["ID"]
}

export type GQLTestUserInput = {
  acceptTOS?: InputMaybe<Scalars["Boolean"]>
  address?: InputMaybe<GQLAddressInput>
  dob?: InputMaybe<Scalars["DateTime"]>
  email: Scalars["String"]
  isAdmin?: InputMaybe<Scalars["Boolean"]>
  licenseNumber?: InputMaybe<Scalars["String"]>
  name: Scalars["String"]
  parentEmail?: InputMaybe<Scalars["String"]>
  parentName?: InputMaybe<Scalars["String"]>
  preferredPronoun?: InputMaybe<Scalars["String"]>
  roles: Array<GQLUserRole>
  smsOptIn?: InputMaybe<Scalars["Boolean"]>
  uid?: InputMaybe<Scalars["ID"]>
}

export type GQLTransferAccountCheckResult = {
  __typename?: "TransferAccountCheckResult"
  canTransfer: Scalars["Boolean"]
  errors: Array<Scalars["String"]>
  fromId: Scalars["ID"]
  toId: Scalars["ID"]
  warnings: Array<Scalars["String"]>
}

export type GQLTransferAccountInput = {
  fromId: Scalars["ID"]
  note?: InputMaybe<Scalars["String"]>
  toId: Scalars["ID"]
}

export type GQLTransferExpirationInput = {
  description: Scalars["String"]
  destinationStudentId: Scalars["ID"]
  expirationId: Scalars["ID"]
  sourceStudentId: Scalars["ID"]
}

export type GQLTransferLessonInput = {
  description: Scalars["String"]
  destinationStudentId: Scalars["ID"]
  lessonId: Scalars["ID"]
  shouldTransferPurchases: Scalars["Boolean"]
  sourceStudentId: Scalars["ID"]
}

export type GQLTransferPurchaseInput = {
  amountToTransfer?: InputMaybe<Scalars["Float"]>
  description: Scalars["String"]
  destinationStudentId: Scalars["ID"]
  itemsToTransfer: Array<GQLItemToTransferInput>
  purchaseId: Scalars["ID"]
  sourceStudentId: Scalars["ID"]
}

export type GQLTransientSlot = {
  __typename?: "TransientSlot"
  endTime: Scalars["DateTime"]
  id: Scalars["ID"]
  instructor: GQLInstructor
  instructorId: Scalars["ID"]
  isBooked?: Maybe<Scalars["Boolean"]>
  isConfirmed?: Maybe<Scalars["Boolean"]>
  isCustom?: Maybe<Scalars["Boolean"]>
  isReserved?: Maybe<Scalars["Boolean"]>
  isShareable: Scalars["Boolean"]
  isShared?: Maybe<Scalars["Boolean"]>
  lessonType: GQLLessonType
  lessonTypeId: Scalars["ID"]
  locationId?: Maybe<Scalars["String"]>
  note?: Maybe<Scalars["String"]>
  packagePrices?: Maybe<Array<GQLPackagePrice>>
  pickupAddress: Scalars["String"]
  price?: Maybe<Scalars["Float"]>
  reservedBy?: Maybe<GQLPerson>
  reservedFor?: Maybe<GQLPerson>
  searchAddress?: Maybe<Scalars["String"]>
  searchCity?: Maybe<Scalars["String"]>
  searchRegion?: Maybe<Scalars["String"]>
  startTime: Scalars["DateTime"]
  updatedAt?: Maybe<Scalars["DateTime"]>
}

export type GQLTransientSlotInput = {
  durationMinutes: Scalars["Float"]
  endTime: Scalars["DateTime"]
  instructorId: Scalars["ID"]
  isReserved?: InputMaybe<Scalars["Boolean"]>
  isShareable?: InputMaybe<Scalars["Boolean"]>
  lessonTypeId: Scalars["ID"]
  packagePrices?: InputMaybe<Array<GQLPackagePriceInput>>
  price?: InputMaybe<Scalars["Float"]>
  reservedForId?: InputMaybe<Scalars["ID"]>
  searchAddress: Scalars["String"]
  startTime: Scalars["DateTime"]
}

export type GQLTransientSlotsQuery = {
  endTime: Scalars["DateTime"]
  startTime: Scalars["DateTime"]
}

export type GQLUsState = {
  __typename?: "USState"
  id: Scalars["ID"]
  name: Scalars["String"]
}

export enum GQLUsStateId {
  CA = "CA",
  CO = "CO",
  IL = "IL",
  IN = "IN",
  MO = "MO",
  NV = "NV",
  PA = "PA",
  TX = "TX",
  WI = "WI",
}

export type GQLUnenrollStudentFromCohortInput = {
  cohortId: Scalars["ID"]
  studentId: Scalars["ID"]
}

export type GQLUpdateCourseSessionInput = {
  lectureId?: InputMaybe<Scalars["ID"]>
  onlineCourseId: Scalars["ID"]
  sessionId: Scalars["ID"]
  studentId: Scalars["ID"]
}

export type GQLUpdateInstructorInput = {
  about?: InputMaybe<Scalars["String"]>
  badges?: InputMaybe<Array<GQLInstructorBadgeInput>>
  baseAddress?: InputMaybe<Scalars["String"]>
  email?: InputMaybe<Scalars["String"]>
  firstName?: InputMaybe<Scalars["String"]>
  isInactive?: InputMaybe<Scalars["Boolean"]>
  languages?: InputMaybe<Scalars["String"]>
  lastName?: InputMaybe<Scalars["String"]>
  licenseExpirationDate?: InputMaybe<Scalars["DateTime"]>
  licenseNumber?: InputMaybe<Scalars["String"]>
  longBreakMinutes?: InputMaybe<Scalars["Int"]>
  maxBaseTravelMinutes?: InputMaybe<Scalars["Int"]>
  maxBetweenTravelMinutes?: InputMaybe<Scalars["Int"]>
  payrollFirstName?: InputMaybe<Scalars["String"]>
  payrollLastName?: InputMaybe<Scalars["String"]>
  payrollTitle?: InputMaybe<Scalars["String"]>
  phoneNumber?: InputMaybe<Scalars["String"]>
  portraitImage?: InputMaybe<Scalars["String"]>
  region?: InputMaybe<Scalars["ID"]>
  regionalManagerId?: InputMaybe<Scalars["ID"]>
  shortBreakMinutes?: InputMaybe<Scalars["Int"]>
  slackUsername?: InputMaybe<Scalars["String"]>
  summary?: InputMaybe<Scalars["String"]>
  timeZone?: InputMaybe<Scalars["String"]>
  title?: InputMaybe<Scalars["String"]>
  usStateId?: InputMaybe<Scalars["ID"]>
  userId?: InputMaybe<Scalars["ID"]>
}

export type GQLUpdateInstructorQuery = {
  instructorId: Scalars["ID"]
}

export type GQLUpdateParkingHubInput = {
  address: Scalars["String"]
  timeZone: Scalars["String"]
}

export type GQLUpdateProductInput = {
  description?: InputMaybe<Scalars["String"]>
  id: Scalars["ID"]
  name?: InputMaybe<Scalars["String"]>
  products?: InputMaybe<Array<GQLProductLinkInput>>
}

export type GQLUpdateStudentCourseMetaInput = {
  allowLowerGrade?: InputMaybe<Scalars["Boolean"]>
  isPassed?: InputMaybe<Scalars["Boolean"]>
  lectureId: Scalars["ID"]
  numCorrect?: InputMaybe<Scalars["Int"]>
  onlineCourseId: Scalars["ID"]
  studentId: Scalars["ID"]
  totalQuestions?: InputMaybe<Scalars["Int"]>
}

export type GQLUpdateStudentSessionAttendanceInput = {
  attendance: GQLAttendance
  cohortSessionId: Scalars["ID"]
  studentId: Scalars["ID"]
}

export type GQLUpdateStudentsSessionAttendanceInput = {
  attendance: GQLAttendance
  cohortSessionId: Scalars["ID"]
  studentIds: Array<Scalars["ID"]>
}

export type GQLUpdateVoucherInput = {
  amountDollars?: InputMaybe<Scalars["Float"]>
  amountHours?: InputMaybe<Scalars["Float"]>
  id: Scalars["ID"]
  name?: InputMaybe<Scalars["String"]>
  source?: InputMaybe<Scalars["String"]>
  zipcode?: InputMaybe<Scalars["String"]>
}

export type GQLUpdatedBy = {
  __typename?: "UpdatedBy"
  id?: Maybe<Scalars["ID"]>
  name: Scalars["String"]
  role: GQLAuditLogUserRoles
}

export type GQLUpsertCouponInput = {
  course?: InputMaybe<GQLCourseCouponInput>
  lesson?: InputMaybe<GQLLessonCouponInput>
  roadTest?: InputMaybe<GQLRoadTestCouponInput>
  type: GQLCouponType
}

export type GQLUserLog = {
  __typename?: "UserLog"
  action: Scalars["String"]
  changedBy?: Maybe<GQLPerson>
  changedById: Scalars["ID"]
  changes: Array<GQLLogChange>
  id: Scalars["ID"]
  initiatedBy?: Maybe<GQLInitiatedBy>
  instructor?: Maybe<GQLInstructor>
  instructorId?: Maybe<Scalars["ID"]>
  lessonStartTime?: Maybe<Scalars["DateTime"]>
  note?: Maybe<Scalars["String"]>
  timestamp?: Maybe<Scalars["DateTime"]>
  type?: Maybe<Scalars["String"]>
  user?: Maybe<GQLPerson>
  userId: Scalars["ID"]
}

export type GQLUserLogEdge = {
  __typename?: "UserLogEdge"
  cursor: Scalars["ID"]
  node: GQLUserLog
}

export type GQLUserLogsPaginatedList = {
  __typename?: "UserLogsPaginatedList"
  edges: Array<GQLUserLogEdge>
  pageInfo: GQLPageInfo
  totalCount: Scalars["Int"]
}

export type GQLUserLogsQuery = {
  logTypes: GQLLogTypes
  userId?: InputMaybe<Scalars["ID"]>
}

export enum GQLUserRole {
  Admin = "Admin",
  ClassroomManager = "ClassroomManager",
  Fleet = "Fleet",
  Fulfillment = "Fulfillment",
  Instructor = "Instructor",
  Manager = "Manager",
  Student = "Student",
  Support = "Support",
  SyncCheckAdmin = "SyncCheckAdmin",
}

export type GQLVehicle = {
  __typename?: "Vehicle"
  color?: Maybe<Scalars["String"]>
  id: Scalars["ID"]
  instructorIds?: Maybe<Array<Scalars["ID"]>>
  instructors?: Maybe<Array<GQLInstructor>>
  make?: Maybe<Scalars["String"]>
  model?: Maybe<Scalars["String"]>
  name?: Maybe<Scalars["String"]>
  number?: Maybe<Scalars["String"]>
  opsManagerUserId?: Maybe<Scalars["ID"]>
  parkingHub?: Maybe<Scalars["String"]>
  plate?: Maybe<Scalars["String"]>
  samsaraId?: Maybe<Scalars["String"]>
  usStateId?: Maybe<GQLUsStateId>
}

export type GQLVehicleAvail = {
  __typename?: "VehicleAvail"
  availId: Scalars["ID"]
  date: Scalars["String"]
  endTime: Scalars["DateTime"]
  instructor: GQLVehicleInstructorFields
  startTime: Scalars["DateTime"]
  vehicleId: Scalars["ID"]
  vehicleName: Scalars["String"]
}

export type GQLVehicleAvailability = {
  __typename?: "VehicleAvailability"
  endTime: Scalars["DateTime"]
  id: Scalars["ID"]
  instructorName: Scalars["String"]
  startTime: Scalars["DateTime"]
  vehicleId: Scalars["ID"]
  vehicleName: Scalars["String"]
}

export type GQLVehicleAvailabilityQuery = {
  date: Scalars["String"]
}

export type GQLVehicleEdge = {
  __typename?: "VehicleEdge"
  cursor: Scalars["ID"]
  node: GQLVehicle
}

export type GQLVehicleInput = {
  color: Scalars["String"]
  id?: InputMaybe<Scalars["ID"]>
  instructorId: Scalars["ID"]
  make: Scalars["String"]
  model: Scalars["String"]
  name: Scalars["String"]
  number: Scalars["String"]
  opsManagerUserId?: InputMaybe<Scalars["ID"]>
  parkingHub: Scalars["String"]
  plate: Scalars["String"]
  samsaraId: Scalars["String"]
  state: GQLUsStateId
}

export type GQLVehicleInstructorFields = {
  __typename?: "VehicleInstructorFields"
  displayName: Scalars["String"]
  id: Scalars["ID"]
  timeZone: Scalars["String"]
}

export type GQLVehicleMapAvailability = {
  __typename?: "VehicleMapAvailability"
  availId: Scalars["ID"]
  endTime: Scalars["DateTime"]
  instructor: GQLInstructorOnAvail
  startTime: Scalars["DateTime"]
  vehicleId: Scalars["ID"]
  vehicleName: Scalars["String"]
}

export type GQLVehicleMapAvailabilityQuery = {
  endTime: Scalars["String"]
  startTime: Scalars["String"]
  vehicleIds: Array<Scalars["ID"]>
}

export type GQLVehiclesPaginatedList = {
  __typename?: "VehiclesPaginatedList"
  edges: Array<GQLVehicleEdge>
  pageInfo: GQLPageInfo
  totalCount: Scalars["Int"]
}

export type GQLVoidExpirationInput = {
  expirationId: Scalars["ID"]
  studentId: Scalars["ID"]
}

export type GQLVoidPurchaseInput = {
  purchaseId: Scalars["ID"]
  studentId: Scalars["ID"]
}

export type GQLVoidRefundInput = {
  refundId: Scalars["ID"]
  studentId: Scalars["ID"]
}

export type GQLVoucher = {
  __typename?: "Voucher"
  amountDollars?: Maybe<Scalars["Float"]>
  amountHours?: Maybe<Scalars["Float"]>
  id: Scalars["ID"]
  isRedeemed: Scalars["Boolean"]
  name?: Maybe<Scalars["String"]>
  source?: Maybe<Scalars["String"]>
  timestamp: Scalars["DateTime"]
  zipcode?: Maybe<Scalars["String"]>
}

export type GQLVoucherEdge = {
  __typename?: "VoucherEdge"
  cursor: Scalars["ID"]
  node: GQLVoucher
}

export type GQLVoucherRedeemInput = {
  id: Scalars["ID"]
  issuedToId: Scalars["ID"]
}

export type GQLVoucherSource = {
  __typename?: "VoucherSource"
  key: Scalars["String"]
  label: Scalars["String"]
}

export type GQLVouchersPaginatedList = {
  __typename?: "VouchersPaginatedList"
  edges: Array<GQLVoucherEdge>
  pageInfo: GQLPageInfo
  totalCount: Scalars["Int"]
}

export type GQLVouchersRedeemedByStudentQuery = {
  studentId: Scalars["ID"]
}

export type GQLWaitlistComment = {
  __typename?: "WaitlistComment"
  author: GQLPerson
  createdAt: Scalars["DateTime"]
  id: Scalars["ID"]
  text: Scalars["String"]
}

export type GQLWaitlistCommentInput = {
  text: Scalars["String"]
  waitlistId: Scalars["ID"]
}

export type GQLWaitlistCommentsQuery = {
  waitlistId: Scalars["ID"]
}

export type GQLWaitlistEdge = {
  __typename?: "WaitlistEdge"
  cursor: Scalars["ID"]
  node: GQLWaitlistStudent
}

export type GQLWaitlistPaginatedList = {
  __typename?: "WaitlistPaginatedList"
  edges: Array<GQLWaitlistEdge>
  pageInfo: GQLPageInfo
  totalCount: Scalars["Int"]
}

export type GQLWaitlistStudent = {
  __typename?: "WaitlistStudent"
  availability: Scalars["String"]
  bookingLocation: Scalars["String"]
  comments: Array<GQLWaitlistComment>
  commentsCount: Scalars["Int"]
  createdAt: Scalars["DateTime"]
  expiresAt: Scalars["DateTime"]
  id: Scalars["ID"]
  phone?: Maybe<Scalars["String"]>
  source: Scalars["String"]
  studentEmail: Scalars["String"]
  studentId?: Maybe<Scalars["ID"]>
  studentName: Scalars["String"]
}

export type GQLWaitlistStudentInput = {
  availability: Scalars["String"]
  bookingLocation: Scalars["String"]
  expiresAt: Scalars["DateTime"]
  id?: InputMaybe<Scalars["ID"]>
  phone?: InputMaybe<Scalars["String"]>
  source: Scalars["String"]
  studentEmail: Scalars["String"]
  studentId?: InputMaybe<Scalars["ID"]>
  studentName: Scalars["String"]
}

export enum GQLWeekDays {
  Friday = "Friday",
  Monday = "Monday",
  Saturday = "Saturday",
  Sunday = "Sunday",
  Thursday = "Thursday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
}

export type GQLCreateSessionCookieMutationVariables = Exact<{
  session: GQLCreateSessionCookieInput
}>

export type GQLCreateSessionCookieMutation = {
  __typename?: "Mutation"
  createSessionCookie: string
}

export type GQLSignOutNextMutationVariables = Exact<{ [key: string]: never }>

export type GQLSignOutNextMutation = {
  __typename?: "Mutation"
  signOut: boolean
}

export type GQLResetTestLedgerLogsMutationVariables = Exact<{
  [key: string]: never
}>

export type GQLResetTestLedgerLogsMutation = {
  __typename?: "Mutation"
  resetTestLedgerLogs: boolean
}

export type GQLExpireProductsMutationVariables = Exact<{
  input: GQLExpireProductsInput
}>

export type GQLExpireProductsMutation = {
  __typename?: "Mutation"
  expireProducts: { __typename?: "Expiration"; id: string }
}

export type GQLVoidExpirationMutationVariables = Exact<{
  input: GQLVoidExpirationInput
}>

export type GQLVoidExpirationMutation = {
  __typename?: "Mutation"
  purchaseLog: { __typename?: "Expiration"; id: string }
}

export type GQLTransferExpirationMutationVariables = Exact<{
  input: GQLTransferExpirationInput
}>

export type GQLTransferExpirationMutation = {
  __typename?: "Mutation"
  transferExpiration: { __typename?: "Expiration"; id: string }
}

export type GQLTransferPurchaseMutationVariables = Exact<{
  input: GQLTransferPurchaseInput
}>

export type GQLTransferPurchaseMutation = {
  __typename?: "Mutation"
  transferPurchase: { __typename?: "Purchase"; id: string }
}

export type GQLTransferLessonMutationVariables = Exact<{
  input: GQLTransferLessonInput
}>

export type GQLTransferLessonMutation = {
  __typename?: "Mutation"
  transferLesson: { __typename?: "Lesson"; id: string }
}

export type GQLLessonForBookingFragment = {
  __typename?: "Lesson"
  id: string
  bookedAt: number
  startTime: number
  endTime: number
  actualDurationMinutes?: number | null
  instructor: {
    __typename?: "Instructor"
    id: string
    name: string
    timeZone: string
  }
}

export type GQLScheduleLessonMutationVariables = Exact<{
  input: GQLScheduleLessonInput
}>

export type GQLScheduleLessonMutation = {
  __typename?: "Mutation"
  scheduleLesson: {
    __typename?: "Lesson"
    id: string
    bookedAt: number
    startTime: number
    endTime: number
    actualDurationMinutes?: number | null
    instructor: {
      __typename?: "Instructor"
      id: string
      name: string
      timeZone: string
    }
  }
}

export type GQLSelfBookSharedLessonMutationVariables = Exact<{
  input: GQLSelfBookSharedLessonInput
}>

export type GQLSelfBookSharedLessonMutation = {
  __typename?: "Mutation"
  selfBookSharedLesson: {
    __typename?: "Lesson"
    id: string
    bookedAt: number
    startTime: number
    endTime: number
    actualDurationMinutes?: number | null
    instructor: {
      __typename?: "Instructor"
      id: string
      name: string
      timeZone: string
    }
  }
}

export type GQLUpdateLessonMutationVariables = Exact<{
  query: GQLLessonUpdateQuery
  update: GQLLessonUpdateInput
}>

export type GQLUpdateLessonMutation = {
  __typename?: "Mutation"
  updateLesson: { __typename?: "Lesson"; id: string }
}

export type GQLCancelLessonMutationVariables = Exact<{
  query: GQLLessonCancelQuery
  input: GQLLessonCancellationInfo
}>

export type GQLCancelLessonMutation = {
  __typename?: "Mutation"
  cancelLesson: { __typename?: "Lesson"; id: string }
}

export type GQLRemoveStudentFromLessonMutationVariables = Exact<{
  query: GQLLessonRemoveStudentQuery
  input: GQLLessonCancellationInfo
}>

export type GQLRemoveStudentFromLessonMutation = {
  __typename?: "Mutation"
  removeStudentFromLesson: { __typename?: "Lesson"; id: string }
}

export type GQLRefundPurchaseMutationVariables = Exact<{
  input: GQLRefundPurchaseInput
}>

export type GQLRefundPurchaseMutation = {
  __typename?: "Mutation"
  refundPurchase: { __typename?: "Refund"; id: string; amountToRefund: number }
}

export type GQLEditPurchaseMutationVariables = Exact<{
  input: GQLEditPurchaseInput
}>

export type GQLEditPurchaseMutation = {
  __typename?: "Mutation"
  editPurchase: { __typename?: "Purchase"; id: string }
}

export type GQLVoidPurchaseMutationVariables = Exact<{
  input: GQLVoidPurchaseInput
}>

export type GQLVoidPurchaseMutation = {
  __typename?: "Mutation"
  purchaseLog: { __typename?: "Purchase"; id: string }
}

export type GQLCreatePurchaseMutationVariables = Exact<{
  input: GQLCreatePurchaseInput
}>

export type GQLCreatePurchaseMutation = {
  __typename?: "Mutation"
  createPurchase: { __typename?: "Purchase"; id: string }
}

export type GQLInstructorUpdateLessonMutationVariables = Exact<{
  query: GQLLessonUpdateQuery
  update: GQLInstructorLessonUpdateInput
}>

export type GQLInstructorUpdateLessonMutation = {
  __typename?: "Mutation"
  instructorUpdateLesson: { __typename?: "Lesson"; id: string }
}

export type GQLSignOutMutationVariables = Exact<{ [key: string]: never }>

export type GQLSignOutMutation = { __typename?: "Mutation"; signOut: boolean }

export type GQLVoucherForViewFragment = {
  __typename?: "Voucher"
  id: string
  amountDollars?: number | null
  amountHours?: number | null
  isRedeemed: boolean
  name?: string | null
  source?: string | null
  timestamp: number
  zipcode?: string | null
}

export type GQLRedeemVoucherMutationVariables = Exact<{
  voucher: GQLVoucherRedeemInput
}>

export type GQLRedeemVoucherMutation = {
  __typename?: "Mutation"
  redeemVoucher: {
    __typename?: "RedeemVoucherResult"
    voucher: {
      __typename?: "Voucher"
      id: string
      amountDollars?: number | null
      amountHours?: number | null
      isRedeemed: boolean
      name?: string | null
      source?: string | null
      timestamp: number
      zipcode?: string | null
    }
  }
}

export type GQLCreateVoucherMutationVariables = Exact<{
  voucherInput: GQLCreateVoucherInput
}>

export type GQLCreateVoucherMutation = {
  __typename?: "Mutation"
  voucher: {
    __typename?: "Voucher"
    id: string
    amountDollars?: number | null
    amountHours?: number | null
    isRedeemed: boolean
    name?: string | null
    source?: string | null
    timestamp: number
    zipcode?: string | null
  }
}

export type GQLUpdateVoucherMutationVariables = Exact<{
  voucherInput: GQLUpdateVoucherInput
}>

export type GQLUpdateVoucherMutation = {
  __typename?: "Mutation"
  voucher: {
    __typename?: "Voucher"
    id: string
    amountDollars?: number | null
    amountHours?: number | null
    isRedeemed: boolean
    name?: string | null
    source?: string | null
    timestamp: number
    zipcode?: string | null
  }
}

export type GQLImpersonateUserMutationVariables = Exact<{
  userId: Scalars["ID"]
}>

export type GQLImpersonateUserMutation = {
  __typename?: "Mutation"
  impersonateUser: { __typename?: "ImpersonateUserResponse"; token: string }
}

type GQLCouponForView_CourseCoupon_Fragment = {
  __typename: "CourseCoupon"
  id: string
  code: string
  discount: number
  title: string
  courseId?: string | null
  state: GQLUsStateId
  isDailyCoupon: boolean
  type: GQLCouponType
  expiration?: number | null
}

type GQLCouponForView_LessonCoupon_Fragment = {
  __typename: "LessonCoupon"
  id: string
  code: string
  discount: number
  type: GQLCouponType
  title: string
  expiration?: number | null
  displayInLandingPages?: boolean | null
  onlyFirstPurchase: boolean
  regions: Array<{ __typename?: "Region"; id: string }>
}

type GQLCouponForView_RoadTestCoupon_Fragment = {
  __typename: "RoadTestCoupon"
  id: string
  code: string
  discount: number
  type: GQLCouponType
  title: string
  expiration?: number | null
  displayInLandingPages?: boolean | null
  onlyFirstPurchase: boolean
  regions: Array<{ __typename?: "Region"; id: string }>
}

export type GQLCouponForViewFragment =
  | GQLCouponForView_CourseCoupon_Fragment
  | GQLCouponForView_LessonCoupon_Fragment
  | GQLCouponForView_RoadTestCoupon_Fragment

export type GQLCouponsListQueryVariables = Exact<{
  first: Scalars["Int"]
}>

export type GQLCouponsListQuery = {
  __typename?: "Query"
  coupons: {
    __typename?: "CouponsPaginatedList"
    edges: Array<{
      __typename?: "CouponEdge"
      cursor: string
      node:
        | {
            __typename: "CourseCoupon"
            id: string
            code: string
            discount: number
            title: string
            courseId?: string | null
            state: GQLUsStateId
            isDailyCoupon: boolean
            type: GQLCouponType
            expiration?: number | null
          }
        | {
            __typename: "LessonCoupon"
            id: string
            code: string
            discount: number
            type: GQLCouponType
            title: string
            expiration?: number | null
            displayInLandingPages?: boolean | null
            onlyFirstPurchase: boolean
            regions: Array<{ __typename?: "Region"; id: string }>
          }
        | {
            __typename: "RoadTestCoupon"
            id: string
            code: string
            discount: number
            type: GQLCouponType
            title: string
            expiration?: number | null
            displayInLandingPages?: boolean | null
            onlyFirstPurchase: boolean
            regions: Array<{ __typename?: "Region"; id: string }>
          }
    }>
  }
}

export type GQLCourseBasicInfoFragment = {
  __typename?: "OnlineCourseContent"
  id: string
  title: string
  stateShortName: string
  isClassroomCourse: boolean
}

export type GQLFetchCoursesForEnrollmentQueryVariables = Exact<{
  [key: string]: never
}>

export type GQLFetchCoursesForEnrollmentQuery = {
  __typename?: "Query"
  courses: Array<{
    __typename?: "OnlineCourseContent"
    id: string
    title: string
    stateShortName: string
    isClassroomCourse: boolean
  }>
}

export type GQLCourseForSelectFragment = {
  __typename?: "OnlineCourseContent"
  id: string
  title: string
  stateShortName: string
}

export type GQLInstructorInSuperRegionViewFragment = {
  __typename?: "InstructorWithSuperRegion"
  userId: string
  displayName: string
  superRegion?: string | null
  region?: string | null
  id: string
}

export type GQLFetchInstructorsInSuperRegionQueryVariables = Exact<{
  [key: string]: never
}>

export type GQLFetchInstructorsInSuperRegionQuery = {
  __typename?: "Query"
  instructorsInSuperRegionForUser: Array<{
    __typename?: "InstructorWithSuperRegion"
    userId: string
    displayName: string
    superRegion?: string | null
    region?: string | null
    id: string
  }>
}

export type GQLGetCompletedCartsQueryVariables = Exact<{
  filter: GQLCartFilterInput
  pageQuery?: InputMaybe<GQLPageQuery>
}>

export type GQLGetCompletedCartsQuery = {
  __typename?: "Query"
  completedCarts: {
    __typename?: "CartsPaginatedList"
    totalCount: number
    edges: Array<{
      __typename?: "CartEdge"
      node: {
        __typename?: "Cart"
        id: string
        updatedAt: number
        isFirstPurchase?: boolean | null
        student?: {
          __typename?: "Student"
          id: string
          name: string
          email: string
          dob?: number | null
          address: { __typename?: "Address"; city?: string | null }
        } | null
        items: Array<{
          __typename?: "ProductPackageWithPrice"
          id: string
          title: string
        }>
      }
    }>
    pageInfo: {
      __typename?: "PageInfo"
      hasNextPage: boolean
      endCursor?: string | null
    }
  }
}

export type GQLGetRegionSchemesQueryVariables = Exact<{
  query?: InputMaybe<GQLRegionSchemeQuery>
}>

export type GQLGetRegionSchemesQuery = {
  __typename?: "Query"
  regionSchemes: {
    __typename?: "RegionSchemePaginatedList"
    edges: Array<{
      __typename?: "RegionSchemeEdge"
      node: { __typename?: "RegionScheme"; id: string }
    }>
  }
}

export type GQLVoidRefundMutationVariables = Exact<{
  input: GQLVoidRefundInput
}>

export type GQLVoidRefundMutation = {
  __typename?: "Mutation"
  purchaseLog: { __typename?: "Refund"; id: string }
}

export type GQLSharedLessonStudentDetailsFragment = {
  __typename?: "LessonStudentDetails"
  studentId: string
  drivingMinutes: number
  observationMinutes: number
}

export type GQLSharedLessonViewFragment = {
  __typename?: "SharedLesson"
  lesson: {
    __typename?: "Lesson"
    id: string
    startTime: number
    endTime: number
    durationMinutes: number
    meetingPointId?: string | null
    locationId?: string | null
    isShareable: boolean
    pickupLocation: string
    instructor: {
      __typename?: "Instructor"
      id: string
      name: string
      timeZone: string
    }
    studentDetailsList: Array<{
      __typename?: "LessonStudentDetails"
      studentId: string
      drivingMinutes: number
      observationMinutes: number
    }>
  }
  eligibleStudents: Array<{
    __typename?: "EligibleStudent"
    userId: string
    displayName: string
    distanceMeters: number
    dob: number
    email: string
    phone?: string | null
    drivingMinutesRemaining: number
    observationMinutesRemaining: number
    licenseExpirationDate?: number | null
    licenseIssueDate?: number | null
    licenseNumber?: string | null
    tags?: Array<string> | null
  }>
}

export type GQLGetSharedLessonsQueryVariables = Exact<{
  sharedLessonsQuery: GQLSharedLessonsQuery
}>

export type GQLGetSharedLessonsQuery = {
  __typename?: "Query"
  sharedLessons: Array<{
    __typename?: "SharedLesson"
    lesson: {
      __typename?: "Lesson"
      id: string
      startTime: number
      endTime: number
      durationMinutes: number
      meetingPointId?: string | null
      locationId?: string | null
      isShareable: boolean
      pickupLocation: string
      instructor: {
        __typename?: "Instructor"
        id: string
        name: string
        timeZone: string
      }
      studentDetailsList: Array<{
        __typename?: "LessonStudentDetails"
        studentId: string
        drivingMinutes: number
        observationMinutes: number
      }>
    }
    eligibleStudents: Array<{
      __typename?: "EligibleStudent"
      userId: string
      displayName: string
      distanceMeters: number
      dob: number
      email: string
      phone?: string | null
      drivingMinutesRemaining: number
      observationMinutesRemaining: number
      licenseExpirationDate?: number | null
      licenseIssueDate?: number | null
      licenseNumber?: string | null
      tags?: Array<string> | null
    }>
  }>
}

export type GQLTimeOffForCalendarFragment = {
  __typename?: "InstructorTimeOff"
  id: string
  instructorId: string
  startDate: string
  endDate: string
}

export type GQLInstructorScheduleFragment = {
  __typename?: "InstructorSchedule"
  id: string
  instructorId: string
  createdAt: number
  startDate: string
  endDate: string
  isApproved: boolean
  daysPerWeek: number
  updatedAt: number
  instructorName?: string | null
  managerName?: string | null
  dayTimesAvailable: Array<{
    __typename?: "ScheduleDayTimes"
    dayOfTheWeek: string
    startTime: string
    endTime: string
  }>
  timesOff?: Array<{
    __typename?: "InstructorTimeOff"
    id: string
    instructorId: string
    startDate: string
    endDate: string
  }> | null
}

export type GQLGetInstructorSchedulesQueryVariables = Exact<{
  query: GQLInstructorScheduleQuery
}>

export type GQLGetInstructorSchedulesQuery = {
  __typename?: "Query"
  instructorSchedules?: Array<{
    __typename?: "InstructorSchedule"
    id: string
    instructorId: string
    createdAt: number
    startDate: string
    endDate: string
    isApproved: boolean
    daysPerWeek: number
    updatedAt: number
    instructorName?: string | null
    managerName?: string | null
    dayTimesAvailable: Array<{
      __typename?: "ScheduleDayTimes"
      dayOfTheWeek: string
      startTime: string
      endTime: string
    }>
    timesOff?: Array<{
      __typename?: "InstructorTimeOff"
      id: string
      instructorId: string
      startDate: string
      endDate: string
    }> | null
  }> | null
}

export type GQLGetInstructorScheduleQueryVariables = Exact<{
  query: GQLInstructorScheduleQuery
}>

export type GQLGetInstructorScheduleQuery = {
  __typename?: "Query"
  instructorSchedule: {
    __typename?: "InstructorSchedule"
    id: string
    instructorId: string
    createdAt: number
    startDate: string
    endDate: string
    isApproved: boolean
    daysPerWeek: number
    updatedAt: number
    instructorName?: string | null
    managerName?: string | null
    dayTimesAvailable: Array<{
      __typename?: "ScheduleDayTimes"
      dayOfTheWeek: string
      startTime: string
      endTime: string
    }>
    timesOff?: Array<{
      __typename?: "InstructorTimeOff"
      id: string
      instructorId: string
      startDate: string
      endDate: string
    }> | null
  }
}

export type GQLCreateApprovedInstructorScheduleMutationVariables = Exact<{
  input: GQLApprovedInstructorScheduleInput
}>

export type GQLCreateApprovedInstructorScheduleMutation = {
  __typename?: "Mutation"
  createApprovedInstructorSchedule: string
}

export type GQLRemoveElevatedAccessMutationVariables = Exact<{
  userId: Scalars["ID"]
}>

export type GQLRemoveElevatedAccessMutation = {
  __typename?: "Mutation"
  removeElevatedAccess: boolean
}

export type GQLCreateTimeOffMutationVariables = Exact<{
  input: GQLCreateInstructorTimeOffInput
}>

export type GQLCreateTimeOffMutation = {
  __typename?: "Mutation"
  createTimeOff?: string | null
}

export type GQLUpdateTimeOffMutationVariables = Exact<{
  input: GQLInstructorTimeOffInput
  query: GQLInstructorMutationTimeOffQuery
}>

export type GQLUpdateTimeOffMutation = {
  __typename?: "Mutation"
  updateTimeOff?: string | null
}

export type GQLTimeOffFragment = {
  __typename?: "InstructorTimeOff"
  id: string
  instructorId: string
  createdAt: number
  startDate: string
  endDate: string
  updatedAt: number
}

export type GQLGetInstructorTimeOffQueryVariables = Exact<{
  query: GQLInstructorTimeOffQuery
}>

export type GQLGetInstructorTimeOffQuery = {
  __typename?: "Query"
  instructorTimeOff?: Array<{
    __typename?: "InstructorTimeOff"
    id: string
    instructorId: string
    createdAt: number
    startDate: string
    endDate: string
    updatedAt: number
  }> | null
}

export type GQLDeleteTimeOffMutationVariables = Exact<{
  query: GQLInstructorMutationTimeOffQuery
}>

export type GQLDeleteTimeOffMutation = {
  __typename?: "Mutation"
  deleteTimeOff?: string | null
}

export type GQLCohortDetailsFragment = {
  __typename?: "Cohort"
  id: string
  name: string
  stateId: string
  description: string
  startDate: string
  endDate: string
  instructor: string
  isEnrollmentOpen: boolean
  state: GQLUsStateId
  timeZone: string
  location: string
  maxEnrollees: number
  courseId: string
  numEnrolled: number
}

export type GQLSaveCohortMutationVariables = Exact<{
  input: GQLCohortInput
}>

export type GQLSaveCohortMutation = {
  __typename?: "Mutation"
  saveCohort: {
    __typename?: "Cohort"
    id: string
    name: string
    stateId: string
    description: string
    startDate: string
    endDate: string
    instructor: string
    isEnrollmentOpen: boolean
    state: GQLUsStateId
    timeZone: string
    location: string
    maxEnrollees: number
    courseId: string
    numEnrolled: number
  }
}

export type GQLFetchCohortQueryVariables = Exact<{
  id: Scalars["ID"]
}>

export type GQLFetchCohortQuery = {
  __typename?: "Query"
  cohort: {
    __typename?: "Cohort"
    enrolledUserIds?: Array<string | null> | null
    id: string
    name: string
    stateId: string
    description: string
    startDate: string
    endDate: string
    instructor: string
    isEnrollmentOpen: boolean
    state: GQLUsStateId
    timeZone: string
    location: string
    maxEnrollees: number
    courseId: string
    numEnrolled: number
    sessions?: Array<{
      __typename?: "CohortSession"
      id: string
      cohortId: string
      name: string
      startTime: number
      endTime: number
      attendance?: Array<{
        __typename?: "StudentSessionAttendance"
        id: string
        studentId: string
        attendance: GQLAttendance
        cohortSessionId: string
        makeUpCohortId?: string | null
        makeUpSessionId?: string | null
      }> | null
    }> | null
    students?: Array<{
      __typename?: "StudentObject"
      id: string
      name: string
    }> | null
  }
}

export type GQLUnenrollStudentFromCohortMutationVariables = Exact<{
  input: GQLUnenrollStudentFromCohortInput
}>

export type GQLUnenrollStudentFromCohortMutation = {
  __typename?: "Mutation"
  unenrollStudentFromCohort: boolean
}

export type GQLUpdateStudentsSessionAttendanceMutationVariables = Exact<{
  input: GQLUpdateStudentsSessionAttendanceInput
}>

export type GQLUpdateStudentsSessionAttendanceMutation = {
  __typename?: "Mutation"
  updateStudentsSessionAttendance: boolean
}

export type GQLFetchCohortsForStudentQueryVariables = Exact<{
  studentId: Scalars["ID"]
  courseId: Scalars["ID"]
}>

export type GQLFetchCohortsForStudentQuery = {
  __typename?: "Query"
  cohortsForStudent: Array<{
    __typename?: "Cohort"
    id: string
    name: string
    stateId: string
    description: string
    startDate: string
    endDate: string
    instructor: string
    isEnrollmentOpen: boolean
    state: GQLUsStateId
    timeZone: string
    location: string
    maxEnrollees: number
    courseId: string
    numEnrolled: number
    sessions?: Array<{
      __typename?: "CohortSession"
      id: string
      name: string
      startTime: number
      endTime: number
      attendance?: Array<{
        __typename?: "StudentSessionAttendance"
        attendance: GQLAttendance
      }> | null
    }> | null
  }>
}

export type GQLEnrollStudentToCohortMutationVariables = Exact<{
  input: GQLEnrollStudentToCohortInput
}>

export type GQLEnrollStudentToCohortMutation = {
  __typename?: "Mutation"
  enrollStudentToCohort: boolean
}

export type GQLFetchCohortsWithSessionsQueryVariables = Exact<{
  doShowClosed?: InputMaybe<Scalars["Boolean"]>
  courseId: Scalars["ID"]
}>

export type GQLFetchCohortsWithSessionsQuery = {
  __typename?: "Query"
  cohorts: Array<{
    __typename?: "Cohort"
    id: string
    name: string
    stateId: string
    description: string
    startDate: string
    endDate: string
    instructor: string
    isEnrollmentOpen: boolean
    state: GQLUsStateId
    timeZone: string
    location: string
    maxEnrollees: number
    courseId: string
    numEnrolled: number
    sessions?: Array<{
      __typename?: "CohortSession"
      id: string
      cohortId: string
      name: string
      startTime: number
      endTime: number
    }> | null
  }>
}

export type GQLGetClassroomCoursesForDropdownQueryVariables = Exact<{
  [key: string]: never
}>

export type GQLGetClassroomCoursesForDropdownQuery = {
  __typename?: "Query"
  courses: Array<{
    __typename?: "OnlineCourseContent"
    id: string
    stateShortName: string
    title: string
    slug: string
    locationId?: string | null
    maxEnrollees?: number | null
  }>
}

export type GQLFetchCourseForGradesTableQueryVariables = Exact<{
  id: Scalars["ID"]
}>

export type GQLFetchCourseForGradesTableQuery = {
  __typename?: "Query"
  courseById: {
    __typename?: "OnlineCourseContent"
    id: string
    lectureIds: Array<string>
    topics: Array<{
      __typename?: "CourseTopic"
      lectures: Array<{
        __typename?: "CourseLecture"
        id: string
        title: string
      }>
    }>
  }
}

export type GQLAddMakeUpSessionInformationMutationVariables = Exact<{
  input: GQLAddMakeUpSessionInformationInput
}>

export type GQLAddMakeUpSessionInformationMutation = {
  __typename?: "Mutation"
  addMakeUpSessionInformation: boolean
}

export type GQLUpdateStudentSessionAttendanceMutationVariables = Exact<{
  input: GQLUpdateStudentSessionAttendanceInput
}>

export type GQLUpdateStudentSessionAttendanceMutation = {
  __typename?: "Mutation"
  updateStudentSessionAttendance: boolean
}

export type GQLSaveCohortSessionMutationVariables = Exact<{
  input: GQLCohortSessionInput
}>

export type GQLSaveCohortSessionMutation = {
  __typename?: "Mutation"
  saveSession: { __typename?: "CohortSession"; id: string }
}

export type GQLGetCourseRequiresNotarizedFormQueryVariables = Exact<{
  id: Scalars["ID"]
}>

export type GQLGetCourseRequiresNotarizedFormQuery = {
  __typename?: "Query"
  course: {
    __typename?: "OnlineCourseContent"
    id: string
    requiresNotarizedForm?: boolean | null
  }
}

export type GQLGetCourseTitleQueryVariables = Exact<{
  id: Scalars["ID"]
}>

export type GQLGetCourseTitleQuery = {
  __typename?: "Query"
  course: { __typename?: "OnlineCourseContent"; id: string; title: string }
}

export type GQLAvailabilityForCalendarFragment = {
  __typename?: "VehicleMapAvailability"
  availId: string
  endTime: number
  startTime: number
  vehicleId: string
  vehicleName: string
  instructor: {
    __typename?: "InstructorOnAvail"
    id: string
    displayName: string
    timeZone: string
  }
}

export type GQLVehicleMapAvailabilitiesQueryQueryVariables = Exact<{
  vehicleIds: Array<Scalars["ID"]> | Scalars["ID"]
  startTime: Scalars["String"]
  endTime: Scalars["String"]
}>

export type GQLVehicleMapAvailabilitiesQueryQuery = {
  __typename?: "Query"
  vehicleMapAvailabilities: Array<{
    __typename?: "VehicleMapAvailability"
    availId: string
    endTime: number
    startTime: number
    vehicleId: string
    vehicleName: string
    instructor: {
      __typename?: "InstructorOnAvail"
      id: string
      displayName: string
      timeZone: string
    }
  }>
}

export type GQLParkingHubForSelectFragment = {
  __typename?: "ParkingHub"
  id: string
  name: string
}

export type GQLParkingHubsQueryQueryVariables = Exact<{ [key: string]: never }>

export type GQLParkingHubsQueryQuery = {
  __typename?: "Query"
  parkingHubs: Array<{ __typename?: "ParkingHub"; id: string; name: string }>
}

export type GQLParkingHubDetailsFragment = {
  __typename?: "ParkingHub"
  id: string
  name: string
  timeZone?: string | null
  center?: { __typename?: "LocationCenter"; address: string } | null
}

export type GQLParkingHubQueryQueryVariables = Exact<{
  id: Scalars["ID"]
}>

export type GQLParkingHubQueryQuery = {
  __typename?: "Query"
  parkingHub?: {
    __typename?: "ParkingHub"
    id: string
    name: string
    timeZone?: string | null
    center?: { __typename?: "LocationCenter"; address: string } | null
  } | null
}

export type GQLInstructorAlertViewFragment = {
  __typename?: "InstructorAlert"
  id: string
  message: string
  severity: GQLInstructorAlertSeverity
  timestamp: number
  readMoreLink?: string | null
}

export type GQLFetchInstructorAlertsQueryVariables = Exact<{
  pageQuery?: InputMaybe<GQLPageQuery>
}>

export type GQLFetchInstructorAlertsQuery = {
  __typename?: "Query"
  instructorAlerts: {
    __typename?: "InstructorAlertPaginatedList"
    totalCount: number
    edges: Array<{
      __typename?: "InstructorAlertEdge"
      cursor: string
      node: {
        __typename?: "InstructorAlert"
        id: string
        message: string
        severity: GQLInstructorAlertSeverity
        timestamp: number
        readMoreLink?: string | null
      }
    }>
  }
}

export type GQLCreateInstructorAlertMutationVariables = Exact<{
  input: GQLInstructorAlertCreateInput
}>

export type GQLCreateInstructorAlertMutation = {
  __typename?: "Mutation"
  instructor: {
    __typename?: "InstructorAlert"
    id: string
    message: string
    severity: GQLInstructorAlertSeverity
    timestamp: number
    readMoreLink?: string | null
  }
}

export type GQLUpdateInstructorAlertMutationVariables = Exact<{
  input: GQLInstructorAlertUpdateInput
}>

export type GQLUpdateInstructorAlertMutation = {
  __typename?: "Mutation"
  instructor: {
    __typename?: "InstructorAlert"
    id: string
    message: string
    severity: GQLInstructorAlertSeverity
    timestamp: number
    readMoreLink?: string | null
  }
}

export type GQLDeleteInstructorAlertMutationVariables = Exact<{
  input: GQLInstructorAlertDeleteInput
}>

export type GQLDeleteInstructorAlertMutation = {
  __typename?: "Mutation"
  id: string
}

export type GQLGetAllInstructorsSchedulesQueryVariables = Exact<{
  query: GQLInstructorSchedulesQuery
}>

export type GQLGetAllInstructorsSchedulesQuery = {
  __typename?: "Query"
  instructorsSchedules?: Array<{
    __typename?: "InstructorSchedule"
    id: string
    instructorId: string
    createdAt: number
    startDate: string
    endDate: string
    isApproved: boolean
    daysPerWeek: number
    updatedAt: number
    instructorName?: string | null
    managerName?: string | null
    dayTimesAvailable: Array<{
      __typename?: "ScheduleDayTimes"
      dayOfTheWeek: string
      startTime: string
      endTime: string
    }>
    timesOff?: Array<{
      __typename?: "InstructorTimeOff"
      id: string
      instructorId: string
      startDate: string
      endDate: string
    }> | null
  }> | null
}

export type GQLApprovedInstructorScheduleFragment = {
  __typename?: "ApprovedInstructorSchedule"
  id: string
  instructorId: string
  createdAt: number
  startDate: string
  endDate: string
  daysPerWeek: number
  updatedAt: number
  scheduleRequestId: string
  instructorName?: string | null
  managerName?: string | null
  dayTimesAvailable: Array<{
    __typename?: "ApprovedScheduleDayTimes"
    dayOfTheWeek: string
    startTime: string
    endTime: string
  }>
  timesOff?: Array<{
    __typename?: "InstructorTimeOff"
    id: string
    instructorId: string
    startDate: string
    endDate: string
  }> | null
}

export type GQLGetAllApprovedSchedulesQueryVariables = Exact<{
  query: GQLApprovedInstructorsSchedulesQuery
}>

export type GQLGetAllApprovedSchedulesQuery = {
  __typename?: "Query"
  approvedInstructorsSchedules?: Array<{
    __typename?: "ApprovedInstructorSchedule"
    id: string
    instructorId: string
    createdAt: number
    startDate: string
    endDate: string
    daysPerWeek: number
    updatedAt: number
    scheduleRequestId: string
    instructorName?: string | null
    managerName?: string | null
    dayTimesAvailable: Array<{
      __typename?: "ApprovedScheduleDayTimes"
      dayOfTheWeek: string
      startTime: string
      endTime: string
    }>
    timesOff?: Array<{
      __typename?: "InstructorTimeOff"
      id: string
      instructorId: string
      startDate: string
      endDate: string
    }> | null
  }> | null
}

export type GQLCheckAccountTransferQueryVariables = Exact<{
  fromId: Scalars["ID"]
  toId: Scalars["ID"]
}>

export type GQLCheckAccountTransferQuery = {
  __typename?: "Query"
  transferAccountCheck: {
    __typename?: "TransferAccountCheckResult"
    canTransfer: boolean
    errors: Array<string>
    warnings: Array<string>
  }
}

export type GQLTransferAccountMutationVariables = Exact<{
  fromId: Scalars["ID"]
  toId: Scalars["ID"]
  note: Scalars["String"]
}>

export type GQLTransferAccountMutation = {
  __typename?: "Mutation"
  transferAccount: boolean
}

export type GQLGetCourseForMoveQueryVariables = Exact<{
  id: Scalars["ID"]
}>

export type GQLGetCourseForMoveQuery = {
  __typename?: "Query"
  course: {
    __typename?: "OnlineCourseContent"
    id: string
    title: string
    stateLongName: string
  }
}

export type GQLMigrateCourseMutationVariables = Exact<{
  sourceStudentId: Scalars["ID"]
  destinationStudentId: Scalars["ID"]
  onlineCourseId: Scalars["ID"]
  reason: Scalars["String"]
}>

export type GQLMigrateCourseMutation = {
  __typename?: "Mutation"
  migrateStudentOnlineCourse: {
    __typename?: "StudentCourseMigration"
    onlineCourseId: string
  }
}

export type GQLUpdateParkingHubMutationMutationVariables = Exact<{
  id: Scalars["ID"]
  address: Scalars["String"]
  timeZone: Scalars["String"]
}>

export type GQLUpdateParkingHubMutationMutation = {
  __typename?: "Mutation"
  updateParkingHub: { __typename?: "ParkingHub"; id: string }
}

export type GQLPriceSchemeForBookingFragment = {
  __typename?: "PriceScheme"
  id: string
  name: string
  products: Array<{
    __typename?: "PriceSchemeProductLink"
    id: string
    name: string
    type: GQLProductType
    price: number
    productLinks: Array<{
      __typename?: "ProductLink"
      quantity: number
      productId: string
      productName: string
      product: {
        __typename?: "Product"
        id: string
        name: string
        type: GQLProductType
        price?: number | null
      }
    }>
  }>
}

export type GQLFetchPriceSchemeForAddressQueryVariables = Exact<{
  address: Scalars["String"]
}>

export type GQLFetchPriceSchemeForAddressQuery = {
  __typename?: "Query"
  priceSchemeForAddress?: {
    __typename?: "PriceScheme"
    id: string
    name: string
    products: Array<{
      __typename?: "PriceSchemeProductLink"
      id: string
      name: string
      type: GQLProductType
      price: number
      productLinks: Array<{
        __typename?: "ProductLink"
        quantity: number
        productId: string
        productName: string
        product: {
          __typename?: "Product"
          id: string
          name: string
          type: GQLProductType
          price?: number | null
        }
      }>
    }>
  } | null
}

export type GQLLectureReportFullFragment = {
  __typename?: "LectureReport"
  id: string
  totalTimeSeconds?: number | null
  testScore?: number | null
}

export type GQLFetchStudentOnlineCourseReportQueryVariables = Exact<{
  studentId: Scalars["ID"]
  courseId: Scalars["ID"]
}>

export type GQLFetchStudentOnlineCourseReportQuery = {
  __typename?: "Query"
  studentOnlineCourseReport: {
    __typename?: "StudentOnlineCourseReport"
    name: string
    dob: string
    address: string
    dmvPreRegNumber?: string | null
    startDate?: number | null
    completionDate?: number | null
    courseTotalTimeSeconds?: number | null
    lectureReports?: Array<{
      __typename?: "LectureReport"
      id: string
      totalTimeSeconds?: number | null
      testScore?: number | null
    }> | null
  }
}

export type GQLVehicleAvailsQueryVariables = Exact<{
  vehicleId: Scalars["ID"]
  startTime: Scalars["DateTime"]
}>

export type GQLVehicleAvailsQuery = {
  __typename?: "Query"
  vehicleAvails: Array<{
    __typename?: "VehicleAvail"
    availId: string
    startTime: number
    endTime: number
    vehicleName: string
    vehicleId: string
    instructor: {
      __typename?: "VehicleInstructorFields"
      id: string
      displayName: string
      timeZone: string
    }
  }>
}

export type GQLVehicleForTableFragment = {
  __typename?: "Vehicle"
  id: string
  number?: string | null
  make?: string | null
  model?: string | null
  plate?: string | null
  color?: string | null
  instructorIds?: Array<string> | null
  parkingHub?: string | null
  samsaraId?: string | null
  opsManagerUserId?: string | null
  state?: GQLUsStateId | null
}

export type GQLVehiclesPageQueryVariables = Exact<{
  first: Scalars["Int"]
  skip?: InputMaybe<Scalars["Int"]>
}>

export type GQLVehiclesPageQuery = {
  __typename?: "Query"
  vehicles?: {
    __typename?: "VehiclesPaginatedList"
    totalCount: number
    pageInfo: {
      __typename?: "PageInfo"
      hasNextPage: boolean
      endCursor?: string | null
    }
    edges: Array<{
      __typename?: "VehicleEdge"
      cursor: string
      node: {
        __typename?: "Vehicle"
        id: string
        number?: string | null
        make?: string | null
        model?: string | null
        plate?: string | null
        color?: string | null
        instructorIds?: Array<string> | null
        parkingHub?: string | null
        samsaraId?: string | null
        opsManagerUserId?: string | null
        state?: GQLUsStateId | null
      }
    }>
  } | null
}

export type GQLUpdateUserCourseMetaMutationVariables = Exact<{
  input: GQLUpdateStudentCourseMetaInput
}>

export type GQLUpdateUserCourseMetaMutation = {
  __typename?: "Mutation"
  updateStudentCourseMeta: boolean
}

export type GQLGetCourseForTimerQueryVariables = Exact<{
  id: Scalars["ID"]
}>

export type GQLGetCourseForTimerQuery = {
  __typename?: "Query"
  course: {
    __typename?: "OnlineCourseContent"
    id: string
    requiredHours: number
  }
}

export type GQLGetCourseDmvLinkQueryVariables = Exact<{
  courseId: Scalars["ID"]
}>

export type GQLGetCourseDmvLinkQuery = {
  __typename?: "Query"
  course: {
    __typename?: "OnlineCourseContent"
    id: string
    dmvPreRegistrationLink?: string | null
  }
}

export type GQLLectureContentImageFullFragment = {
  __typename?: "LectureContentImage"
  srcSet?: string | null
  webpSrcSet?: string | null
  sizes?: string | null
  src: string
  width: number
  height?: number | null
  aspectRatio?: number | null
  alt?: string | null
  title?: string | null
  bgColor?: string | null
  base64?: string | null
}

export type GQLLectureContentVideoFullFragment = {
  __typename?: "LectureContentVideo"
  url: string
  title: string
  thumbnailUrl: string
}

export type GQLLectureSingleImageBlockFullFragment = {
  __typename: "LectureSingleImageBlock"
  id: string
  type: GQLLectureContentBlockType
  image: {
    __typename?: "LectureContentImage"
    srcSet?: string | null
    webpSrcSet?: string | null
    sizes?: string | null
    src: string
    width: number
    height?: number | null
    aspectRatio?: number | null
    alt?: string | null
    title?: string | null
    bgColor?: string | null
    base64?: string | null
  }
}

export type GQLLectureSingleVideoBlockFullFragment = {
  __typename: "LectureSingleVideoBlock"
  id: string
  type: GQLLectureContentBlockType
  video?: {
    __typename?: "LectureContentVideo"
    url: string
    title: string
    thumbnailUrl: string
  } | null
}

export type GQLLectureTitleBlockFullFragment = {
  __typename: "LectureTitleBlock"
  id: string
  type: GQLLectureContentBlockType
  title: string
}

export type GQLLectureTwoColumnBlockFullFragment = {
  __typename: "LectureTwoColumnBlock"
  id: string
  type: GQLLectureContentBlockType
  column1: Array<
    | {
        __typename: "LectureSingleImageBlock"
        id: string
        type: GQLLectureContentBlockType
        image: {
          __typename?: "LectureContentImage"
          srcSet?: string | null
          webpSrcSet?: string | null
          sizes?: string | null
          src: string
          width: number
          height?: number | null
          aspectRatio?: number | null
          alt?: string | null
          title?: string | null
          bgColor?: string | null
          base64?: string | null
        }
      }
    | {
        __typename: "LectureTitleBlock"
        id: string
        type: GQLLectureContentBlockType
        title: string
      }
  >
  column2: { __typename?: "LectureBlockTextColumn"; structuredText: any }
}

type GQLLectureContentBlockFull_LectureSingleImageBlock_Fragment = {
  __typename: "LectureSingleImageBlock"
  id: string
  type: GQLLectureContentBlockType
  image: {
    __typename?: "LectureContentImage"
    srcSet?: string | null
    webpSrcSet?: string | null
    sizes?: string | null
    src: string
    width: number
    height?: number | null
    aspectRatio?: number | null
    alt?: string | null
    title?: string | null
    bgColor?: string | null
    base64?: string | null
  }
}

type GQLLectureContentBlockFull_LectureSingleVideoBlock_Fragment = {
  __typename: "LectureSingleVideoBlock"
  id: string
  type: GQLLectureContentBlockType
  video?: {
    __typename?: "LectureContentVideo"
    url: string
    title: string
    thumbnailUrl: string
  } | null
}

type GQLLectureContentBlockFull_LectureTitleBlock_Fragment = {
  __typename: "LectureTitleBlock"
  id: string
  type: GQLLectureContentBlockType
  title: string
}

type GQLLectureContentBlockFull_LectureTwoColumnBlock_Fragment = {
  __typename: "LectureTwoColumnBlock"
  id: string
  type: GQLLectureContentBlockType
  column1: Array<
    | {
        __typename: "LectureSingleImageBlock"
        id: string
        type: GQLLectureContentBlockType
        image: {
          __typename?: "LectureContentImage"
          srcSet?: string | null
          webpSrcSet?: string | null
          sizes?: string | null
          src: string
          width: number
          height?: number | null
          aspectRatio?: number | null
          alt?: string | null
          title?: string | null
          bgColor?: string | null
          base64?: string | null
        }
      }
    | {
        __typename: "LectureTitleBlock"
        id: string
        type: GQLLectureContentBlockType
        title: string
      }
  >
  column2: { __typename?: "LectureBlockTextColumn"; structuredText: any }
}

export type GQLLectureContentBlockFullFragment =
  | GQLLectureContentBlockFull_LectureSingleImageBlock_Fragment
  | GQLLectureContentBlockFull_LectureSingleVideoBlock_Fragment
  | GQLLectureContentBlockFull_LectureTitleBlock_Fragment
  | GQLLectureContentBlockFull_LectureTwoColumnBlock_Fragment

export type GQLLectureContentPartFullFragment = {
  __typename?: "LectureContentPart"
  id: string
  type: GQLLecturePartType
  structuredText?: any | null
  video?: {
    __typename?: "LectureContentVideo"
    url: string
    title: string
    thumbnailUrl: string
  } | null
  blocks: Array<
    | {
        __typename: "LectureSingleImageBlock"
        id: string
        type: GQLLectureContentBlockType
        image: {
          __typename?: "LectureContentImage"
          srcSet?: string | null
          webpSrcSet?: string | null
          sizes?: string | null
          src: string
          width: number
          height?: number | null
          aspectRatio?: number | null
          alt?: string | null
          title?: string | null
          bgColor?: string | null
          base64?: string | null
        }
      }
    | {
        __typename: "LectureSingleVideoBlock"
        id: string
        type: GQLLectureContentBlockType
        video?: {
          __typename?: "LectureContentVideo"
          url: string
          title: string
          thumbnailUrl: string
        } | null
      }
    | {
        __typename: "LectureTitleBlock"
        id: string
        type: GQLLectureContentBlockType
        title: string
      }
    | {
        __typename: "LectureTwoColumnBlock"
        id: string
        type: GQLLectureContentBlockType
        column1: Array<
          | {
              __typename: "LectureSingleImageBlock"
              id: string
              type: GQLLectureContentBlockType
              image: {
                __typename?: "LectureContentImage"
                srcSet?: string | null
                webpSrcSet?: string | null
                sizes?: string | null
                src: string
                width: number
                height?: number | null
                aspectRatio?: number | null
                alt?: string | null
                title?: string | null
                bgColor?: string | null
                base64?: string | null
              }
            }
          | {
              __typename: "LectureTitleBlock"
              id: string
              type: GQLLectureContentBlockType
              title: string
            }
        >
        column2: { __typename?: "LectureBlockTextColumn"; structuredText: any }
      }
  >
}

export type GQLLectureQuizQuestionFullFragment = {
  __typename?: "LectureQuizQuestion"
  id: string
  doShuffle: boolean
  question: string
  answer: string
  image?: {
    __typename?: "LectureContentImage"
    srcSet?: string | null
    webpSrcSet?: string | null
    sizes?: string | null
    src: string
    width: number
    height?: number | null
    aspectRatio?: number | null
    alt?: string | null
    title?: string | null
    bgColor?: string | null
    base64?: string | null
  } | null
  choices?: Array<{
    __typename?: "LectureQuestionChoice"
    id: string
    option: string
  }> | null
}

export type GQLLectureQuizPartFullFragment = {
  __typename?: "LectureQuizPart"
  id: string
  type: GQLLecturePartType
  questions: Array<{
    __typename?: "LectureQuizQuestion"
    id: string
    doShuffle: boolean
    question: string
    answer: string
    image?: {
      __typename?: "LectureContentImage"
      srcSet?: string | null
      webpSrcSet?: string | null
      sizes?: string | null
      src: string
      width: number
      height?: number | null
      aspectRatio?: number | null
      alt?: string | null
      title?: string | null
      bgColor?: string | null
      base64?: string | null
    } | null
    choices?: Array<{
      __typename?: "LectureQuestionChoice"
      id: string
      option: string
    }> | null
  }>
}

type GQLLecturePartFull_LectureContentPart_Fragment = {
  __typename?: "LectureContentPart"
  id: string
  type: GQLLecturePartType
  structuredText?: any | null
  video?: {
    __typename?: "LectureContentVideo"
    url: string
    title: string
    thumbnailUrl: string
  } | null
  blocks: Array<
    | {
        __typename: "LectureSingleImageBlock"
        id: string
        type: GQLLectureContentBlockType
        image: {
          __typename?: "LectureContentImage"
          srcSet?: string | null
          webpSrcSet?: string | null
          sizes?: string | null
          src: string
          width: number
          height?: number | null
          aspectRatio?: number | null
          alt?: string | null
          title?: string | null
          bgColor?: string | null
          base64?: string | null
        }
      }
    | {
        __typename: "LectureSingleVideoBlock"
        id: string
        type: GQLLectureContentBlockType
        video?: {
          __typename?: "LectureContentVideo"
          url: string
          title: string
          thumbnailUrl: string
        } | null
      }
    | {
        __typename: "LectureTitleBlock"
        id: string
        type: GQLLectureContentBlockType
        title: string
      }
    | {
        __typename: "LectureTwoColumnBlock"
        id: string
        type: GQLLectureContentBlockType
        column1: Array<
          | {
              __typename: "LectureSingleImageBlock"
              id: string
              type: GQLLectureContentBlockType
              image: {
                __typename?: "LectureContentImage"
                srcSet?: string | null
                webpSrcSet?: string | null
                sizes?: string | null
                src: string
                width: number
                height?: number | null
                aspectRatio?: number | null
                alt?: string | null
                title?: string | null
                bgColor?: string | null
                base64?: string | null
              }
            }
          | {
              __typename: "LectureTitleBlock"
              id: string
              type: GQLLectureContentBlockType
              title: string
            }
        >
        column2: { __typename?: "LectureBlockTextColumn"; structuredText: any }
      }
  >
}

type GQLLecturePartFull_LectureQuizPart_Fragment = {
  __typename?: "LectureQuizPart"
  id: string
  type: GQLLecturePartType
  questions: Array<{
    __typename?: "LectureQuizQuestion"
    id: string
    doShuffle: boolean
    question: string
    answer: string
    image?: {
      __typename?: "LectureContentImage"
      srcSet?: string | null
      webpSrcSet?: string | null
      sizes?: string | null
      src: string
      width: number
      height?: number | null
      aspectRatio?: number | null
      alt?: string | null
      title?: string | null
      bgColor?: string | null
      base64?: string | null
    } | null
    choices?: Array<{
      __typename?: "LectureQuestionChoice"
      id: string
      option: string
    }> | null
  }>
}

export type GQLLecturePartFullFragment =
  | GQLLecturePartFull_LectureContentPart_Fragment
  | GQLLecturePartFull_LectureQuizPart_Fragment

export type GQLGetLectureQueryVariables = Exact<{
  lectureId: Scalars["ID"]
  courseId: Scalars["ID"]
  seed: Scalars["String"]
}>

export type GQLGetLectureQuery = {
  __typename?: "Query"
  lecture: {
    __typename?: "CourseLecture"
    title: string
    content: {
      __typename?: "CourseLectureContent"
      parts: Array<
        | {
            __typename?: "LectureContentPart"
            id: string
            type: GQLLecturePartType
            structuredText?: any | null
            video?: {
              __typename?: "LectureContentVideo"
              url: string
              title: string
              thumbnailUrl: string
            } | null
            blocks: Array<
              | {
                  __typename: "LectureSingleImageBlock"
                  id: string
                  type: GQLLectureContentBlockType
                  image: {
                    __typename?: "LectureContentImage"
                    srcSet?: string | null
                    webpSrcSet?: string | null
                    sizes?: string | null
                    src: string
                    width: number
                    height?: number | null
                    aspectRatio?: number | null
                    alt?: string | null
                    title?: string | null
                    bgColor?: string | null
                    base64?: string | null
                  }
                }
              | {
                  __typename: "LectureSingleVideoBlock"
                  id: string
                  type: GQLLectureContentBlockType
                  video?: {
                    __typename?: "LectureContentVideo"
                    url: string
                    title: string
                    thumbnailUrl: string
                  } | null
                }
              | {
                  __typename: "LectureTitleBlock"
                  id: string
                  type: GQLLectureContentBlockType
                  title: string
                }
              | {
                  __typename: "LectureTwoColumnBlock"
                  id: string
                  type: GQLLectureContentBlockType
                  column1: Array<
                    | {
                        __typename: "LectureSingleImageBlock"
                        id: string
                        type: GQLLectureContentBlockType
                        image: {
                          __typename?: "LectureContentImage"
                          srcSet?: string | null
                          webpSrcSet?: string | null
                          sizes?: string | null
                          src: string
                          width: number
                          height?: number | null
                          aspectRatio?: number | null
                          alt?: string | null
                          title?: string | null
                          bgColor?: string | null
                          base64?: string | null
                        }
                      }
                    | {
                        __typename: "LectureTitleBlock"
                        id: string
                        type: GQLLectureContentBlockType
                        title: string
                      }
                  >
                  column2: {
                    __typename?: "LectureBlockTextColumn"
                    structuredText: any
                  }
                }
            >
          }
        | {
            __typename?: "LectureQuizPart"
            id: string
            type: GQLLecturePartType
            questions: Array<{
              __typename?: "LectureQuizQuestion"
              id: string
              doShuffle: boolean
              question: string
              answer: string
              image?: {
                __typename?: "LectureContentImage"
                srcSet?: string | null
                webpSrcSet?: string | null
                sizes?: string | null
                src: string
                width: number
                height?: number | null
                aspectRatio?: number | null
                alt?: string | null
                title?: string | null
                bgColor?: string | null
                base64?: string | null
              } | null
              choices?: Array<{
                __typename?: "LectureQuestionChoice"
                id: string
                option: string
              }> | null
            }>
          }
      >
    }
  }
}

export type GQLGetLectureForNavigationQueryVariables = Exact<{
  lectureId: Scalars["ID"]
  courseId: Scalars["ID"]
  studentId: Scalars["ID"]
}>

export type GQLGetLectureForNavigationQuery = {
  __typename?: "Query"
  lecture: {
    __typename?: "CourseLecture"
    includesAQuiz: boolean
    timeSpentByStudent: number
    content: { __typename?: "CourseLectureContent"; minimumDuration: number }
  }
}

export type GQLStudentOnlineCourseTimeProgressQueryVariables = Exact<{
  studentId: Scalars["ID"]
  courseId: Scalars["ID"]
}>

export type GQLStudentOnlineCourseTimeProgressQuery = {
  __typename?: "Query"
  studentOnlineCourseTimeProgress: {
    __typename?: "StudentOnlineCourseTimeProgress"
    courseTotalTime: number
    totalTimePerDay?: Array<{
      __typename?: "LectureTimeDaily"
      date: string
      totalTimeSeconds: number
    }> | null
  }
}

export type GQLCourseForSummaryFragment = {
  __typename?: "OnlineCourseContent"
  id: string
  title: string
  slug: string
  courseTypeId?: string | null
  courseAgeMinimum?: number | null
  isClassroomCourse: boolean
  isExternalCourse: boolean
  requiresNotarizedForm?: boolean | null
  requiresDmvPreRegistration?: boolean | null
  hasControlNumber: boolean
  link?: string | null
  completionFlow: Array<string>
}

export type GQLGetCoursesForSummaryQueryVariables = Exact<{
  courseIds: Array<Scalars["ID"]> | Scalars["ID"]
}>

export type GQLGetCoursesForSummaryQuery = {
  __typename?: "Query"
  coursesWithIds: Array<{
    __typename?: "OnlineCourseContent"
    id: string
    title: string
    slug: string
    courseTypeId?: string | null
    courseAgeMinimum?: number | null
    isClassroomCourse: boolean
    isExternalCourse: boolean
    requiresNotarizedForm?: boolean | null
    requiresDmvPreRegistration?: boolean | null
    hasControlNumber: boolean
    link?: string | null
    completionFlow: Array<string>
  }>
}

export type GQLCoursePrepQuizQuestionFragment = {
  __typename?: "LectureQuizQuestion"
  question: string
  id: string
  answer: string
  doShuffle: boolean
  image?: {
    __typename?: "LectureContentImage"
    src: string
    width: number
    height?: number | null
    alt?: string | null
  } | null
  choices?: Array<{
    __typename?: "LectureQuestionChoice"
    id: string
    option: string
  }> | null
}

export type GQLCoursePrepTestChapterViewFragment = {
  __typename?: "CoursePrepTestChapter"
  id: string
  title: string
  questions: Array<{
    __typename?: "LectureQuizQuestion"
    question: string
    id: string
    answer: string
    doShuffle: boolean
    image?: {
      __typename?: "LectureContentImage"
      src: string
      width: number
      height?: number | null
      alt?: string | null
    } | null
    choices?: Array<{
      __typename?: "LectureQuestionChoice"
      id: string
      option: string
    }> | null
  }>
}

export type GQLGetPrepTestsQueryVariables = Exact<{
  courseId: Scalars["ID"]
}>

export type GQLGetPrepTestsQuery = {
  __typename?: "Query"
  coursePrepTest: {
    __typename?: "CoursePrepTest"
    title: string
    chapters: Array<{
      __typename?: "CoursePrepTestChapter"
      id: string
      title: string
      questions: Array<{
        __typename?: "LectureQuizQuestion"
        question: string
        id: string
        answer: string
        doShuffle: boolean
        image?: {
          __typename?: "LectureContentImage"
          src: string
          width: number
          height?: number | null
          alt?: string | null
        } | null
        choices?: Array<{
          __typename?: "LectureQuestionChoice"
          id: string
          option: string
        }> | null
      }>
    }>
  }
}

export type GQLGetPrepTestsScoresQueryVariables = Exact<{
  courseId: Scalars["ID"]
}>

export type GQLGetPrepTestsScoresQuery = {
  __typename?: "Query"
  coursePrepTestScores: Array<{
    __typename?: "CoursePrepTestChapterScores"
    id: string
    questionScores: Array<{
      __typename?: "LectureQuizQuestionPrepTestScore"
      id: string
      isCorrect: boolean
    }>
  }>
}

export type GQLSubmitPrepTestScoresMutationVariables = Exact<{
  input: GQLSubmitCoursePrepTestChapterScoresInput
}>

export type GQLSubmitPrepTestScoresMutation = {
  __typename?: "Mutation"
  submitCoursePrepTestChapterScores: boolean
}

export type GQLAccountTagViewFragment = {
  __typename?: "AccountTag"
  name: string
  count: number
}

export type GQLSearchAccountTagsQueryVariables = Exact<{
  query: Scalars["String"]
}>

export type GQLSearchAccountTagsQuery = {
  __typename?: "Query"
  tags: Array<{ __typename?: "AccountTag"; name: string; count: number }>
}

export type GQLUpdateStudentTagsMutationVariables = Exact<{
  student: GQLStudentInput
}>

export type GQLUpdateStudentTagsMutation = {
  __typename?: "Mutation"
  student: { __typename?: "Student"; id: string }
}

export type GQLProductForViewFragment = {
  __typename?: "Product"
  id: string
  name: string
  description?: string | null
  type: GQLProductType
  products?: Array<{
    __typename?: "ProductLink"
    quantity: number
    product: {
      __typename?: "Product"
      id: string
      name: string
      description?: string | null
      type: GQLProductType
    }
  }> | null
}

export type GQLListProductQueryVariables = Exact<{
  productsQuery?: InputMaybe<GQLProductsQuery>
  first: Scalars["Int"]
}>

export type GQLListProductQuery = {
  __typename?: "Query"
  products: {
    __typename?: "ProductsPaginatedList"
    edges: Array<{
      __typename?: "ProductEdge"
      node: {
        __typename?: "Product"
        id: string
        name: string
        description?: string | null
        type: GQLProductType
        products?: Array<{
          __typename?: "ProductLink"
          quantity: number
          product: {
            __typename?: "Product"
            id: string
            name: string
            description?: string | null
            type: GQLProductType
          }
        }> | null
      }
    }>
  }
}

export type GQLCartItemPackageWithPricesFragment = {
  __typename?: "ProductPackageWithPrice"
  id: string
  hasCourse: boolean
  hasCertificateShipping?: boolean | null
  banner?: string | null
  description: string
  extras?: string | null
  hasLessons: boolean
  state: string
  title: string
  msrp: number
  price: number
  discountAmount: number
  couponDiscountAmount?: number | null
  courseId?: string | null
  courseType?: string | null
  didApplyCoupon?: boolean | null
  lessonPackage?: {
    __typename?: "LessonPackage"
    id: string
    totalMinutes: number
    drivingMinutes: number
    observationMinutes: number
    numLessons: number
    numRoadTests: number
    isSharedLessons?: boolean | null
  } | null
}

export type GQLCartDetailsFragment = {
  __typename?: "Cart"
  id: string
  isOrderComplete: boolean
  createdAt: number
  updatedAt: number
  bookedSlotIds?: Array<string> | null
  paymentStatus?: string | null
  items: Array<{
    __typename?: "ProductPackageWithPrice"
    id: string
    hasCourse: boolean
    hasCertificateShipping?: boolean | null
    banner?: string | null
    description: string
    extras?: string | null
    hasLessons: boolean
    state: string
    title: string
    msrp: number
    price: number
    discountAmount: number
    couponDiscountAmount?: number | null
    courseId?: string | null
    courseType?: string | null
    didApplyCoupon?: boolean | null
    lessonPackage?: {
      __typename?: "LessonPackage"
      id: string
      totalMinutes: number
      drivingMinutes: number
      observationMinutes: number
      numLessons: number
      numRoadTests: number
      isSharedLessons?: boolean | null
    } | null
  }>
  coupon?:
    | {
        __typename?: "CourseCoupon"
        id: string
        code: string
        discount: number
        title: string
        courseId?: string | null
        state: GQLUsStateId
        isDailyCoupon: boolean
        type: GQLCouponType
        expiration?: number | null
      }
    | {
        __typename?: "LessonCoupon"
        id: string
        code: string
        discount: number
        type: GQLCouponType
        title: string
        expiration?: number | null
        displayInLandingPages?: boolean | null
        onlyFirstPurchase: boolean
        regions: Array<{ __typename?: "Region"; id: string }>
      }
    | {
        __typename?: "RoadTestCoupon"
        id: string
        code: string
        discount: number
        type: GQLCouponType
        title: string
        expiration?: number | null
        displayInLandingPages?: boolean | null
        onlyFirstPurchase: boolean
        regions: Array<{ __typename?: "Region"; id: string }>
      }
    | null
}

export type GQLFetchCartQueryVariables = Exact<{
  cartId: Scalars["ID"]
}>

export type GQLFetchCartQuery = {
  __typename?: "Query"
  cart: {
    __typename?: "Cart"
    id: string
    isOrderComplete: boolean
    createdAt: number
    updatedAt: number
    bookedSlotIds?: Array<string> | null
    paymentStatus?: string | null
    items: Array<{
      __typename?: "ProductPackageWithPrice"
      id: string
      hasCourse: boolean
      hasCertificateShipping?: boolean | null
      banner?: string | null
      description: string
      extras?: string | null
      hasLessons: boolean
      state: string
      title: string
      msrp: number
      price: number
      discountAmount: number
      couponDiscountAmount?: number | null
      courseId?: string | null
      courseType?: string | null
      didApplyCoupon?: boolean | null
      lessonPackage?: {
        __typename?: "LessonPackage"
        id: string
        totalMinutes: number
        drivingMinutes: number
        observationMinutes: number
        numLessons: number
        numRoadTests: number
        isSharedLessons?: boolean | null
      } | null
    }>
    coupon?:
      | {
          __typename?: "CourseCoupon"
          id: string
          code: string
          discount: number
          title: string
          courseId?: string | null
          state: GQLUsStateId
          isDailyCoupon: boolean
          type: GQLCouponType
          expiration?: number | null
        }
      | {
          __typename?: "LessonCoupon"
          id: string
          code: string
          discount: number
          type: GQLCouponType
          title: string
          expiration?: number | null
          displayInLandingPages?: boolean | null
          onlyFirstPurchase: boolean
          regions: Array<{ __typename?: "Region"; id: string }>
        }
      | {
          __typename?: "RoadTestCoupon"
          id: string
          code: string
          discount: number
          type: GQLCouponType
          title: string
          expiration?: number | null
          displayInLandingPages?: boolean | null
          onlyFirstPurchase: boolean
          regions: Array<{ __typename?: "Region"; id: string }>
        }
      | null
  }
}

export type GQLCreateCartMutationVariables = Exact<{
  input: GQLCartCreateInput
}>

export type GQLCreateCartMutation = {
  __typename?: "Mutation"
  createCart: {
    __typename?: "Cart"
    id: string
    isOrderComplete: boolean
    createdAt: number
    updatedAt: number
    bookedSlotIds?: Array<string> | null
    paymentStatus?: string | null
    items: Array<{
      __typename?: "ProductPackageWithPrice"
      id: string
      hasCourse: boolean
      hasCertificateShipping?: boolean | null
      banner?: string | null
      description: string
      extras?: string | null
      hasLessons: boolean
      state: string
      title: string
      msrp: number
      price: number
      discountAmount: number
      couponDiscountAmount?: number | null
      courseId?: string | null
      courseType?: string | null
      didApplyCoupon?: boolean | null
      lessonPackage?: {
        __typename?: "LessonPackage"
        id: string
        totalMinutes: number
        drivingMinutes: number
        observationMinutes: number
        numLessons: number
        numRoadTests: number
        isSharedLessons?: boolean | null
      } | null
    }>
    coupon?:
      | {
          __typename?: "CourseCoupon"
          id: string
          code: string
          discount: number
          title: string
          courseId?: string | null
          state: GQLUsStateId
          isDailyCoupon: boolean
          type: GQLCouponType
          expiration?: number | null
        }
      | {
          __typename?: "LessonCoupon"
          id: string
          code: string
          discount: number
          type: GQLCouponType
          title: string
          expiration?: number | null
          displayInLandingPages?: boolean | null
          onlyFirstPurchase: boolean
          regions: Array<{ __typename?: "Region"; id: string }>
        }
      | {
          __typename?: "RoadTestCoupon"
          id: string
          code: string
          discount: number
          type: GQLCouponType
          title: string
          expiration?: number | null
          displayInLandingPages?: boolean | null
          onlyFirstPurchase: boolean
          regions: Array<{ __typename?: "Region"; id: string }>
        }
      | null
  }
}

export type GQLAddToCartMutationVariables = Exact<{
  input: GQLIdLessonPackageInput
}>

export type GQLAddToCartMutation = {
  __typename?: "Mutation"
  addToCart: {
    __typename?: "Cart"
    id: string
    isOrderComplete: boolean
    createdAt: number
    updatedAt: number
    bookedSlotIds?: Array<string> | null
    paymentStatus?: string | null
    items: Array<{
      __typename?: "ProductPackageWithPrice"
      id: string
      hasCourse: boolean
      hasCertificateShipping?: boolean | null
      banner?: string | null
      description: string
      extras?: string | null
      hasLessons: boolean
      state: string
      title: string
      msrp: number
      price: number
      discountAmount: number
      couponDiscountAmount?: number | null
      courseId?: string | null
      courseType?: string | null
      didApplyCoupon?: boolean | null
      lessonPackage?: {
        __typename?: "LessonPackage"
        id: string
        totalMinutes: number
        drivingMinutes: number
        observationMinutes: number
        numLessons: number
        numRoadTests: number
        isSharedLessons?: boolean | null
      } | null
    }>
    coupon?:
      | {
          __typename?: "CourseCoupon"
          id: string
          code: string
          discount: number
          title: string
          courseId?: string | null
          state: GQLUsStateId
          isDailyCoupon: boolean
          type: GQLCouponType
          expiration?: number | null
        }
      | {
          __typename?: "LessonCoupon"
          id: string
          code: string
          discount: number
          type: GQLCouponType
          title: string
          expiration?: number | null
          displayInLandingPages?: boolean | null
          onlyFirstPurchase: boolean
          regions: Array<{ __typename?: "Region"; id: string }>
        }
      | {
          __typename?: "RoadTestCoupon"
          id: string
          code: string
          discount: number
          type: GQLCouponType
          title: string
          expiration?: number | null
          displayInLandingPages?: boolean | null
          onlyFirstPurchase: boolean
          regions: Array<{ __typename?: "Region"; id: string }>
        }
      | null
  }
}

export type GQLClearPackagesMutationVariables = Exact<{
  input: GQLCartInput
}>

export type GQLClearPackagesMutation = {
  __typename?: "Mutation"
  clearPackages: {
    __typename?: "Cart"
    id: string
    isOrderComplete: boolean
    createdAt: number
    updatedAt: number
    bookedSlotIds?: Array<string> | null
    paymentStatus?: string | null
    items: Array<{
      __typename?: "ProductPackageWithPrice"
      id: string
      hasCourse: boolean
      hasCertificateShipping?: boolean | null
      banner?: string | null
      description: string
      extras?: string | null
      hasLessons: boolean
      state: string
      title: string
      msrp: number
      price: number
      discountAmount: number
      couponDiscountAmount?: number | null
      courseId?: string | null
      courseType?: string | null
      didApplyCoupon?: boolean | null
      lessonPackage?: {
        __typename?: "LessonPackage"
        id: string
        totalMinutes: number
        drivingMinutes: number
        observationMinutes: number
        numLessons: number
        numRoadTests: number
        isSharedLessons?: boolean | null
      } | null
    }>
    coupon?:
      | {
          __typename?: "CourseCoupon"
          id: string
          code: string
          discount: number
          title: string
          courseId?: string | null
          state: GQLUsStateId
          isDailyCoupon: boolean
          type: GQLCouponType
          expiration?: number | null
        }
      | {
          __typename?: "LessonCoupon"
          id: string
          code: string
          discount: number
          type: GQLCouponType
          title: string
          expiration?: number | null
          displayInLandingPages?: boolean | null
          onlyFirstPurchase: boolean
          regions: Array<{ __typename?: "Region"; id: string }>
        }
      | {
          __typename?: "RoadTestCoupon"
          id: string
          code: string
          discount: number
          type: GQLCouponType
          title: string
          expiration?: number | null
          displayInLandingPages?: boolean | null
          onlyFirstPurchase: boolean
          regions: Array<{ __typename?: "Region"; id: string }>
        }
      | null
  }
}

export type GQLCompleteOrderWithSlotsMutationVariables = Exact<{
  input: GQLCompleteOrderInput
}>

export type GQLCompleteOrderWithSlotsMutation = {
  __typename?: "Mutation"
  completeOrderWithSlots: {
    __typename?: "Cart"
    id: string
    isOrderComplete: boolean
    createdAt: number
    updatedAt: number
    bookedSlotIds?: Array<string> | null
    paymentStatus?: string | null
    items: Array<{
      __typename?: "ProductPackageWithPrice"
      id: string
      hasCourse: boolean
      hasCertificateShipping?: boolean | null
      banner?: string | null
      description: string
      extras?: string | null
      hasLessons: boolean
      state: string
      title: string
      msrp: number
      price: number
      discountAmount: number
      couponDiscountAmount?: number | null
      courseId?: string | null
      courseType?: string | null
      didApplyCoupon?: boolean | null
      lessonPackage?: {
        __typename?: "LessonPackage"
        id: string
        totalMinutes: number
        drivingMinutes: number
        observationMinutes: number
        numLessons: number
        numRoadTests: number
        isSharedLessons?: boolean | null
      } | null
    }>
    coupon?:
      | {
          __typename?: "CourseCoupon"
          id: string
          code: string
          discount: number
          title: string
          courseId?: string | null
          state: GQLUsStateId
          isDailyCoupon: boolean
          type: GQLCouponType
          expiration?: number | null
        }
      | {
          __typename?: "LessonCoupon"
          id: string
          code: string
          discount: number
          type: GQLCouponType
          title: string
          expiration?: number | null
          displayInLandingPages?: boolean | null
          onlyFirstPurchase: boolean
          regions: Array<{ __typename?: "Region"; id: string }>
        }
      | {
          __typename?: "RoadTestCoupon"
          id: string
          code: string
          discount: number
          type: GQLCouponType
          title: string
          expiration?: number | null
          displayInLandingPages?: boolean | null
          onlyFirstPurchase: boolean
          regions: Array<{ __typename?: "Region"; id: string }>
        }
      | null
  }
}

export type GQLSetStudentIdMutationVariables = Exact<{
  input: GQLSetStudentInput
}>

export type GQLSetStudentIdMutation = {
  __typename?: "Mutation"
  setStudentId: {
    __typename?: "Cart"
    id: string
    isOrderComplete: boolean
    createdAt: number
    updatedAt: number
    bookedSlotIds?: Array<string> | null
    paymentStatus?: string | null
    items: Array<{
      __typename?: "ProductPackageWithPrice"
      id: string
      hasCourse: boolean
      hasCertificateShipping?: boolean | null
      banner?: string | null
      description: string
      extras?: string | null
      hasLessons: boolean
      state: string
      title: string
      msrp: number
      price: number
      discountAmount: number
      couponDiscountAmount?: number | null
      courseId?: string | null
      courseType?: string | null
      didApplyCoupon?: boolean | null
      lessonPackage?: {
        __typename?: "LessonPackage"
        id: string
        totalMinutes: number
        drivingMinutes: number
        observationMinutes: number
        numLessons: number
        numRoadTests: number
        isSharedLessons?: boolean | null
      } | null
    }>
    coupon?:
      | {
          __typename?: "CourseCoupon"
          id: string
          code: string
          discount: number
          title: string
          courseId?: string | null
          state: GQLUsStateId
          isDailyCoupon: boolean
          type: GQLCouponType
          expiration?: number | null
        }
      | {
          __typename?: "LessonCoupon"
          id: string
          code: string
          discount: number
          type: GQLCouponType
          title: string
          expiration?: number | null
          displayInLandingPages?: boolean | null
          onlyFirstPurchase: boolean
          regions: Array<{ __typename?: "Region"; id: string }>
        }
      | {
          __typename?: "RoadTestCoupon"
          id: string
          code: string
          discount: number
          type: GQLCouponType
          title: string
          expiration?: number | null
          displayInLandingPages?: boolean | null
          onlyFirstPurchase: boolean
          regions: Array<{ __typename?: "Region"; id: string }>
        }
      | null
  }
}

export type GQLSetCouponMutationVariables = Exact<{
  input: GQLSetCouponInput
}>

export type GQLSetCouponMutation = {
  __typename?: "Mutation"
  setCoupon: {
    __typename?: "Cart"
    id: string
    isOrderComplete: boolean
    createdAt: number
    updatedAt: number
    bookedSlotIds?: Array<string> | null
    paymentStatus?: string | null
    items: Array<{
      __typename?: "ProductPackageWithPrice"
      id: string
      hasCourse: boolean
      hasCertificateShipping?: boolean | null
      banner?: string | null
      description: string
      extras?: string | null
      hasLessons: boolean
      state: string
      title: string
      msrp: number
      price: number
      discountAmount: number
      couponDiscountAmount?: number | null
      courseId?: string | null
      courseType?: string | null
      didApplyCoupon?: boolean | null
      lessonPackage?: {
        __typename?: "LessonPackage"
        id: string
        totalMinutes: number
        drivingMinutes: number
        observationMinutes: number
        numLessons: number
        numRoadTests: number
        isSharedLessons?: boolean | null
      } | null
    }>
    coupon?:
      | {
          __typename?: "CourseCoupon"
          id: string
          code: string
          discount: number
          title: string
          courseId?: string | null
          state: GQLUsStateId
          isDailyCoupon: boolean
          type: GQLCouponType
          expiration?: number | null
        }
      | {
          __typename?: "LessonCoupon"
          id: string
          code: string
          discount: number
          type: GQLCouponType
          title: string
          expiration?: number | null
          displayInLandingPages?: boolean | null
          onlyFirstPurchase: boolean
          regions: Array<{ __typename?: "Region"; id: string }>
        }
      | {
          __typename?: "RoadTestCoupon"
          id: string
          code: string
          discount: number
          type: GQLCouponType
          title: string
          expiration?: number | null
          displayInLandingPages?: boolean | null
          onlyFirstPurchase: boolean
          regions: Array<{ __typename?: "Region"; id: string }>
        }
      | null
  }
}

export type GQLClearCouponMutationVariables = Exact<{
  input: GQLCartInput
}>

export type GQLClearCouponMutation = {
  __typename?: "Mutation"
  clearCoupon: {
    __typename?: "Cart"
    id: string
    isOrderComplete: boolean
    createdAt: number
    updatedAt: number
    bookedSlotIds?: Array<string> | null
    paymentStatus?: string | null
    items: Array<{
      __typename?: "ProductPackageWithPrice"
      id: string
      hasCourse: boolean
      hasCertificateShipping?: boolean | null
      banner?: string | null
      description: string
      extras?: string | null
      hasLessons: boolean
      state: string
      title: string
      msrp: number
      price: number
      discountAmount: number
      couponDiscountAmount?: number | null
      courseId?: string | null
      courseType?: string | null
      didApplyCoupon?: boolean | null
      lessonPackage?: {
        __typename?: "LessonPackage"
        id: string
        totalMinutes: number
        drivingMinutes: number
        observationMinutes: number
        numLessons: number
        numRoadTests: number
        isSharedLessons?: boolean | null
      } | null
    }>
    coupon?:
      | {
          __typename?: "CourseCoupon"
          id: string
          code: string
          discount: number
          title: string
          courseId?: string | null
          state: GQLUsStateId
          isDailyCoupon: boolean
          type: GQLCouponType
          expiration?: number | null
        }
      | {
          __typename?: "LessonCoupon"
          id: string
          code: string
          discount: number
          type: GQLCouponType
          title: string
          expiration?: number | null
          displayInLandingPages?: boolean | null
          onlyFirstPurchase: boolean
          regions: Array<{ __typename?: "Region"; id: string }>
        }
      | {
          __typename?: "RoadTestCoupon"
          id: string
          code: string
          discount: number
          type: GQLCouponType
          title: string
          expiration?: number | null
          displayInLandingPages?: boolean | null
          onlyFirstPurchase: boolean
          regions: Array<{ __typename?: "Region"; id: string }>
        }
      | null
  }
}

export type GQLCourseForConfirmationFragment = {
  __typename?: "OnlineCourseContent"
  id: string
  stateShortName: string
  link?: string | null
  isExternalCourse: boolean
  isClassroomCourse: boolean
  courseAgeMinimum?: number | null
  slug: string
}

export type GQLGetCourseForConfirmationQueryVariables = Exact<{
  courseId: Scalars["ID"]
}>

export type GQLGetCourseForConfirmationQuery = {
  __typename?: "Query"
  course: {
    __typename?: "OnlineCourseContent"
    id: string
    stateShortName: string
    link?: string | null
    isExternalCourse: boolean
    isClassroomCourse: boolean
    courseAgeMinimum?: number | null
    slug: string
  }
}

export type GQLGetCourseIdByIdQueryVariables = Exact<{
  courseId: Scalars["ID"]
}>

export type GQLGetCourseIdByIdQuery = {
  __typename?: "Query"
  course: { __typename?: "OnlineCourseContent"; id: string }
}

export type GQLFetchCourseForCheckoutQueryVariables = Exact<{
  courseId: Scalars["ID"]
}>

export type GQLFetchCourseForCheckoutQuery = {
  __typename?: "Query"
  courseInfo: {
    __typename?: "OnlineCourseContent"
    id: string
    courseAgeMinimum?: number | null
    isClassroomCourse: boolean
    stateShortName: string
  }
}

export type GQLGetCourseForDigitalCerticateQueryVariables = Exact<{
  id: Scalars["ID"]
}>

export type GQLGetCourseForDigitalCerticateQuery = {
  __typename?: "Query"
  course: {
    __typename?: "OnlineCourseContent"
    id: string
    title: string
    hasControlNumber: boolean
    requiresNotarizedForm?: boolean | null
    isClassroomCourse: boolean
    anvilPdfIds: {
      __typename?: "AnvilPdfIds"
      student?: string | null
      admin?: string | null
      insurance?: string | null
    }
  }
}

export type GQLGetCourseForInstructionsQueryVariables = Exact<{
  id: Scalars["ID"]
}>

export type GQLGetCourseForInstructionsQuery = {
  __typename?: "Query"
  course: {
    __typename?: "OnlineCourseContent"
    id: string
    slug: string
    instructionsText?: string | null
  }
}

export type GQLGetCourseForVerificationQueryVariables = Exact<{
  id: Scalars["ID"]
}>

export type GQLGetCourseForVerificationQuery = {
  __typename?: "Query"
  course: {
    __typename?: "OnlineCourseContent"
    id: string
    stateShortName: string
    branchLicenseNumber?: string | null
    requiresSampleCertificateCheck?: boolean | null
    hasControlNumber: boolean
    verifyAddressText?: string | null
    anvilPdfIds: { __typename?: "AnvilPdfIds"; student?: string | null }
  }
}

export type GQLProductPackageFieldsFragment = {
  __typename?: "ProductPackage"
  id: string
  title: string
  banner?: string | null
  description: string
  courseId?: string | null
  courseType?: string | null
  hasCourse: boolean
  hasLessons: boolean
  isHomePickup?: boolean | null
  state: string
  doHighlight?: boolean | null
  hasCertificateShipping?: boolean | null
  tags?: Array<string | null> | null
  lessonPackage?: {
    __typename?: "LessonPackage"
    id: string
    totalMinutes: number
    drivingMinutes: number
    observationMinutes: number
    numLessons: number
    numRoadTests: number
    isSharedLessons?: boolean | null
  } | null
}

export type GQLGetPackagesQueryVariables = Exact<{
  state: Scalars["String"]
  courseType?: InputMaybe<Scalars["String"]>
}>

export type GQLGetPackagesQuery = {
  __typename?: "Query"
  packages: Array<{
    __typename?: "ProductPackage"
    id: string
    title: string
    banner?: string | null
    description: string
    courseId?: string | null
    courseType?: string | null
    hasCourse: boolean
    hasLessons: boolean
    isHomePickup?: boolean | null
    state: string
    doHighlight?: boolean | null
    hasCertificateShipping?: boolean | null
    tags?: Array<string | null> | null
    lessonPackage?: {
      __typename?: "LessonPackage"
      id: string
      totalMinutes: number
      drivingMinutes: number
      observationMinutes: number
      numLessons: number
      numRoadTests: number
      isSharedLessons?: boolean | null
    } | null
  } | null>
}

export type GQLCheckCourseExistsQueryVariables = Exact<{
  id: Scalars["ID"]
}>

export type GQLCheckCourseExistsQuery = {
  __typename?: "Query"
  course: { __typename?: "OnlineCourseContent"; id: string }
}

export type GQLUpdateStudentCourseSessionMutationVariables = Exact<{
  input: GQLUpdateCourseSessionInput
}>

export type GQLUpdateStudentCourseSessionMutation = {
  __typename?: "Mutation"
  updateStudentCourseSession: {
    __typename?: "FirebaseCourseSessionTime"
    sessionStart?: number | null
    sessionEnd?: number | null
  }
}

export type GQLUpdateStudentCourseLectureSessionMutationVariables = Exact<{
  input: GQLUpdateCourseSessionInput
}>

export type GQLUpdateStudentCourseLectureSessionMutation = {
  __typename?: "Mutation"
  updateStudentCourseLectureSession: {
    __typename?: "FirebaseCourseSessionTime"
    sessionStart?: number | null
    sessionEnd?: number | null
  }
}

export type GQLBasicLectureFragment = {
  __typename?: "CourseLecture"
  id: string
  title: string
  slug: string
  includesAQuiz: boolean
}

export type GQLFullCourseFragment = {
  __typename?: "OnlineCourseContent"
  id: string
  slug: string
  title: string
  stateShortName: string
  stateLongName: string
  datoStateId: string
  courseTypeId?: string | null
  lectureIds: Array<string>
  numTests: number
  numLectures: number
  minPercentCorrect: number
  notarizedFormLink?: string | null
  requireOrder?: boolean | null
  requiredHours: number
  requiresDmvPreRegistration?: boolean | null
  requiresNotarizedForm?: boolean | null
  requiresSampleCertificateCheck?: boolean | null
  anvilPdfTemplateId?: string | null
  certificateCourseName?: string | null
  certificateIncludeMeetsRequirements?: boolean | null
  certificateInstructor?: string | null
  challengeRequired?: boolean | null
  completionFlow: Array<string>
  courseAgeMinimum?: number | null
  dailyTimeLimit?: number | null
  dmvPreRegistrationLink?: string | null
  externalCourseId?: string | null
  failTwiceReview: boolean
  hasCertificate: boolean
  hasControlNumber: boolean
  hasDrivingLessons: boolean
  hasShipping: boolean
  hasTimer: boolean
  hasWeakSecurityMeasures: boolean
  inactivityCheck: boolean
  instructionsText?: string | null
  isClassroomCourse: boolean
  isEnrollmentOpen: boolean
  isExternalCourse: boolean
  isGuideRequired: boolean
  licenseNumber?: string | null
  link?: string | null
  timerLockedLectureId?: string | null
  verifyAddressText?: string | null
  topics: Array<{
    __typename?: "CourseTopic"
    id: string
    slug: string
    title: string
    lectures: Array<{
      __typename?: "CourseLecture"
      id: string
      title: string
      slug: string
      includesAQuiz: boolean
    }>
  }>
  testLectures: Array<{
    __typename?: "CourseTestLecture"
    id: string
    title: string
  }>
}

export type GQLFetchCourseByIdQueryVariables = Exact<{
  id: Scalars["ID"]
}>

export type GQLFetchCourseByIdQuery = {
  __typename?: "Query"
  courseById: {
    __typename?: "OnlineCourseContent"
    id: string
    slug: string
    title: string
    stateShortName: string
    stateLongName: string
    datoStateId: string
    courseTypeId?: string | null
    lectureIds: Array<string>
    numTests: number
    numLectures: number
    minPercentCorrect: number
    notarizedFormLink?: string | null
    requireOrder?: boolean | null
    requiredHours: number
    requiresDmvPreRegistration?: boolean | null
    requiresNotarizedForm?: boolean | null
    requiresSampleCertificateCheck?: boolean | null
    anvilPdfTemplateId?: string | null
    certificateCourseName?: string | null
    certificateIncludeMeetsRequirements?: boolean | null
    certificateInstructor?: string | null
    challengeRequired?: boolean | null
    completionFlow: Array<string>
    courseAgeMinimum?: number | null
    dailyTimeLimit?: number | null
    dmvPreRegistrationLink?: string | null
    externalCourseId?: string | null
    failTwiceReview: boolean
    hasCertificate: boolean
    hasControlNumber: boolean
    hasDrivingLessons: boolean
    hasShipping: boolean
    hasTimer: boolean
    hasWeakSecurityMeasures: boolean
    inactivityCheck: boolean
    instructionsText?: string | null
    isClassroomCourse: boolean
    isEnrollmentOpen: boolean
    isExternalCourse: boolean
    isGuideRequired: boolean
    licenseNumber?: string | null
    link?: string | null
    timerLockedLectureId?: string | null
    verifyAddressText?: string | null
    topics: Array<{
      __typename?: "CourseTopic"
      id: string
      slug: string
      title: string
      lectures: Array<{
        __typename?: "CourseLecture"
        id: string
        title: string
        slug: string
        includesAQuiz: boolean
      }>
    }>
    testLectures: Array<{
      __typename?: "CourseTestLecture"
      id: string
      title: string
    }>
  }
}

export type GQLFetchCourseBySlugQueryVariables = Exact<{
  slug: Scalars["String"]
}>

export type GQLFetchCourseBySlugQuery = {
  __typename?: "Query"
  courseBySlug: {
    __typename?: "OnlineCourseContent"
    id: string
    slug: string
    title: string
    stateShortName: string
    stateLongName: string
    datoStateId: string
    courseTypeId?: string | null
    lectureIds: Array<string>
    numTests: number
    numLectures: number
    minPercentCorrect: number
    notarizedFormLink?: string | null
    requireOrder?: boolean | null
    requiredHours: number
    requiresDmvPreRegistration?: boolean | null
    requiresNotarizedForm?: boolean | null
    requiresSampleCertificateCheck?: boolean | null
    anvilPdfTemplateId?: string | null
    certificateCourseName?: string | null
    certificateIncludeMeetsRequirements?: boolean | null
    certificateInstructor?: string | null
    challengeRequired?: boolean | null
    completionFlow: Array<string>
    courseAgeMinimum?: number | null
    dailyTimeLimit?: number | null
    dmvPreRegistrationLink?: string | null
    externalCourseId?: string | null
    failTwiceReview: boolean
    hasCertificate: boolean
    hasControlNumber: boolean
    hasDrivingLessons: boolean
    hasShipping: boolean
    hasTimer: boolean
    hasWeakSecurityMeasures: boolean
    inactivityCheck: boolean
    instructionsText?: string | null
    isClassroomCourse: boolean
    isEnrollmentOpen: boolean
    isExternalCourse: boolean
    isGuideRequired: boolean
    licenseNumber?: string | null
    link?: string | null
    timerLockedLectureId?: string | null
    verifyAddressText?: string | null
    topics: Array<{
      __typename?: "CourseTopic"
      id: string
      slug: string
      title: string
      lectures: Array<{
        __typename?: "CourseLecture"
        id: string
        title: string
        slug: string
        includesAQuiz: boolean
      }>
    }>
    testLectures: Array<{
      __typename?: "CourseTestLecture"
      id: string
      title: string
    }>
  }
}

export type GQLFetchAllCoursesQueryVariables = Exact<{ [key: string]: never }>

export type GQLFetchAllCoursesQuery = {
  __typename?: "Query"
  courses: Array<{
    __typename?: "OnlineCourseContent"
    id: string
    title: string
    slug: string
    locationId?: string | null
    maxEnrollees?: number | null
    stateShortName: string
    isClassroomCourse: boolean
  }>
}

export type GQLCreateInstructorScheduleMutationVariables = Exact<{
  input: GQLInstructorScheduleInput
}>

export type GQLCreateInstructorScheduleMutation = {
  __typename?: "Mutation"
  createInstructorSchedule?: string | null
}

export type GQLUpdateInstructorScheduleMutationVariables = Exact<{
  input: GQLInstructorScheduleUpdateInput
  query: GQLInstructorScheduleMutateQuery
}>

export type GQLUpdateInstructorScheduleMutation = {
  __typename?: "Mutation"
  updateInstructorSchedule?: string | null
}

export type GQLDeleteInstructorScheduleMutationVariables = Exact<{
  query: GQLInstructorScheduleMutateQuery
}>

export type GQLDeleteInstructorScheduleMutation = {
  __typename?: "Mutation"
  deleteInstructorSchedule?: string | null
}

export type GQLStudentBalanceQueryQueryVariables = Exact<{
  studentId: Scalars["ID"]
}>

export type GQLStudentBalanceQueryQuery = {
  __typename?: "Query"
  getStudentBalance: {
    __typename?: "StudentBalance"
    userId: string
    drivingMinutesUsed: number
    observationMinutesUsed: number
    drivingMinutesPurchased: number
    observationMinutesPurchased: number
    drivingMinutesRemaining: number
    observationMinutesRemaining: number
    expirationDrivingMinutesUsed: number
    homePickupsPurchased: number
    homePickupsUsed: number
    homePickupsRemaining: number
    homePickupsExpired: number
  }
}
